import React from 'react'
import { Button as Btn } from 'react-bootstrap'
import Loader from 'react-loader-spinner'
import PropTypes from 'prop-types';


class Button extends React.Component {

    render() {

        let buttonText = null

        if(this.props.isBusy) {
            buttonText = <Loader type="ThreeDots" height={10} color="#ffffff" />
        }
        else
        {   
            const icon = this.props.icon ? this.props.icon : null;
            buttonText = <span> {icon} {this.props.text}</span>
        }


        return (
            <div style={this.props.style}>
                <Btn
                    className={this.props.className}
                    variant={this.props.variant} 
                    type="button" 
                    onClick={this.props.onClick} 
                    disabled={this.props.isBusy || this.props.disabled}
                    size={this.props.size}
                > 
                    {buttonText}
                </Btn>
            </div>
        )
    }
}

Button.propTypes = {
    isBusy: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    text: PropTypes.string.isRequired,
    variant: PropTypes.string,
    size: PropTypes.string
}

Button.defaultProps = {
    disabled: false,
    isBusy: false,
    variant: "primary",
    size: ""
}
export default Button