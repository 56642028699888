import React from 'react'
import {Row, Col} from 'react-bootstrap'
import {formatMoney, sortByDateAsc} from '../Helpers/Formatter'
import {withRouter} from 'react-router-dom';
import {withToastManager,} from 'react-toast-notifications';
import Moment from 'react-moment'
import {Consumer} from '../context'
import {TransactionTypes} from '../Constants'
import LoaderDisplay from './controls/loaderDisplay'
import ContextMenu from '../components/controls/contextMenu'


class FutureBox extends React.Component {

    constructor(props) {
        super(props);
        this.SelectItem = this.SelectItem.bind(this);

        this.state = {
            upcomingTransactions: [],
            isLoading: true
        }
    }

    componentDidMount() {
        this.getFutureTransactions();
    }

    getFutureTransactions() {
        const filter = {
            LimitResultCount: 10
        }
        this.context.getUpcoming(filter)
            .then((upcomingTransactions) => {
                this.setState({
                    upcomingTransactions,
                    isLoading: false
                })
            })
    }

    SelectItem(id) {
        this.props.history.push(`/schedule/${id}`);
    }

    PayNow(id, paymentNumber) {
        const {toastManager} = this.props;
        this.context.payScheduledTransaction(id, paymentNumber)
            .then((result) => {

                if (result.ok) {
                    
                    this.setState({upcomingTransactions: []}) // clear the list.
                    // We need to trigger the recent transactions to update.
                    this.getFutureTransactions();
                    const filter = {
                        LimitResultCount: 10
                    }
                    this.context.getTransactions(filter)

                    // And indicate we're done.
                    toastManager.add(result.message, {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });

                } else {
                    toastManager.add(result.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
                }
            })
    }

    SkipPayment(id, paymentNumber) {
        const {toastManager} = this.props;
        this.context.skipScheduledTransaction(id, paymentNumber)
            .then((result) => {
                if (result.ok) {
                    this.setState({upcomingTransactions: []}) // clear the list.
                    this.getFutureTransactions();
                    toastManager.add(result.message, {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
                } else {
                    toastManager.add(result.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
                }

            })
    }

    Pay(id, paymentNumber) {
        this.props.history.push(`/transaction/schedule/${id}/${paymentNumber}`);
    }

    View(id) {
        this.props.history.push(`/schedule/${id}`);
    }


    render() {


        if (this.state.isLoading) {
            return <LoaderDisplay/>
        }

        let lastDate = "";
        if (!this.state.upcomingTransactions || this.state.upcomingTransactions.length === 0) {
            return <div>No Upcoming Transactions found</div>
        }

        const workOutDescription = (item) => {
            let textBit = '';
            if (item.transactionTypeId === TransactionTypes.PAYMENT) { // Payment to...
                textBit = `${item.transactionType} to ${item.creditAccount}`
            }
            if (item.transactionTypeId === TransactionTypes.DEPOSIT) { // Deposit from to...
                textBit = `${item.transactionType} from ${item.debitAccount}`
            }
            if (item.transactionTypeId === TransactionTypes.TRANSFER) { // Transfer to...
                textBit = `${item.transactionType} from ${item.debitAccount} to ${item.creditAccount}`
            }

            return <React.Fragment><small><strong>{textBit}</strong></small></React.Fragment>
        }


        const itemStyle = {
            cursor: "pointer"
        }


        return (
            <React.Fragment>
                {this.state.upcomingTransactions
                    .sort((a, b) => sortByDateAsc(a.date, b.date))
                    .slice(0, 7) // Take the first 7
                    .map((item, index) => {
                        const options = [
                            {
                                displayText: "Pay Now",
                                callback: () => this.PayNow(item.scheduleId, item.paymentNumber),
                                enabled: true
                            },
                            {
                                displayText: "Pay",
                                callback: () => this.Pay(item.scheduleId, item.paymentNumber),
                                enabled: true
                            },
                            {
                                displayText: "Skip",
                                callback: () => this.SkipPayment(item.scheduleId, item.paymentNumber),
                                enabled: true
                            },
                            {},
                            {displayText: "View Schedule", callback: () => this.View(item.scheduleId), enabled: true},
                        ]

                        const formattedDate = <Moment format="dddd, Do of MMMM">{item.date}</Moment>;
                        const showHeader = (lastDate !== item.date);
                        lastDate = item.date
                        return (
                            <React.Fragment key={index}>
                                {showHeader &&
                                    <Row className="tableViewGroupBarStyle">
                                        <Col md="12">
                                            {formattedDate}
                                        </Col>
                                    </Row>
                                }
                                <Row className="tableViewRowStyle">
                                    <Col>
                                        <Row>
                                            <Col md="8">
                                                <div>
                                                    <ContextMenu items={options}/>


                                                    <div className="menutext" style={itemStyle}
                                                         onClick={() => this.View(item.scheduleId)}>
                                                        {workOutDescription(item)}
                                                    </div>
                                                </div>
                                                <div>
                                                    <small>{item.subCategory} {item.schedule &&
                                                        <span> - {item.schedule}</span>}</small>
                                                </div>
                                            </Col>
                                            <Col md="4" className="text-right">
                                                <div>
                                                    <span
                                                        className={item.transactionTypeId === TransactionTypes.DEPOSIT ? "incomeColor" : item.transactionTypeId === TransactionTypes.PAYMENT ? "expenseColor" : ""}><strong>$ {formatMoney(item.amount)}</strong></span>
                                                </div>
                                                <div>
                                                    {item.dueDays < 0 && <div className="warningText"><small><strong>Over
                                                        Due!</strong></small></div>}
                                                    {item.dueDays === 0 &&
                                                        <div><small><strong>Due Now</strong></small></div>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )
                    })
                }

            </React.Fragment>
        )
    }
}

FutureBox.contextType = Consumer
export default withRouter(withToastManager(FutureBox))
