import React from 'react'
import { Link } from 'react-router-dom';
import { ButtonIcons } from '../../Constants';

class PanelHeader extends React.Component {

    render() {

        const headerStyle = {
            display: "flex",
            justifyContent: "space-between"
          }

          const params = this.props.params

        return(
            <div>
                <div className="headerBoxStyle" style={headerStyle}>
                    <div>{this.props.text}</div>
                    {this.props.link && // If a link was passed in, we show a link button
                        <div>
                            <Link 
                                to={{
                                    pathname: this.props.link, 
                                    state: params
                                }}>
                                    {ButtonIcons.VIEW}
                            </Link>
                        </div>
                    }               
                </div>
                <hr />
            </div>
        )
    }
}

export default PanelHeader