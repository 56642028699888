import React from 'react'
import AuthorisedArea from '../../Helpers/AuthorisedArea'
import { Row, Col } from 'react-bootstrap'
import SelectInput from '../controls/selectInput'
import RadioInput from '../controls/radioInout'
import LineChart from '../charts/lineChart'
import { Consumer } from '../../context'
import LoaderDisplay from '../controls/loaderDisplay'
import { AccountTypes } from '../../Constants'
import moment from 'moment';

class BalanceForecast extends React.Component {

    constructor(props) {

        super(props)

        // If we want to default to a specific graph, we send isForecast asa Link parameter.
        const type = this.props.location?.state?.isForecast ? 1 : 2

        this.state = {
            selectedPeriod: 1,
            selectedAccountId: null,
            selectedType: type,
            accounts: [],
            datasets: [],
            isLoading: true
        }
    }

    populateData() {
        let history = null

        switch(this.state.selectedPeriod) {
            case 1: history = 30; break;
            case 2: history = 60; break;
            case 3: history = 90; break;
            case 4: history = 180; break;
            case 5: history = 365; break;
            case 6: history = 730; break;
            default: history = 730;
        }

        let functionName = null

        switch(this.state.selectedType) {
            case 1: functionName = this.context.getForecastBalanceReport; break
            case 2: functionName = this.context.getHistoricBalanceReport; break
            default: functionName = this.context.getForecastBalanceReport;
        }


       functionName(this.state.selectedAccountId, history)
            .then((data) => {
                const datasets = [
                      {
                        data: data
                          .map((item) => { return { amount: item.balance, label: moment(new Date(item.date)).format("Do MMM")} })
                      }
                    ]
                

                this.setState({
                    datasets,
                    isLoading: false
                })
            })
    }

    componentDidMount() {
        this.context.getAllAccounts(false)
            .then((result) => {
                const accounts = result
                        .filter(item => 
                            (item.isActive === true && item.accountType === AccountTypes.BANKACCOUNT) // Only active items
                        )
                        .sort(function(a, b) {
                            const textA = a.name.toUpperCase();
                            const textB = b.name.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        })
                        .map(item => ({ id: item.id, value: item.name }))
                        this.setState({
                            accounts
                        })

                        this.context.getDefaultAccount()
                            .then((defaultAccountId) => {
                                this.setState({
                                selectedAccountId: defaultAccountId
                                }, () => this.populateData())
                            })
                })
            }
                

    onPeriodChange(value) {
        this.setState({
            selectedPeriod: value,
            isLoading: true
        }, () =>  this.populateData())

    }

    onChangeAccount(value) {
        this.setState({
            selectedAccountId: value,
            isLoading: true
        }, () => this.populateData())
        
    }

    handleTypeChange(value) {
        this.setState({
            isLoading: true,
            selectedType: value
        }, () => this.populateData())
    }

    render() {

        const periods = [
            {id: 1, value: "1 Month"},
            {id: 2, value: "2 Months"},
            {id: 3, value: "3 Months"},
            {id: 4, value: "6 Months"},
            {id: 5, value: "1 Year"},
            {id: 6, value: "2 Year"}
        ]

        const chartHolder = {
            height: "50vh",
            width: "100%"
          }




          const typeOptions = [
              {label: "Forecast Balance", value: 1},
              {label: "Historical Balance", value: 2 }
          ]

          const forceXZero = true;

        return(
            <AuthorisedArea showError>
                <div className="borderBoxStyle">
                    <Row>
                        <Col>
                            <h4>Balance Trend Report</h4>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3">
                            <RadioInput label="Trend" options={typeOptions} value={this.state.selectedType} onChange={(value) => this.handleTypeChange(value)} />
                        </Col>
                        <Col md="5">
                            <SelectInput label="Account" options={this.state.accounts} value={this.state.selectedAccountId} onChange={(value) => this.onChangeAccount(value)} help="The account to forecast" />
                        </Col>
                        <Col md="4">
                            <SelectInput label="Projection Period" options={periods} value={this.state.selectedPeriod} onChange={(value) => this.onPeriodChange(value)} help="The period to project" />
                        </Col>
                    </Row>
                </div>
                <div className="borderBoxStyle top-buffer">
                    <div style={chartHolder}>
                        {this.state.isLoading && 
                            <LoaderDisplay />
                        }
                        {!this.state.isLoading && 
                            <LineChart 
                                datasets={this.state.datasets} 
                                showGrid={true} 
                                forceXZero={forceXZero}
                                referenceValue={0}
                            />
                        }
                    </div>
                </div>

            </AuthorisedArea>

        )
    }
}

BalanceForecast.contextType = Consumer
export default BalanceForecast