import React from 'react'
import { formatMoney } from '../Helpers/Formatter'
import { TransactionTypes } from '../Constants' 
import { Link } from 'react-router-dom';

class ScheduleCalendarCellEvent extends React.Component {

    render() {

        const disabledStyle = {
            opacity: "0.3",
            textDecoration: "line-through"
        }

        return(
            <React.Fragment>
                <Link to={{pathname: `/schedule/${this.props.event.scheduleId}`}} >
                    <div style={this.props.event.isSkipped ? disabledStyle : null} >
                        {this.props.event.displayText}<br />
                            <span className={this.props.event.transactionType === TransactionTypes.DEPOSIT ? `incomeColor` : this.props.event.transactionType === TransactionTypes.PAYMENT ? `expenseColor` : ``}>
                                <strong>
                                    {formatMoney(this.props.event.amount)}
                                </strong>
                            </span>
                    </div>
                </Link>
            </React.Fragment>
        )
    }
}

export default ScheduleCalendarCellEvent