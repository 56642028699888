import fetchData from '../Helpers/FetchData'
import Server from '../Constants'
import { HandleResponse } from './ResponseHandler' 

const investmentUrl = `${Server.ApiURL}/api/investments`;

class BudgetDataAcessor {

    static getInvestments() {
        return new Promise((resolve, reject) => {
    
          const request = {
            URL: investmentUrl,
            method: 'GET'
          };
    
          fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
            });
        }

        static getInvestment(id) {
            return new Promise((resolve, reject) => {
        
              const request = {
                URL: `${Server.ApiURL}/api/investment/${id}`,
                method: 'GET'
              };
        
              fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
            });
        }

        static saveInvestment(data) {
            return new Promise((resolve, reject) => {
        
                const request = {
                    URL: `${Server.ApiURL}/api/investment`,
                    method: 'POST',
                    data: data
                };
        
                fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
            });
        }

        static getChart(historicDays) {

            return new Promise((resolve, reject) => {
        
              const request = {
                URL:  `${Server.ApiURL}/api/investment/chart`,
                method: 'POST',
                data: { InvestmentChartPeriod: historicDays }
              };
        
              fetchData(request)            
                    .then((response) => {  
                        HandleResponse(response)
                            .then((result) => {
                                resolve(result)
                        })
                    })
                    .catch((err) => {
                        console.log('Error caught!', err);
                        reject(err);
                    });
                });
            }

}

export default BudgetDataAcessor;