import React from 'react'
import { Form } from 'react-bootstrap'
import { uuidv4 } from '../../Helpers/Formatter'
import ControlLabel from './controlLabel'
import ControlText from './controlText'

class CheckboxInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: this.props.value,
            id: this.props.id ? this.props.value : uuidv4()
        }
        // Set the value when the control was created.
        this.onChange = this.onChange.bind(this);
    }   

    onChange(e) {
        this.setState({ value: !this.state.value})
        this.props.onChange(!this.state.value);
    }

    render() {
        const checkStyle = {
            paddingTop: "6px"
        }

        return (
            <React.Fragment>
                <div>
                    <ControlLabel text={this.props.label} />
                </div>
                <div style={checkStyle}>
                    {!this.props.isViewMode &&
                        <Form.Check 
                            type="switch"
                            id={`custom-switch-${this.state.id}`}
                            label=""
                            checked={this.props.value}
                            onChange={() => this.onChange()}
                        />
                    }
                    {this.props.isViewMode && 
                        <ControlText text={this.props.value ? "Yes" : "No"} />
                    }
                </div>
                {!this.props.isViewMode &&
                    <div className="hintStyle">
                        {this.props.help}
                    </div>
                }
                
            </React.Fragment>
        )
    }
}

export default CheckboxInput