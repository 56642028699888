import React from 'react'
import {Route, Switch} from 'react-router-dom';
import Accounts from '../components/accounts';
import AccountEditor from '../components/accountEditor'
import Reports from '../components/reports';
//import Register from '../components/register';
// import Recover from '../components/recover'
import Budget from '../components/budget'
import Transactions from '../components/transactions'
import TransactionEditor from '../components/transactionEditor'
import Dashboard from '../components/dashboard'
import Debug from '../components/debug'
import User from '../components/user'
import Schedule from '../components/schedule'
import ScheduledItemEdit from '../components/scheduledItemEdit'
import Schedules from '../components/schedules'
import Categories from '../components/categories'
import Budgets from '../components/budgets'
import SchedueCalendar from '../components/scheduleCalendar'
import BalanceTrend from '../components/reports/balanceTrend'
import BudgetTrend from '../components/reports/budgetTrend'
import CategoryAmountReport from '../components/reports/categoryAmountReport'
import TransactionSearch from '../components/transactionSearch'
import Security from '../components/security'
import ThirdParties from '../components/thirdparties'
import ThirdParty from '../components/thirdparty'
import EmailValidation from '../components/emailValidation'
import Import from '../components/import'
import CategoriesOverTime from './reports/categoriesovertime';
import Investments from './investments';
import investmentEditor from './investmentEditor';
import Tags from './tags';
import Tag from './tag';


class RouteManager extends React.Component {

    render() {

        return (
            <Switch>
                <Route exact path="/"
                    component={Dashboard}/>
                <Route exact path="/accounts"
                    component={Accounts}/>
                <Route exact path="/accounts/:id"
                    component={Accounts}/>
                <Route exact path="/account"
                    component={AccountEditor}/>
                <Route exact path="/account/:id"
                    component={AccountEditor}/>
                <Route exact path="/reports"
                    component={Reports}/>
                <Route exact path="/budget/:id"
                    component={Budget}/>
                <Route exact path="/investments"
                    component={Investments}/>
                <Route exact path="/investment/:id"
                    component={investmentEditor}/>
                <Route exact path="/investment/"
                    component={investmentEditor}/>
                <Route exact path="/budget"
                    component={Budget}/>
                <Route exact path="/transactions/:id"
                    component={Transactions}/>
                <Route exact path="/transaction/:id"
                    component={TransactionEditor}/>
                <Route exact path="/transaction/schedule/:scheduleId/:paymentNumber"
                    component={TransactionEditor}/>
                <Route exact path="/transaction"
                    component={TransactionEditor}/>
                <Route exact path="/user"
                    component={User}/>
                <Route exaxt path="/debug"
                    component={Debug}/>
                <Route exact path="/schedule"
                    component={Schedule}/>
                <Route exact path="/schedule/:id"
                    component={Schedule}/>
                <Route exact path="/schedule/:id/event/:number"
                    component={ScheduledItemEdit}/>
                <Route exact path="/schedules"
                    component={Schedules}/>
                <Route exact path="/tags"
                    component={Tags} />
                <Route exact path="/tag/:id"
                    component={Tag} />
                <Route exact path="/categories"
                    component={Categories}/>
                <Route exact path="/budgets" component ={Budgets}/>
                <Route exact path="/calendar"
                    component={SchedueCalendar}/>
                <Route exact path="/balancetrend"
                    component={BalanceTrend}/>
                <Route exact path="/budgettrend"
                    component={BudgetTrend}/>
                <Route exact path="/categoryamountreport"
                    component={CategoryAmountReport}/>
                <Route exact path="/categoriesovertime"
                    component={CategoriesOverTime} />
                <Route exact path="/transactionsearch/"
                    component={TransactionSearch}/>
                <Route exact path="/security"
                    component={Security}/>
                <Route exact path="/thirdparties"
                    component={ThirdParties} />
                <Route exact path="/thirdparty/:id"
                    component={ThirdParty} />     
                <Route exact path="/emailvalidation/:id"
                    component={EmailValidation} />
                <Route exact path="/import"
                    component={Import} />
            </Switch>
        )
    }

}
export default RouteManager
