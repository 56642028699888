import React from 'react';

class Reports extends React.Component {
    render() {
        return (<div>
            <h1>Reports</h1>
        </div>)
    }
}

export default Reports;
