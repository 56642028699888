import React from 'react';
import Auth from '../Authentication';


// Method to validate that the user is authorised.
const isAuthorised = (requiredAdmin) => {
  // First, are we authenticated?
  const isAuthenticated = Auth.isAuthenticated();

  if (isAuthenticated) {
    if (!requiredAdmin) {
      return true;
    }
    if (Auth.isAdmin()) {
      return true;
    }
  }
  return false;
};

const AuthorisedArea = (props) => {
  const Authorised = isAuthorised(props.requiredAdmin);

  if (Authorised) {
    return props.children;
  }

  if (!props.showError) {
    return null;
  }

  return (
    <h6>You are not authorised to see this page</h6>
  );
};

export default AuthorisedArea;
