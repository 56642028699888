import React from 'react'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import {Row, Col} from 'react-bootstrap'
import SelectInput from './controls/selectInput'
import {Consumer} from '../context'
import LoaderDisplay from './controls/loaderDisplay'
import {AccountTypes, ButtonIcons} from '../Constants'
import DateInput from './controls/dateInput'
import CheckboxInput from './controls/checkboxInput'
import ReactGA from 'react-ga';
import CurrencyInput from "./controls/currencyInput";
import TextInput from "./controls/textInput";
import Moment from "react-moment";
import RadioInput from "./controls/radioInout";
import AccountsEditor from "./subcomponents/accountsEditor";
import Button from './controls/button'
import BudgetEditor from './subcomponents/budgetEditor'
import CategoryEditor from './subcomponents/categoryEditor'
import MultiSelectInput from './controls/multiSelectInput'
import { withToastManager, } from 'react-toast-notifications';

class TransactionSearch extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tags: [],

            dateTypesSelection: 0,
            amountTypeSelection: 0,
            accountFilter: false,

            fromDate: null,
            toDate: null,
            exactDate: null,
            datePeriod: 0,

            exactValueOption: false,
            valueRangeOption: false,

            includeDeleted: false,
            selectedCreditAccountId: null,
            selectedDebitAccountId: null,
            description: null,
            minValue: null,
            maxValue: null,
            exactValue: null,
            selectedCategoryId: null,
            selectedSubCategoryId: null,
            selectedBudgetId: null,
            accounts: [],
            isLoading: false,

            transactions: [],

            bulkUpdateTags: [],
            bulkUpdateAmount: null,
            bulkUpdateTransactionDate: null,
            bulkUpdateDescription: null,
            bulkUpdateSubCategoryId: null,
            bulkUpdateBudgetId: null,
            bulkUpdateTransactionIds: []
        }
    }

    componentDidMount() {

        const params = new URLSearchParams(window.location.search);
        const entries = Array.from(params.entries());

        // Get the available tags for the user to populate the list of tags that the user can select.
        this.context.getTags(false)
        .then((tags) => {
        this.setState({
            tags: tags.sort(function(a, b) {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })
            .map(item => (
            { value: item.id, label: item.name }
            ))
        })
        })


        // Combine entries into a single object
        const newState = entries.reduce((acc, [key, value]) => {
            if ((key === "exactDate" || key === "toDate" || key === "fromDate") && value !== null) {
                console.log("Date", value)
                acc[key] = new Date(value); // Convert date string to Date object
            } else if ((key === "dateTypesSelection" || key === "amountTypeSelection") && value!== null) {
                acc[key] = parseInt(value, 10); // Convert value to an integer
            } else if ((key === "minValue" || key === "maxValue" || key === "exactValue") && value!== null) {
                acc[key] = parseFloat(value); // Convert value to an integer
            } else {
                acc[key] = value; // Keep other values as they are
            }
            return acc;
        }, {});



        this.setState(newState);

        ReactGA.pageview(window.location.pathname);
        this.context.getAllAccounts(false)
            .then((result) => {
                const accounts = result
                    .filter(item =>
                        (item.isActive === true && item.accountType === AccountTypes.THIRDPARTY) // Only active items
                    )
                    .sort(function (a, b) {
                        const textA = a.name.toUpperCase();
                        const textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    })
                    .map(item => ({id: item.id, value: item.name}))
                this.setState({
                    accounts
                })
            })
    }


    handleChange(fieldName, value) {
        this.setState({
            [fieldName]: value,
        })

    }

    handleSearch() {
        this.setState({
            isLoading: true
        })

        const searchRequest = {
            description: this.state.description,
            creditAccountId: this.state.selectedAccountId,
            maxValue: this.state.amountTypeSelection === 2 ? this.state.maxValue : null,
            minValue: this.state.amountTypeSelection === 2 ? this.state.minValue : null,
            exactValue: this.state.amountTypeSelection === 1 ? this.state.exactValue : null,
            exactDate: this.state.dateTypesSelection === 1 ? this.state.exactDate : null,
            fromDate: this.state.dateTypesSelection === 2 ? this.state.fromDate : null,
            toDate: this.state.dateTypesSelection === 2 ? this.state.toDate : null
        }

        const params = new URLSearchParams()
        if (this.state.description) {
            params.append("description", this.state.description)
        } else {
            params.delete("description")
        }

        if (this.state.includeDeleted) {
            params.append("includeDeleted", "true")
        } else {
            params.delete("includeDeleted")
        }


        if (this.state.dateTypesSelection === 1) {
            params.append("exactDate", this.state.exactDate)
        } else {
            params.delete("exactDate")
        }

        params.append("dateTypesSelection", this.state.dateTypesSelection)
        params.append("amountTypeSelection", this.state.amountTypeSelection)

        if (this.state.dateTypesSelection === 2) {
            if(this.state.fromDate) params.append("fromDate", this.state.fromDate)
            if(this.state.toDate) params.append("toDate", this.state.toDate)

        } else {
            params.delete("fromDate")
            params.delete("toDate")
        }

        if (this.state.amountTypeSelection === 1) {
            params.append("exactValue", this.state.exactValue)
        } else {
            params.delete("exactValue")
        }

        if (this.state.amountTypeSelection === 2) {
            if(this.state.minValue) params.append("minValue", this.state.minValue)
            if(this.state.maxValue) params.append("maxValue", this.state.maxValue)
        } else {
            params.delete("minValue")
            params.delete("maxValue")
        }


        this.props.history.push({search: params.toString()})


        this.context.transactionSearch(searchRequest)
            .then((data) => {
                this.setState({
                    transactions: data.data,
                    isLoading: false
                })
            })
    }

    onSelect(transactionId) {
        this.props.history.push(`/transaction/${transactionId}`);
    }

    handleCheckboxChange = (id) => {
        this.setState((prevState) => {
          const { bulkUpdateTransactionIds } = prevState;
          if (bulkUpdateTransactionIds.includes(id)) {
            // If the ID is already in the array, remove it
            return { bulkUpdateTransactionIds: bulkUpdateTransactionIds.filter((item) => item !== id) };
          } else {
            // Otherwise, add it
            return { bulkUpdateTransactionIds: [...bulkUpdateTransactionIds, id] };
          }
        });
      };

    handleBulkUpdate() {

        this.setState({ isLoading : true  });
        const tagIds = this.state.bulkUpdateTags?.map(item => (item.value))

        console.log(this.state)

        const payload = {
            tagIds: tagIds,
            transactionAmount: this.state.bulkUpdateAmount,
            transactionDate: this.state.bulkUpdateTransactionDate,
            description: this.state.bulkUpdateDescription,
            subCategoryId: this.state.bulkUpdateSubCategoryId,
            budgetId: this.state.bulkUpdateBudgetId,
            transactionIds: this.state.bulkUpdateTransactionIds
        }
        const { toastManager } = this.props;

        this.context.bulkUpdateTransactions(payload)
        .then((result) => {
            this.setState({ isLoading : false })
            console.log("Result", result)

            if(result.ok === false) {
                toastManager.add(result.message, {
                    appearance: 'error',
                    autoDismiss: true,
                    pauseOnHover: false,
                  });
                  return  
            } else {
                toastManager.add(result.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    pauseOnHover: false,
                  });

                  this.handleSearch();
            }

        })
    }

    handleClear() {
        console.log("Clear")
        this.setState({
            bulkUpdateTags: [],
            bulkUpdateAmount: null,
            bulkUpdateTransactionDate: null,
            bulkUpdateDescription: "",
            bulkUpdateSubCategoryId: null,
            bulkUpdateBudgetId: null
        })
    }

    render() {

        const periods = [
            {id: 0, value: "All"},
            {id: 1, value: "Past month"},
            {id: 2, value: "Past 2 months"},
            {id: 3, value: "Past 3 Months"},
            {id: 4, value: "Past 6 Months"},
            {id: 5, value: "Past 1 Year"},
        ]

        const dateTypes = [
            {label: "None", value: 0},
            {label: "Exact Date", value: 1},
            {label: "Date Range", value: 2},
            {label: "Date Period", value: 3},

        ]

        const amountTypes = [
            {label: "None", value: 0},
            {label: "Exact Amount", value: 1},
            {label: "Amount Range", value: 2},
        ]

        const itemStyle = {
            cursor: "pointer"
        }

        return (
            <AuthorisedArea showError>
                <div className="borderBoxStyle">
                    <Row>
                        <Col>
                            <h4>Transaction Search</h4>
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Row>
                                <Col md={"3"}>
                                    <CheckboxInput label="Account Filter" value={this.state.accountFilter}
                                        onChange={(value) => this.handleChange('accountFilter', value)}
                                    />
                                </Col>
                                {this.state.accountFilter &&
                                    <Col md="9">
                                        <AccountsEditor transactionTypeId={1}/>
                                    </Col>
                                }
                                </Row>
                                <Row>
                                <Col md="6">
                                    <CheckboxInput label="Include Deleted" value={this.state.includeDeleted}
                                                onChange={(value) => this.handleChange('includeDeleted', value)}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={"3"}>
                                    <RadioInput label="Date Selection" options={dateTypes} value={this.state.dateTypesSelection}
                                                onChange={(value) => this.handleChange("dateTypesSelection", value)}/>
                                </Col>

                                {this.state.dateTypesSelection === 1 &&
                                    <Col md="9">
                                        <DateInput label="Transaction Date" value={this.state.exactDate}
                                                onChange={(value) => this.handleChange('exactDate', value)}/>
                                    </Col>
                                }
                                {this.state.dateTypesSelection === 2 &&
                                    <React.Fragment>
                                        <Col md="4">
                                            <DateInput label="From" value={this.state.fromDate}
                                                    onChange={(value) => this.handleChange('fromDate', value)}/>
                                        </Col>
                                        <Col md="4">
                                            <DateInput label="To" value={this.state.toDate}
                                                    onChange={(value) => this.handleChange('toDate', value)}/>
                                        </Col>
                                    </React.Fragment>
                                }
                                {this.state.dateTypesSelection === 3 &&
                                    <Col md="6">
                                        <SelectInput label="Report Period" value={this.state.datePeriod}
                                                    onChange={(value) => this.handleChange('datePeriod', value)}
                                                    options={periods}/>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col md={"3"}>
                                    <RadioInput label="Amount Selection" options={amountTypes}
                                                value={this.state.amountTypeSelection}
                                                onChange={(value) => this.handleChange("amountTypeSelection", value)}/>
                                </Col>

                                {this.state.amountTypeSelection === 1 &&
                                    <Col md="4">
                                        <CurrencyInput label={"Exact Amount"} value={this.state.exactValue}
                                                    onChange={(value) => this.handleChange("exactValue", value)}/>
                                    </Col>
                                }


                                {this.state.amountTypeSelection === 2 &&
                                    <React.Fragment>
                                        <Col md={"4"}>
                                            <CurrencyInput label={"Minimum Amount"} value={this.state.minValue}
                                                        onChange={(value) => this.handleChange("minValue", value)}/>
                                        </Col>
                                        <Col md={"4"}>
                                            <CurrencyInput label={"Maximum Amount"} value={this.state.maxValue}
                                                        onChange={(value) => this.handleChange("maxValue", value)}/>
                                        </Col>
                                    </React.Fragment>
                                }
                            </Row>
                            <Row>
                                <Col md={"8"}>
                                    <TextInput
                                        label="Description"
                                        dataType={"TEXT"}
                                        value={this.state.description}
                                        onChange={(value) => this.handleChange("description", value)}
                                        placeholder={"Description"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={"12"}>
                                    <Button variant="primary" icon={ButtonIcons.SEARCH} text="Search" onClick={() => this.handleSearch()}>Search</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6">
                            <Row>
                                <Col md="12">
                                    <h5>Bulk Update</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <DateInput label="New Transaction Date" value={this.state.bulkUpdateTransactionDate}
                                        onChange={(value) => this.handleChange('bulkUpdateTransactionDate', value)}/>
                                </Col>
                                <Col md={"6"}>
                                    <CurrencyInput label={"New Amount (Splits will not update)"} value={this.state.bulkUpdateAmount}
                                        onChange={(value) => this.handleChange("bulkUpdateAmount", value)}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={"8"}>
                                    <TextInput
                                        label="New Description"
                                        dataType={"TEXT"}
                                        value={this.state.bulkUpdateDescription}
                                        onChange={(value) => this.handleChange("bulkUpdateDescription", value)}
                                        placeholder={"New Description"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <CategoryEditor
                                    value={this.state.bulkUpdateSubCategoryId}
                                    onChange = {(value) => this.handleChange("bulkUpdateSubCategoryId", value)} 
                                    isViewMode ={this.props.isViewMode}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <BudgetEditor
                                    value={this.state.bulkUpdateBudgetId}
                                    onChange={(value) => this.handleChange("bulkUpdateBudgetId", value)}
                                    isViewMode={this.props.isViewMode}
                                    />
                                </Col>    
                            </Row>
                            <Row>
                                <Col md="5">
                                    <MultiSelectInput 
                                        name="tags"
                                        label="Updated Tags" 
                                        values={this.state.bulkUpdateTags} 
                                        options={this.state.tags}
                                        help="Select the tags for this transaction" 
                                        onChange={(values) => this.handleChange("bulkUpdateTags", values)}
                                        isViewMode = {this.state.isViewMode}
                                    />
                                </Col>
                                <Col md="7" className="text-right d-flex flex-row justify-content-end align-items-center">
                                    <Button style={{ marginRight: '10px' }} variant="danger" icon={ButtonIcons.YES} text="Bulk Update" onClick={() => this.handleBulkUpdate()} />
                                    <Button variant="info" icon={ButtonIcons.YES} text="Clear" onClick={() => this.handleClear()} />
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                    
                </div>
                <div className="borderBoxStyle top-buffer">
                    {this.state.isLoading &&
                        <LoaderDisplay/>
                    }
                    {!this.state.isLoading && this.state.transactions.length === 0 &&
                        <h2>No Transactions meet this search request</h2>
                    }
                    {!this.state.isLoading && this.state.transactions.length > 0 &&
                        <React.Fragment>
                            <Row className="tableViewHeaderStyle">
                                <Col md="2">Transaction Date</Col>
                                <Col md="3">Description</Col>
                                <Col md="5">Transaction</Col>
                                <Col md="2" className="text-right">Transaction Value</Col>

                            </Row>
                            {this.state.transactions.map((item, index) =>
                                <Row key={index} onClick={() => this.onSelect(item.id)} className="tableViewRowStyle" style={itemStyle}>
                                    <Col md={"2"}>
                                        <Moment format="Do MMM 'YY">{item.date}</Moment>
                                    </Col>
                                    <Col md={"3"}>
                                        {item.description}
                                    </Col>
                                    <Col md={"5"}>
                                        <strong>{item.transactionType}</strong>, {item.debitAccountName} to <strong>{item.creditAccountName}</strong>
                                    </Col>
                                    <Col md={"2"} className="text-right">
                                        <strong>{item.amount.toFixed(2)}</strong>
                                        <input
                                            type="checkbox"
                                            className="ml-2" // Add margin-left for spacing
                                            style={{ width: "16px", height: "16px" }} // Make the checkbox smaller
                                            onChange={() => this.handleCheckboxChange(item.id)} // Handle checkbox change
                                            onClick={(e) => e.stopPropagation()} // Stop the event propagation
                                        />
                                    </Col>
                                </Row>
                            )
                            }
                        </React.Fragment>
                    }
                </div>

            </AuthorisedArea>

        )
    }
}

TransactionSearch.contextType = Consumer
export default withToastManager(TransactionSearch)