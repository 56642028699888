import React from 'react'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import { Col, Row } from 'react-bootstrap'
import TextInput from './controls/textInput'
import CheckboxInput from './controls/checkboxInput'
import CategoryEditor from './subcomponents/categoryEditor'
import BudgetEditor from './subcomponents/budgetEditor'
import { Consumer } from '../context'
import { withToastManager, } from 'react-toast-notifications';
import LoaderDisplay from './controls/loaderDisplay';
import ReactGA from 'react-ga';
import Moment from 'react-moment'
import Button from './controls/button'
import { ButtonIcons } from '../Constants'


class ThirdParty extends React.Component {

    constructor(props) {
        super(props)

        let account = {
            id: null,
            name: null,
            accountTypeId: null,
            date: Date.now(),
            openingBalance: null,
            defaultSubCategoryId: null,
            defaultBudgetId: null,
            isActive: true,
            isFavourite: false,
            canQuickTransaction: false,
        }

        this.state = {
            account,
            isLoading: false,
            transactions: [],
            isBusy: false
            
        }

        this.saveAccount = this.saveAccount.bind(this);
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
        const selectedId = this.props.match.params.id
        if(selectedId) {
            this.setState({
                isLoading: true
            })
            
            const transactionRequest = {
                accountId: selectedId,
            }

            this.context.getTransactions(transactionRequest)
                .then((data) => {
                    this.setState({
                        transactions: data
                    })
                })

            this.context.getAccount(selectedId)
                .then((result) => {
                    const account = {
                        name: result.name,
                        accountTypeId: result.accountType,
                        date: result.date,
                        openingDate: result.openingDate,
                        openingBalance: result.openingBalance,
                        defaultSubCategoryId: result.defaultSubcategoryId,
                        defaultBudgetId: result.defaultBudgetId,
                        isActive: result.isActive,
                        id: result.id,
                        isFavourite: result.isFavourite,
                        canQuickTransaction: result.canQuickTransaction
                    }
                    this.setState(
                        {
                            account, 
                            isLoading: false
                         })
                })
        }
    }

    onChange(controlName, value) {
        const account = this.state.account
        account[controlName] = value
        this.setState({
            account
        })
    }

    onSelect(transactionId) {
        this.props.history.push(`/transaction/${transactionId}`);
    }

    saveAccount() {
        this.setState({
            isBusy: true
        })

        const { toastManager } = this.props;
        this.context.saveAccount(this.state.account)
            .then((result) => {    
                if(result.ok) {
                this.props.history.goBack();
                toastManager.add(`${this.state.account.name}, Saved Successfully.`, {
                    appearance: 'success',
                    autoDismiss: true,
                    pauseOnHover: false,
                    });
                }
                else {
                    toastManager.add(result.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: false,
                      });
                      return        
                }
            })
    }

    handleCancel() {
        const { toastManager } = this.props;
        this.props.history.goBack();
            toastManager.add(`No changes were made.`, {
                appearance: 'success',
                autoDismiss: true,
                pauseOnHover: false,
            });
    }

    handleDelete() {
        this.setState({
            isBusy: true
        })
        
        this.context.deleteAccount(this.state.account.id)
            .then((result) => {
                const { toastManager } = this.props;
                if(result.ok) {
                    this.props.history.goBack();
                    toastManager.add(result.message, {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
                }
                else {
                    toastManager.add(result.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
                }
            })
    }


    render() {

        if(this.state.isLoading) {
            return (
                <LoaderDisplay />
            )
        }

        const deleteAllowed = (this.state.transactions.length === 0 && this.state.account.id != null)

        const buttonSpacingStyle = {
            padding: "4px",
            float: "left"
          }
      
          const buttonBarStyle = {
            display: "flex",
            justifyContent: "flex-end"
          }



        return (
            <React.Fragment>
                <AuthorisedArea showError>
                    <div className="borderBoxStyle">
                        <Row>
                            <Col md="6" >
                                <TextInput 
                                    label="Name" 
                                    placeholder="Name" 
                                    help="Enter the name of the third party" 
                                    value={this.state.account.name}
                                    onChange={(value) => this.onChange('name', value)}
                                />   
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <CheckboxInput
                                    value={this.state.account.isActive}
                                    label="Active"
                                    onChange={(value) => this.onChange('isActive', value)}
                                    help="Account is active"
                                    />
                            </Col>
                            <Col md="2">
                                <CheckboxInput
                                    value={this.state.account.canQuickTransaction}
                                    label="Quick Transaction"
                                    onChange={(value) => this.onChange('canQuickTransaction', value)}
                                    help="Enable Quick Transactions"
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <CategoryEditor 
                                    prefix="Account's Default"
                                    onChange={(value) => this.onChange('defaultSubCategoryId', value)}
                                    value={this.state.account.defaultSubCategoryId}
                                />
                            </Col>
                            </Row>
                            <Row>
                            <Col md="6">
                                <BudgetEditor 
                                    prefix="Account's Default"
                                    onChange={(value) => this.onChange('defaultBudgetId', value)}
                                    value={this.state.account.defaultBudgetId}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <div style={buttonBarStyle}>
                                <Button 
                                    onClick={() => this.saveAccount()}
                                    text="Save"
                                    variant='outline-primary'
                                    icon={ButtonIcons.SAVE}
                                    style={buttonSpacingStyle}
                                    isBusy={this.state.isBusy}
                                />

                                <Button 
                                    onClick={() => this.handleCancel()}
                                    text="Cancel"
                                    variant='outline-secondary'
                                    icon={ButtonIcons.CANCEL}
                                    style={buttonSpacingStyle}
                                    isBusy={this.state.isBusy}
                                />
                                {deleteAllowed &&
                                    <Button 
                                        onClick={() => this.handleDelete()}
                                        text="Delete"
                                        variant='outline-primary'
                                        icon={ButtonIcons.DELETE}
                                        style={buttonSpacingStyle}
                                        isBusy={this.state.isBusy}
                                    />
                                }
                            </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="borderBoxStyle top-buffer">
                        <h5>Recent Transactions</h5>
                        <hr />

                        {this.state.transactions
                            .map((item, index) => {
                                const formattedDate = <Moment format="Do of MMMM, 'YY">{item.transactionDate}</Moment>;
                                const itemStyle = {
                                    cursor: "pointer"
                                }

                                let description
                                let amount

                                if(item.debitAccountId === this.state.account.id) {
                                    description = <React.Fragment>
                                            <div><i className="far fa-arrow-alt-circle-up incomeColor" /><small> Deposit into</small></div>
                                            <div><strong>{item.creditAccountName}</strong></div>
                                        </React.Fragment>
                                    
                                    amount = <span className="incomeColor">{Number.parseFloat(item.amount).toFixed(2)}</span>
                                }
                                else 
                                {
                                    description = <React.Fragment>
                                            <div><i className="far fa-arrow-alt-circle-down expenseColor" /><small> Payment from</small></div>
                                            <div><strong>{item.debitAccountName}</strong></div>
                                        </React.Fragment>
                                        amount = <span className="expenseColor">{Number.parseFloat(item.amount).toFixed(2)}</span>
                                }
                                    
                                return (
                                    <React.Fragment key={index}>
                                        <Row key={index} onClick={() => this.onSelect(item.id)} className="tableViewRowStyle" style={itemStyle}>
                                            <Col md="2"><small>{formattedDate}</small></Col>
                                            <Col md="3">
                                                {description}
                                            </Col>
                                            <Col><small>{item.description}</small></Col>
                                            <Col>
                                                <div>
                                                    <small>{item.subCategoryName}</small>
                                                </div>
                                                <div>
                                                    <small>{item.budgetName}</small>
                                                </div>
                                            
                                            </Col>
                                            <Col className="text-right"><strong>{amount}</strong></Col>
                                        </Row>
                                    </React.Fragment>
                                )
                            })
                        }

                    </div>
                </AuthorisedArea>
            </React.Fragment>
        )
    }
}

ThirdParty.contextType = Consumer
export default withToastManager(ThirdParty)