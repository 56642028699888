import fetchData from '../Helpers/FetchData'
import Server from '../Constants'
import { HandleResponse } from './ResponseHandler' 

const accountUrl = `${Server.ApiURL}/api/budgets`;

class BudgetDataAcessor {

    static getBudgets() {
        return new Promise((resolve, reject) => {
    
          const request = {
            URL: accountUrl,
            method: 'GET'
          };
    
          fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
            });
        }

        static getBudget(id) {
            return new Promise((resolve, reject) => {
        
              const request = {
                URL: `${Server.ApiURL}/api/budget/${id}`,
                method: 'GET'
              };
        
              fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
            });
        }

        static getBudgetTransactions(budgetId, budgetNumber) {
            return new Promise((resolve, reject) => {
        
              const request = {
                URL: `${Server.ApiURL}/api/budget/${budgetId}/transactions/${budgetNumber}`,
                method: 'GET'
              };
        
              fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
            });
        }

        static saveBudget(data) {
            return new Promise((resolve, reject) => {
        
                const request = {
                    URL: `${Server.ApiURL}/api/budget`,
                    method: 'POST',
                    data: data
                };
        
                fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
                });
            }

            static deleteBudget(id) {
                return new Promise((resolve, reject) => {
            
                    const request = {
                        URL: `${Server.ApiURL}/api/budget/${id}`,
                        method: 'DELETE'
                    };
            
                    fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
                    });
                }
}

export default BudgetDataAcessor;