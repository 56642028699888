import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { HashLink as Link } from 'react-router-hash-link';
import { formatMoney } from '../Helpers/Formatter'

class AccountBox extends React.Component {
    render() {
        const boxStyle = {
            marginBottom: "8px",
            fontSize: "12px"
        }
        
        const amount = `$ ${formatMoney(this.props.balance)}`;
        const params = {
            accountId: this.props.accountId
        }

        return (
            <React.Fragment>
                <Row style={boxStyle}>
                    <Col lg="12" sm="12" className="ellipsis">
                    {this.props.type === 'acc' && <Link to={{ pathname: `/transactions/${this.props.accountId}`, state: params}}>{this.props.name}</Link>}
                    {this.props.type === 'inv' && <Link to={{ pathname: `/investments/`, state: params}}>{this.props.name}</Link>}
                    </Col>
                    <Col lg="12" sm="12" className="text-right labelStyle">{amount}</Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default AccountBox