import React from 'react'
import { Form } from 'react-bootstrap'
import { DataTypes } from '../../Constants'
import { isNumberic } from '../../Helpers/Formatter'
import PropTypes from 'prop-types';
import ControlLabel from './controlLabel';
import ControlText from './controlText'

class TextInput extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: this.props.value ? this.props.value : '',
            dataType: this.props.dataType,
            isViewMode: this.props.isViewMode
        }
        this.onChange = this.onChange.bind(this);
    }   

    onChange(e) {
        let value = e.target.value
        if(this.state.dataType === DataTypes.NUMBER) {
            if(isNumberic(value, false)) {
                value = Number(value);
            }
        }
        this.setState({ value })
        this.props.onChange(value);
    }

    handleKeyPress(e) {
        const code = e.charCode
        if(code === 13)
        {
            this.props.enterPressed()
        }
    }

    render() {

        return (
            <React.Fragment>
                <div>
                    <ControlLabel text={this.props.label} />
                </div>
                <div>
                    {!this.props.isViewMode &&
                        <Form.Control  
                            className="form-control" 
                            placeholder={this.props.placeholder} 
                            value={this.props.value}
                            required 
                            onChange={this.onChange}
                            type={this.props.type ? this.props.type : "text"}
                            onKeyPress={(e) => this.handleKeyPress(e)}
                        />
                    }
                    {this.props.isViewMode && 
                    
                        <ControlText text={this.props.value} />
                    }
                </div>
                {!this.props.isViewMode &&
                    <div className="hintStyle">
                        {this.props.help}
                    </div>
                }
            </React.Fragment>
        )
    }
}

TextInput.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    dataType: PropTypes.oneOf(['TEXT', 'NUMBER']).isRequired,
    isViewMode: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    enterPressed: PropTypes.func,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'password'])
}


TextInput.defaultProps = {
    isViewMode: false,
    type: 'text'
}

export default TextInput