import React from 'react'
import { withRouter } from 'react-router-dom';
import { withToastManager, } from 'react-toast-notifications';
import { Col, Row } from 'react-bootstrap'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import TextInput from './controls/textInput'
import SelectInput from './controls/selectInput'
import Button from './controls/button'
import TimezoneEditor from './subcomponents/timezoneEditor'
import { Consumer } from '../context'
import LoaderDisplay from './controls/loaderDisplay'
import { ButtonIcons } from '../Constants'
import ReactGA from 'react-ga';

class User extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            user: {
                id: null,
                firstname: null,
                surname: null,
                email: null,
                defaultAccountId: null,
                timezoneId: null,
                accountName: null
            },
            isLoading: true,
            isBusy: false
        }

        this.onChange = this.onChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
        this.context.getAllAccounts(false)
            .then((result) => {
                const items = result
                .filter((item) => item.accountType === 1)
                .map(item => {
                    return {
                        id: item.id,
                        value: item.name
                    }
                })
                this.setState({
                    accounts: items
                })
            })

        this.context.getUser(false) 
            .then((result) => {
                if(result.ok) {
                    const user = {     
                        firstname: result.data.firstname,
                        surname: result.data.surname,
                        email: result.data.email,
                        defaultAccountId: result.data.defaultAccountId,
                        timezoneId: result.data.timezoneId,
                        userAccountName: result.data.userAccountName,
                        id: result.data.id,
                    }
                   
                    this.setState({
                        isLoading: false,
                        user
                    })
                }
                else
                {
                    this.props.history.goBack();
                    const { toastManager } = this.props;
                    toastManager.add(result.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
       
                }
            })
    }


    onChange(controlName, value) {
        const user = this.state.user
        user[controlName] = value

        this.setState( {
            user
        })
    }


    onSave() {
        const { toastManager } = this.props;
        this.setState({
            isBusy: true
        })
        
        this.context.saveUser(this.state.user)
            .then((result) => {
                if(result.ok) {
                    toastManager.add(result.message, {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: false,
                    })
                    this.props.history.goBack()

                } else {
                    this.setState({
                        isBusy: false
                    })
                        toastManager.add(result.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: false,
                    })
                }
            })
    }


    render() {
        if(this.state.isLoading)
            return <LoaderDisplay />

        const cancelClick = () => {
            this.props.history.goBack();
            }

            const buttonSpacingStyle = {
                padding: "4px",
                float: "left"
              }
          
              const buttonBarStyle = {
                display: "flex",
                justifyContent: "flex-end"
              }

        return (
            <React.Fragment>
                <AuthorisedArea showError>
                    <div className="borderBoxStyle">
                <Row >
                    <Col md="12">
                        <h5>User Details</h5>
                        <hr />
                    </Col>
                    </Row>
                <Row>
                    <Col md="4" >
                        <TextInput 
                            label="Firstname"
                            value={this.state.user.firstname}
                            help="Your firstname"
                            placeholder="Firstname"
                            onChange={(value) => this.onChange('firstname', value)}
                        />
                    </Col>
                    <Col md="4" >
                        <TextInput 
                            label="Surname"
                            value={this.state.user.surname}
                            help="Your surname"
                            placeholder="Surname"
                            onChange={(value) => this.onChange('surname', value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="5" >
                        <TextInput 
                            label="Email"
                            value={this.state.user.email}
                            help="Your email"
                            placeholder="Email"
                            onChange={(value) => this.onChange('email', value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="8" >
                        <SelectInput
                            label="Default Account"
                            value={this.state.user.defaultAccountId}
                            help="The account you most commonly use"
                            options={this.state.accounts}
                            onChange={(value) => this.onChange('defaultAccountId', value)}
                        />
                    </Col>
                       
                </Row>
                <Row>
                    <Col md="8" >
                        <TimezoneEditor 
                            value={this.state.user.timezoneId}
                            onChange={(value) => this.onChange('timezoneId', value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="6" >
                        You are part of a family account called <strong>{this.state.user.userAccountName}</strong>    
                    </Col>
                    <Col>
                        <div style={buttonBarStyle}>
                            <Button 
                                onClick={() => cancelClick()}
                                text="Cancel" 
                                icon={ButtonIcons.CANCEL}
                                variant="outline-primary"
                                style={buttonSpacingStyle}

                            />
                            <Button 
                                onClick={() => this.onSave()}
                                text="Save"
                                variant="primary"
                                icon={ButtonIcons.SAVE}
                                style={buttonSpacingStyle}
                                isBusy={this.state.isBusy}
                            />
                        </div>

                    </Col>
                </Row>
                </div>
                </AuthorisedArea>

            </React.Fragment>
        )
    }
}

User.contextType = Consumer
export default withRouter(withToastManager(User))