import React from 'react';
import AuthorisedArea from '../Helpers/AuthorisedArea'
import { Row, Col, } from 'react-bootstrap'
import TextInput from './controls/textInput'
import CheckboxInput from './controls/checkboxInput'
import { Consumer } from '../context'
import { withRouter } from 'react-router-dom';
import LoaderDisplay from '../components/controls/loaderDisplay'
import ReactGA from 'react-ga';
import { AccountTypes, ButtonIcons } from '../Constants';
import Button from './controls/button'

class ThirdParties extends React.Component {
    static contextType = Consumer

    constructor(props, context) {
        super(props)
        this.context = context
        this.state = {
            isLoading: true,
            searchString: null,
            thirdparties: null,
            includeInactive: false
        }

        this.onChange = this.onChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.search = this.search.bind(this);
        this.updateList = this.updateList.bind(this);
    }


    onChange(value) {
        this.props.history.push(`/accounts/${value}`)

        this.setState({
            selectedTypeId: value,
        }, () => this.updateList())
    }

    updateList() {
        this.context.getAllAccounts(false)
            .then((result) => {
                const thirdparties = result
                .filter(item => item.accountType === AccountTypes.THIRDPARTY)
                .filter(item => item.name.toLowerCase().includes(this.state.searchString && this.state.searchString.toLowerCase()) || !this.state.searchString )
                .sort(function(a, b) {
                    const textA = a.name.toUpperCase();
                    const textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })

                this.setState({
                    thirdparties,
                    isLoading: false
                })
            })
    }


    onSelect(id) {
        this.props.history.push(`/thirdparty/${id}`);
    }

    search(text) {
        this.setState({
            searchString: text
        }, () => this.updateList())
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
        this.updateList()
    }


    render() {

        // Grab the selected AccountId from the state. If the Link property passed the data, use it.
        console.log(this.state.thirdparties)
        const itemStyle = {
            cursor: "pointer"
        }

        
    const buttonBarStyle = {
        display: "flex",
        justifyContent: "flex-end"
      }

        return (
            
                <AuthorisedArea showError>
                    <div className="borderBoxStyle">
                <Row>
                    <Col md="3">
                        <TextInput
                            value={this.state.searchString}
                            help="Type in a Search String"
                            onChange={(value) => this.search(value)}
                            placeholder="Search Text"
                            label="Search"
                            />

                    </Col>
                    <Col md="3">
                        <CheckboxInput
                            value={this.state.includeInactive}
                            help="Show Inactive Accounts"
                            onChange={(value) => this.setState({ includeInactive: value})}
                            label="Include Inactive"
                        />

                    </Col>
                    <Col>
                        <div style={buttonBarStyle}>

                        <Button 
                            className="primary" 
                            onClick={() => {this.props.history.push(`/account/`);}}
                            text="Create Account"
                            icon={ButtonIcons.CREATE}
                        />
                        </div>
                    </Col>
                </Row>
                </div>

                {this.state.isLoading && 
                    <LoaderDisplay />
                } 
                {!this.state.isLoading && 
                <div className="borderBoxStyle top-buffer">
                    <Row>
                        <Col md="12" >
                            <Row className="tableViewHeaderStyle">  
                                <Col md="3">Name</Col>
                                <Col md="2" className="text-right">Transaction Value</Col>
                                <Col md="1">Favourite</Col>
                                <Col md="1">Quick</Col>
                                <Col md="1">Quick</Col>
                                <Col md="4">Default Category</Col>
                            </Row>
                            {this.state.thirdparties.length === 0 &&
                                <div>No accounts available</div>
                            }
                            {this.state.thirdparties
                                .filter((item) => this.state.includeInactive || item.isActive === true)
                                .map((item, index) => 
                                <Row 
                                    key={index} 
                                    onClick={() => this.onSelect(item.id)} 
                                    className="tableViewRowStyle" 
                                    style={itemStyle} 
                                >
                                    <Col md="3">
                                        <div><strong>{item.name}</strong></div>
                                        <div><small>
                                        {item.defaultSubcategory &&
                                            ` ${item.defaultCategory} / ${item.defaultSubcategory}`
                                        }{!item.defaultSubcategory &&
                                            <span>--</span>
                                        }
                                        </small></div>
                                    </Col>
                                    <Col md="2" className="text-right">{Number.parseFloat(item.balance).toFixed(2)}</Col>
                                    <Col md="1">
                                        {item.isActive && <i className="far fa-check-square"></i> }
                                    </Col>
                                    <Col md="1">{item.isFavourite && <span>{ButtonIcons.YES}</span> }</Col>
                                    <Col md="1">{item.canQuickTransaction && <span>{ButtonIcons.YES}</span> }</Col>
                                    <Col md="4">{item.defaultBudget}</Col>
                                </Row>    
                            )}
                        </Col>
                    </Row>
                    </div>
                }
                </AuthorisedArea>
            
        )
    }
}
export default withRouter(ThirdParties);