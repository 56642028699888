import React from 'react'
import { Button, Row, Col, Modal } from 'react-bootstrap'
import CurrencyInput from './controls/currencyInput'
import {formatMoney} from '../Helpers/Formatter'
import { Consumer } from '../context'
import CheckboxInput from './controls/checkboxInput'
import TextInput from './controls/textInput'

class ScheduleAmountEditor extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            scheduleId: null,
            paymentNumber: null,
            isVisible: false,
            currentAmount: null,
            newAmount: null,
            applyAll: false,
            description: null,
        }
    }

    handleClose() {
        this.setState({
            isVisible: false
        })
    }

    handleChange(fieldName, value) {
        this.setState({
            [fieldName]: value
        })
    }

    handleSave() {
        const data = {
            scheduleId: this.state.scheduleId,
            paymentNumber: this.state.paymentNumber,
            newAmount: this.state.newAmount,
            continueChange: this.state.applyAll,
            description: this.state.description
        }
        this.context.scheduleVariation(data) 
            .then((result) => {
                this.props.callbackMethod(true);
                this.setState({
                    isVisible: false
                })
            })
   

    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if(newProps.event && (newProps.show !== this.state.isVisible || newProps.event.amount !== this.state.currentAmount)) {
            this.setState({
                isVisible: newProps.show,
                currentAmount: newProps.event.amount,
                scheduleId: newProps.event.scheduleId,
                paymentNumber: newProps.event.paymentNumber,
                applyAll: newProps.event.continueChange
            })
        }
    }

    render() {

    return (
        <Modal 
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.isVisible} 
            onHide={() => this.handleClose()} 
            animation={true}
          >
            <Modal.Header closeButton>
                <Modal.Title>Scheduled Amount</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md="12">
                    <p>Current scheduled event amount: {formatMoney(this.state.currentAmount)}</p>
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <CurrencyInput 
                        value={null}
                        label="Current Amount"
                        onChange={(value) => this.handleChange("newAmount", value)}
                        help="The current amount of this payment"
                    />
                </Col>
            </Row>
            <Row>
                <Col md="7" xs="12">
                    <TextInput 
                        label="Description"
                        placeholder="Optional Description"
                        value={this.state.description}
                        onChange={(value) => this.handleChange("description", value)}

                    />
                </Col>
            </Row>
            <Row>
                    <Col md="12">
                    <CheckboxInput 
                        value={this.state.applyAll}
                        label="Apply to all future payments?"
                        onChange={(value) => this.handleChange('applyAll', value)}
                        help="Apply to all future payments"
                    />
                    </Col>
              </Row>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.handleClose()}>
                      Cancel
                  </Button>
                  <Button variant="primary" onClick={() => this.handleSave()}>
                      Save Changes
                  </Button>
              </Modal.Footer>
          </Modal>
    )
}


}
ScheduleAmountEditor.contextType = Consumer
export default ScheduleAmountEditor
