import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { formatMoney, sortByDateDesc } from '../Helpers/Formatter'
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment'
import { Consumer } from  '../context'
import { TransactionTypes } from '../Constants'
import LoaderDisplay from './controls/loaderDisplay' 

class TransactionBox extends React.Component {

    constructor(props) {
        super(props);
        this.SelectItem = this.SelectItem.bind(this);

        this.state = {
            transactions: [],
            isLoading: true
        }
      }

      componentDidMount() {
        this.context.getRecentTransactions(false, 10)
            .then(() => this.setState({ isLoading: false }))
      }

      SelectItem(transactionId) {
        this.props.history.push(`/transaction/${transactionId}`);
      }

    render() {

        if(this.state.isLoading) {
            return <LoaderDisplay />
        }

        const mainDiv = {
            float: "left",
            width: "80%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }

        const valueDiv = {
            float: "right",
            whiteSpace: "nowrap",
            textAlign: "right",
            width: "20%",
            fontWeight: "600"
        }

        let lastDate = "";
        // if(!this.state.transactions || this.state.transactions.length === 0)
        // {
        //     return <div>No Recent Transactions found</div> 
        // }

        const workOutDescription = (item) => {
            
            let transactionDescriptionText = '';

            if(item.transactionTypeId === TransactionTypes.PAYMENT) { // Payment to...
                transactionDescriptionText = `${item.transactionType} to ${item.creditAccountName}`
            }
            if(item.transactionTypeId === TransactionTypes.DEPOSIT) { // Deposit from to...
                transactionDescriptionText = `${item.transactionType} from ${item.debitAccountName}`
            }
            if(item.transactionTypeId === TransactionTypes.TRANSFER) { // Transfer to...
                transactionDescriptionText = `${item.transactionType} to ${item.creditAccountName}`
            }

            return <React.Fragment>
                <React.Fragment>
                    <div style={mainDiv}>
                        <small><strong>{transactionDescriptionText}</strong>{item.budgetId && ` - ${item.budgetName}`}</small>
                        <br />
                        <span>
                            <small>{item.subCategoryName} {item.description && <span> - {item.description}</span>}</small></span>
                    </div>
                    <div style={valueDiv}>
                        <span className={item.transactionTypeId === TransactionTypes.DEPOSIT ? "incomeColor" : item.transactionTypeId === TransactionTypes.PAYMENT ? "expenseColor" : "" }>$ {formatMoney(item.amount)}</span>
                    </div>
                </React.Fragment>

                
                
            </React.Fragment>
        }
        const itemStyle = {
            cursor: "pointer"
        }

        return(
            <React.Fragment>
                <Consumer.Consumer>
                {value => {
                    if(!value.cacheStore.recentTransactions || value.cacheStore.recentTransactions.length === 0)
                    {
                        return <div>No Recent Transactions found</div> 
                    }

                    return (
                    value.cacheStore.recentTransactions
                    .sort((a,b) => sortByDateDesc(a.transactionDate,b.transactionDate))
                    .slice(0, 7) // Take the first 7
                    .map((item, index) => {
                        const formattedDate = <Moment format="dddd, Do of MMMM">{item.transactionDate}</Moment>;
                        const showHeader = (lastDate !== item.transactionDate);
                        lastDate = item.transactionDate
                        return(
                            <React.Fragment key={index}>
                                {showHeader && 
                                    <Row className="tableViewGroupBarStyle">
                                        <Col md="12">
                                            {formattedDate}
                                        </Col>
                                    </Row>
                                }
                                <Row className="tableViewRowStyle" onClick={() => this.SelectItem(item.id)} style={itemStyle}>
                                    {workOutDescription(item)}
                                </Row>
                            </React.Fragment>
                        )
                    })
                
                )}}
                </Consumer.Consumer>
            </React.Fragment>
        )
    }
}
TransactionBox.contextType = Consumer
export default withRouter(TransactionBox)
