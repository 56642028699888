import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { roundUpToNearestPowerOfTen } from '../../Helpers/Formatter';

class LineChart extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            datasets: props.datasets,
            showGrid: props.showGrid,
            maxValue: props.maxValue || 0,
            minValue: props.minValue || 0,
            referenceValue: props.referenceValue,
            forceXZero: props.forceXZero
        };
    }

    componentWillReceiveProps(newProps) {
        if(newProps.datasets !== this.state.datasets) {
            this.setState({ 
                datasets: newProps.datasets, 
                showGrid: newProps.showGrid,
                maxValue: newProps.maxValue || 0,
                minValue: newProps.minValue || 0,
                referenceValue: newProps.referenceValue,
                forceXZero: newProps.forceXZero
            });
        }
    }
    
    render() {
        if(!this.state.datasets || this.state.datasets[0].data.length === 0) {
            return <React.Fragment><span>Loading data...</span></React.Fragment>
        }

        // We're going to work out the min and max values here, so we know if we need to show Negative values.
        const dataset = this.state.datasets[0].data;
        const dataMax = dataset.length > 0 ? Math.max(...dataset.map((i) => i.amount)) : 0;
        const dataMin = dataset.length > 0 ? Math.min(...dataset.map((i) => i.amount)) : 0;

        // Create chart variables so that we can set the display parameters
        let graphMin = dataMin;
        let graphMax = dataMax;

        // If we want to force show the 0 line, we do that. So it will override the display min.
        if(this.state.forceXZero) {
            if (dataMin > 0)
                graphMin = 0;
        }

        // Format the number nicely.
        graphMax = roundUpToNearestPowerOfTen(graphMax)
        graphMin = this.state.forceXZero ? 0 : roundUpToNearestPowerOfTen(graphMin) // If we're forcing 0 - leave it.

        const gradientOffset = () => {
          if (dataMax <= 0) {
            return 0;
          }
          if (dataMin >= 0) {
            return 1;
          }
          let result = dataMax / (dataMax - dataMin);
          return result;
        };
          
        const off = gradientOffset();

        return (
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart 
                    data={dataset} margin={{ top: 0, right: 30, left: 10, bottom: 8 }} >
                    { this.state.showGrid && <CartesianGrid strokeDasharray="1 1" />}
                    
                    <XAxis 
                        dataKey="label" 
                        angle={-30} 
                        textAnchor="end" 
                        style={{ fontSize: '0.7rem' }} 
                    />
                    <YAxis 
                        domain={[graphMin, graphMax]}
                        angle={-15}
                        style={{ fontSize: '0.7rem' }} 
                    />
                    <Tooltip />
                    <ReferenceLine 
                        y={this.state.referenceValue} 
                        stroke={dataMin < 0 ? "red" : ""} />
                    <defs>
                        <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                            <stop offset={off} stopColor="#00ee10" stopOpacity={1} />
                            <stop offset={off} stopColor="#ff0000" stopOpacity={1} />
                        </linearGradient>
                    </defs>
                    <Area 
                        animationDuration={700} 
                        dot={{ stroke: '#8884d8', strokeWidth: 1 }} 
                        type="monotone" 
                        dataKey="amount" 
                        stroke="#8884d8" 
                        fill="url(#splitColor)" 
                        fillOpacity={0.1} 
                    />
                </AreaChart>
            </ResponsiveContainer>
        );
    }
}

export default LineChart;
