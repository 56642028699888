import React from 'react'
import SelectInput from '../controls/selectInput'
import { Consumer } from  '../../context'

class BudgetEditor extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedBudgetId: this.props.value,
            budgetDisplayOptions: [],
        }
    }

    onChange(value) {
        this.setState( {
            selectedBudgetId: value
        })
        this.props.onChange(value)
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.selectedBudgetId !== nextProps.value) {
            this.setState({
                selectedBudgetId: nextProps.value
            })
        }
    }

    componentDidMount() {
        this.context.getBudgets(false)
            .then((budgets) => {
            this.setState({
                budgetDisplayOptions: budgets.sort(function(a, b) {
                    const textA = a.name.toUpperCase();
                    const textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
                .map(item => (
                    { id: item.id, value: item.name }
                ))
            })
        })
    }


    render() {
        const displayBudgetLabel = this.props.prefix ? `${this.props.prefix} Budget` : 'Budget'

        return (
            <React.Fragment>
                <SelectInput 
                      name="budgetId"
                      value={this.state.selectedBudgetId} 
                      label={displayBudgetLabel} 
                      help="Select a budget" 
                      onChange={(value) => this.onChange(value)}
                      options={this.state.budgetDisplayOptions}
                      allowBlank
                      isViewMode={this.props.isViewMode}
                    />
            </React.Fragment>
        )
    }

}
BudgetEditor.contextType = Consumer
export default BudgetEditor