import React from 'react'
import { Row, Col } from 'react-bootstrap'
import TransactionBox from './transactionBox'
import FutureBox from './futureBox'
import LineChart from './charts/lineChart'
import { Consumer } from '../context'
import moment from 'moment';
import ReactGA from 'react-ga';
import { goToTop } from 'react-scrollable-anchor'
import PanelHeader from './shared/panelHeader'
import LoaderDisplay from './controls/loaderDisplay'


class Dashboard extends React.Component {

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    this.context.getDashboardForcastBalanceReport()
    this.context.getDashboardHistoricBalanceReport(14)
    goToTop()
  }

  render() {

    const chartHolder = {
      height: "20vh",
      width: "100%"
    }

    return (
      <React.Fragment>
        <Consumer.Consumer>
          {value => {

            const historicData = [
                {
                  data: value.cacheStore.historicBalanceData
                    .map((item) => { return { amount: item.balance, label: moment(new Date(item.date)).format("Do MMM")} })
                }
              ]

            const futureData = [
                {
                  data: value.cacheStore.forecastBalanceData
                    .map((item) => { return { amount: item.balance, label: moment(new Date(item.date)).format("Do MMM")} })
                }
              ]

            return(
              <React.Fragment>
                 <Row>
                  <Col md="6">
                    <div className="borderBoxStyle">
                      <PanelHeader text="Historic Account Balance" link="/balancetrend" params={{ isForecast: false }} />
                      <div style={chartHolder}>
                        <LineChart 
                          datasets={historicData} 
                          showGrid={false} 
                          referenceValue={0}
                          forceXZero={true} 
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="borderBoxStyle">
                      <PanelHeader text="Projected Account Balance" link="/balancetrend" params={{ isForecast: true }} />
                      <div style={chartHolder}>                       
                          <LineChart 
                            datasets={futureData} 
                            showGrid={false} 
                            referenceValue={0}
                            forceXZero={true} 
                            />
                      </div>
                    </div>
                  </Col>

                </Row>

                <Row  className="top-buffer">
                  <Col md="5">
                    <div className="borderBoxStyle">
                      <PanelHeader text="Recent Transactions" /> 
                      <TransactionBox />
                    </div> 
                  </Col>
                  <Col md="7" >
                    <div className="borderBoxStyle">
                      <PanelHeader text="Upcoming Transactions"  link="/schedules" />  
                      <FutureBox />
                    </div>
                  </Col>
                </Row>  
              </React.Fragment>
            )
          }
        }</Consumer.Consumer>
      </React.Fragment>
    )
  }
}

Dashboard.contextType = Consumer
export default Dashboard