import React from 'react'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import {getDayNameFromDate, getMonthNameFromDate, getDayNumberFromDate} from '../Helpers/Formatter'
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap'
import { Consumer } from  '../context'
import { withToastManager, } from 'react-toast-notifications';
import LoaderDisplay from './controls/loaderDisplay';
import { DataTypes } from '../Constants'
import Moment from 'react-moment';

import TextInput from './controls/textInput'
import CheckboxInput from './controls/checkboxInput';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import ReactGA from 'react-ga';



class Tag extends React.Component {
  constructor(props) {
    super(props);
 
    this.state = {
      isLoading: false,
      tag: null,
      transactions: [],
      schedules: []
    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    this.loadTag()
  }

  loadTag() {
    const selectedId = this.props.match.params.id

    this.setState({ isLoading : true })
    this.context.getTag(selectedId)
      .then((tag) => {
        this.setState({
          tag: tag.tag,
          transactions: tag.transactions,
          schedules: tag.schedules,
          isLoading: false
        }, () => console.log(this.state))
      }) 
  } 

  onSelect(transactionId) {
    this.props.history.push(`/transaction/${transactionId}`);
  }

  handleChange(fieldName, value) {
     
  }

  render() {

    if(this.state.isLoading) {
      return <LoaderDisplay />
    }
    const buttonBarStyle = {
      display: "flex",
      justifyContent: "flex-end"
    }

    const buttonSpacingStyle = {
      padding: "4px",
      float: "left"
    }

    return (
      <React.Fragment >
        <AuthorisedArea showError>
        <div className="borderBoxStyle">  
          <Row>
            <Col>
              <Row>
                <Col>
                    <h5>Tag</h5>
                    <hr />
                </Col>
              </Row>
              {this.state.tag &&
                <React.Fragment>
                <Row>
                    <Col md="6">
                      <TextInput
                          label="Name"
                          help="Tag Name"
                          onChange={(value) => this.handleChange("name", value)}
                          value={this.state.tag?.name}
                          dataType={DataTypes.TEXT}
                          isViewMode={this.state.isViewMode}
                      />
                    </Col>
                    <Col md="3">
                      <CheckboxInput
                          value={!this.state.tag.deactivated}
                          label="Currently Active"
                          onChange={(value) => this.onChange('deactivated', value)}
                          help="Tag can be used"
                          />
                      </Col>
                  
                </Row>
                <Row className="top-buffer">
                    <Col md="12" xs="12">
                      {this.state.tag && this.state.transactions &&
                        <div className="borderBoxStyle">  
                          {this.state.transactions.map((transaction, index) => 
                            <Row className="tableViewRowStyle pointable" onClick={() => this.onSelect(transaction.id)} key={index}>
                              <Col md="2"><Moment format="Do MMM 'YY">{transaction.transactionDate}</Moment></Col>
                              <Col md="4">{transaction.description}</Col>
                              <Col md="4">{transaction.categoryName}: {transaction.subCategoryName}</Col>
                              <Col md="2" className="text-right"><strong>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Math.abs(transaction.amount))}</strong></Col>
                            </Row>  

                          )}
                        </div>
                      }
                    </Col>
              </Row>
              </React.Fragment>
          }
          </Col>
          </Row>
          </div>
          
        </AuthorisedArea>
      </React.Fragment>
    )
  }
}

  Tag.contextType = Consumer;
  export default withRouter(withToastManager(Tag));