import React from 'react'
import { Col, Row } from 'react-bootstrap'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import ScheduleCalendarCell from './scheduleCalendarCell'
import ScheduleDataAccessor from '../api/ScheduleDataAccessor'
import CheckboxInput from '../components/controls/checkboxInput'
import DateInput from './controls/dateInput'
import LoaderDisplay from './controls/loaderDisplay'
import ReactGA from 'react-ga';
import ScrollableAnchor from 'react-scrollable-anchor';
import { configureAnchors, goToAnchor } from 'react-scrollable-anchor'



class ScheduleCalendar extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            data: null,
            showSkipped: false,
            showPaid: false,
            selectedDate: new Date(),
            isLoading: true
        }
    }

    componentDidMount() {
        configureAnchors({offset: -60, scrollDuration: 50})
        goToAnchor('mainSection', false)
        ReactGA.pageview(window.location.pathname);
        this.getData();
        
    }

    getData() {
        this.setState({
            isLoading: true
        })
        ScheduleDataAccessor.calendar(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth() + 1)
            .then((result) => {
                this.setState({
                    data: result.data,
                    isLoading: false
                })

            })
    }

    changeFilterValue(field, value) {
        this.setState({
            [field]: value
        })
    }

    handleDateChange(value) {
        this.setState({
            selectedDate: value
        }, () => this.getData())
    }

    render() {

        const dayHeader = {
            background: "#f1f1f1",
            borderBottomWidth: "1px",
            borderBottomColor: "#c4c4c4",
            borderBottomStyle: "solid",
            paddingBottom: "6px",
            paddingTop: "6px",
            marginBottom: "0px",
            textAlign: "center",
            fontWeight: "600"   
        }

        
        const cellStyle = {
            borderStyle: "solid",
            borderWidth: "1px",
            minHeight: "105px",
            padding: "8px",
            borderColor: "#e8e8e8"
        }

        const cellContentStyle = {
            padding: "8px"
        }

        return(

            <React.Fragment>
                <AuthorisedArea showError>
                <ScrollableAnchor id={'mainSection'}>
                            
                        <div className="borderBoxStyle">  
                            <Row>
                                <Col><h4>Payment Calendar</h4><hr /></Col> 
                            </Row>
                            <Row> 
                                <Col md="3"><DateInput label="Select a Month" monthOnly value={this.state.selectedDate} onChange={(value) => this.handleDateChange(value)} /></Col>   
                                <Col md="3"><CheckboxInput label="Show Skipped" value={this.state.showSkipped} onChange={(value) => this.changeFilterValue('showSkipped', value)} /></Col>
                                <Col md="3"><CheckboxInput label="Show Paid" value={this.state.showPaid} onChange={(value) => this.changeFilterValue('showPaid', value)} /></Col>
                            </Row>
                        </div>
                        </ScrollableAnchor>
                        {this.state.isLoading && 
                            <LoaderDisplay />
                        }
                        {!this.state.isLoading &&

                            <div className="borderBoxStyle top-buffer">  
                                <Row style={dayHeader}>
                                    <Col>Monday</Col>
                                    <Col>Tuesday</Col>
                                    <Col>Wednesday</Col>
                                    <Col>Thursday</Col>
                                    <Col>Friday</Col>
                                    <Col>Saturday</Col>
                                    <Col>Sunday</Col>
                                </Row>
                                {this.state.data && this.state.data.weeks.map((week, index) => {
                                    return (
                                    <Row  className="nopadding" key={index}>
                                        {week.days.map((item, index2) => {
                                            return <Col key={index2} className="nopadding" style={cellStyle}>
                                                <div style={cellContentStyle}>
                                                    {item.isInSelectedMonth &&
                                                        <ScheduleCalendarCell 
                                                            showSkipped={this.state.showSkipped} 
                                                            showPaid={this.state.showPaid}
                                                            dayNumber={item.dayNumberOfWeek} 
                                                            events={item.events} 
                                                            thisMonth={item.thisMonth} 
                                                            isToday={item.isToday} 
                                                        />
                                                    }
                                                </div>
                                            </Col>
                                            })
                                        }
                                    </Row>
                                    )
                                })}
                            </div>
                        }
                </AuthorisedArea>
            </React.Fragment>

        )


    }

}

export default ScheduleCalendar
