import React from 'react';
import AuthorisedArea from '../Helpers/AuthorisedArea'
import { Row, Col } from 'react-bootstrap'
import SelectInput from './controls/selectInput'
import Button from './controls/button'
import TextInput from './controls/textInput'
import CheckboxInput from './controls/checkboxInput'
import { Consumer } from '../context'
import { withRouter } from 'react-router-dom';
import LoaderDisplay from '../components/controls/loaderDisplay'
import ReactGA from 'react-ga';
import { ButtonIcons } from './../Constants'
import { formatMoney } from '../Helpers/Formatter';

class Accounts extends React.Component {
    static contextType = Consumer

    constructor(props, context) {
        super(props)
        this.context = context
        this.state = {
            isLoading: true,
            selectedTypeId: 1,
            searchString: null,
            filteredAccounts: [],
            includeInactive: false
        }

        this.onChange = this.onChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.search = this.search.bind(this);
        this.updateList = this.updateList.bind(this);
    }


    onChange(value) {
        this.props.history.push(`/accounts/${value}`)

        this.setState({
            selectedTypeId: value,
        }, () => this.updateList())
    }

    updateList() {
        this.context.getAllAccounts(false)
            .then((result) => {
                const filteredAccounts = result
                .filter(item => item.accountType === this.state.selectedTypeId || this.state.selectedTypeId === 0)
                .filter(item => item.name.toLowerCase().includes(this.state.searchString && this.state.searchString.toLowerCase()) || !this.state.searchString )
                .sort(function(a, b) {
                    const textA = a.name.toUpperCase();
                    const textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })

                this.setState({
                    filteredAccounts,
                    isLoading: false
                })
            })
    }


    onSelect(transactionId) {
        this.props.history.push(`/account/${transactionId}`);
    }

    search(text) {
        this.setState({
            searchString: text
        }, () => this.updateList())
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
        const selectedType = this.props.match.params.id
        if(selectedType) {
            this.onChange(parseInt(selectedType)) // Can fail if invalid item in query string?
        }
        this.updateList()
    }


    render() {

        // Grab the selected AccountId from the state. If the Link property passed the data, use it.

        console.log(this.state)

        const options = [
            {id: 0, value: 'All'},
            {id: 1, value: 'Bank Accounts'},
            {id: 2, value: 'Third Parties'},
        ]

        const itemStyle = {
            cursor: "pointer"
        }

        
    const buttonBarStyle = {
        display: "flex",
        justifyContent: "flex-end"
      }

        return (
            
                <AuthorisedArea showError>
                    <div className="borderBoxStyle">
                <Row>
                    <Col md="3" >
                        <SelectInput 
                            label="Account Type" 
                            placeholder="Select an Account Type" 
                            help="Select an account type to filter" 
                            options={options}
                            value={this.state.selectedTypeId} 
                            onChange={(value) => this.onChange(value)}
                        />
                    </Col>
                    <Col md="3">
                        <TextInput
                            value={this.state.searchString}
                            help="Type in a Search String"
                            onChange={(value) => this.search(value)}
                            placeholder="Search Text"
                            label="Search"
                            />

                    </Col>
                    <Col md="3">
                        <CheckboxInput
                            value={this.state.includeInactive}
                            help="Show Inactive Accounts"
                            onChange={(value) => this.setState({ includeInactive: value})}
                            label="Include Inactive"
                        />

                    </Col>
                    <Col md="3">
                        <div style={buttonBarStyle}>
                        <Button 
                            variant="primary" 
                            onClick={() => {this.props.history.push(`/account/`);}}
                            text="Create Account"
                            icon={ButtonIcons.CREATE}
                        />
                        </div>
                    </Col>
                </Row>
                </div>

                {this.state.isLoading && 
                    <LoaderDisplay />
                } 
                {!this.state.isLoading && 
                <div className="borderBoxStyle top-buffer">
                    <Row>
                        <Col md="12" >
                            <Row className="tableViewHeaderStyle">  
                                <Col md="3">Account Name</Col>
                                <Col md="2" className="text-right">Balance</Col>
                                <Col md="1">Active</Col>
                                <Col md="1">Favourite</Col>
                            </Row>
                            {this.state.filteredAccounts.length === 0 &&
                                <div>No accounts available</div>
                            }
                            {this.state.filteredAccounts
                                .filter((item) => this.state.includeInactive || item.isActive === true)
                                .map((item, index) => 
                                <Row 
                                    key={index} 
                                    onClick={() => this.onSelect(item.id)} 
                                    className="tableViewRowStyle" 
                                    style={itemStyle} 
                                >
                                    <Col md="3">{item.name}</Col>
                                    <Col md="2" className="text-right">$ {formatMoney(item.balance)}</Col>
                                    <Col md="1">{item.isActive && <span>{ButtonIcons.YES}</span>}</Col>
                                    <Col md="1">{item.isFavourite && <span>{ButtonIcons.YES}</span> }</Col>
                                </Row>    
                            )}
                        </Col>
                    </Row>
                    </div>
                }
                </AuthorisedArea>
            
        )
    }
}
export default withRouter(Accounts);