import React from 'react';
import { BarChart as BC, Bar, Cell, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ChartColours } from '../../Constants';

class BarChart extends React.Component {
    render() {

        // Function to darken a hex color
        const darkenColor = (hex, amt) => {
            hex = hex.slice(1);
            let num = parseInt(hex, 16);
            let r = (num >> 16) + amt;
            let g = ((num >> 8) & 0x00FF) + amt;
            let b = (num & 0x0000FF) + amt;

            let newColor = (
                0x1000000 +
                (r < 255 ? (r < 1 ? 0 : r) : 255) * 0x10000 +
                (g < 255 ? (g < 1 ? 0 : g) : 255) * 0x100 +
                (b < 255 ? (b < 1 ? 0 : b) : 255)
            )
                .toString(16)
                .slice(1);
            return `#${newColor}`;
        };

        return (

            <ResponsiveContainer width="100%" height="100%">
                <BC
                    width={500}
                    height={300}
                    data={this.props.data.datasets[0].data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" />
                    <YAxis />
                    <Tooltip formatter={(value, name, props) => [`$${value.toFixed(2)}`]} />
                    <Bar dataKey="amount" activeBar={<Rectangle fill="gold" stroke="purple" />} >
                        {this.props.data.datasets[0].data.map((entry, index) => (
                            <Cell 
                            dataKey="label" 
                            key={entry.label} 
                            fill={ChartColours[index % ChartColours.length]}
                            stroke={darkenColor(ChartColours[index % ChartColours.length], -40)} // Match border color with fill color
                            strokeWidth={1} // Border width
                            />
                        ))}
                    </Bar>
                </BC>
            </ResponsiveContainer>
        )
    }


}

export default BarChart;
