import fetchData from '../Helpers/FetchData'
import Server from '../Constants'
import { HandleResponse } from './ResponseHandler' 

const userUrl = `${Server.ApiURL}/api/user`;

class UserDataAcessor {

    static getUser() {
        return new Promise((resolve, reject) => {
          const request = {
            URL: userUrl,
            method: 'GET'
          };
    
          fetchData(request)            
          .then((response) => {  
              HandleResponse(response)
                  .then((result) => {
                      resolve(result)
              })
          })
          .catch((err) => {
              console.log('Error caught!', err);
              reject(err);
          });
                });
        }

    static getTimezones() {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/api/timezones`,
                method: 'GET'
              };

              fetchData(request)            
          .then((response) => {  
              HandleResponse(response)
                  .then((result) => {
                      resolve(result)
              })
          })
          .catch((err) => {
              console.log('Error caught!', err);
              reject(err);
          });
                });
    }

    static registerUser(data) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/register`,
                method: 'POST',
                data: data
              };

              fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                        })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        })
    }

    static saveUser(data) {
        return new Promise((resolve, reject) => {
            const request = {
              URL: userUrl,
              method: 'POST',
              data: data
            };
      
            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                        })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        });
    }


    static changePassword(data) {
        return new Promise((resolve, reject) => {
            const request = {
              URL: `${Server.ApiURL}/api/user/changepassword`,
              method: 'POST',
              data: data
            };
      
            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                        })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        });
    }


    static pollChanges() {
        return new Promise((resolve, reject) => {
            const request = {
              URL: `${Server.ApiURL}/api/heartbeat`,
              method: 'GET'
            };
      
            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                        })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        });
    }
}

export default UserDataAcessor;