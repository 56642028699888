import React from 'react'
import { Row, Col } from 'react-bootstrap'
import SelectInput from './controls/selectInput'
import DateInput from './controls/dateInput'
import Button from './controls/button'
import CurrencyInput from './controls/currencyInput'
import { Consumer } from '../context'
import { withToastManager, } from 'react-toast-notifications';

class QuickTransaction extends React.Component {
    static contextType = Consumer
    
    constructor(props, context) {
        super(props)
        this.context = context
        this.state = {
            accounts: [],
            selectedAccountId: null,
            selectedDate: new Date(),
            selectedAmount: null,
            isBusy: false,
        }
    }

    componentDidMount() {
        this.context.getQuickAccounts()
            .then((data) => {
                const accounts = data.sort(function(a, b) {
                    const textA = a.name.toUpperCase();
                    const textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
                .map(item => ({ id: item.id, value: item.name }))

                this.setState({
                    accounts
                })
            })
    }

    handleChange(fieldName, value) {
        this.setState({
            [fieldName]: value
        })
    }

    save() {
        this.setState({
            isBusy: true
        })
        const { toastManager } = this.props;
        const request = {
            creditAccountId: this.state.selectedAccountId,
            date: this.state.selectedDate,
            amount: this.state.selectedAmount
        }

        this.context.saveQuickTransaction(request)
            .then((result) => {
                if(result.ok) {
                    this.setState({
                        selectedAccountId: null,
                        selectedDate: new Date(),
                        selectedAmount: 0,
                        isBusy: false
                    })
                    toastManager.add(`Transaction Saved `, {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: false,
                        });

                    
                } 
                else
                {
                    toastManager.add(result.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: false,
                      });
                      this.setState({
                        isBusy: false
                    })
                }
        })
    }

    render() {


        return (
            <React.Fragment>
                <Row>
                    <Col md="12">
                        <SelectInput
                          label="Pay who?"
                          options={this.state.accounts}
                          value={this.state.selectedAccountId}
                          onChange={(value) => this.handleChange("selectedAccountId", value)}
                        />
                    </Col>
                    <Col md="12">
                        <DateInput
                          label="Date"
                          value={this.state.selectedDate}
                          onChange={(value) => this.handleChange("selectedDate", value)}
                        />
                    </Col>
                    <Col md="12">
                        <CurrencyInput
                          label="Amount"
                          value={this.state.selectedAmount}
                          onChange={(value) => this.handleChange("selectedAmount", value)}
                        />
                    </Col>
                    <Col md="12" className="text-right bottomAlign">
                        <Button 
                            text="Save"
                            onClick={() => this.save()}
                            isBusy={this.state.isBusy}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

}

export default withToastManager(QuickTransaction)