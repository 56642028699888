import React from 'react'
import { Row, Col } from 'react-bootstrap'
import CurrencyInput from './controls/currencyInput'
import Button from './controls/button'
import CategoryEditor from './subcomponents/categoryEditor'
import BudgetEditor from './subcomponents/budgetEditor'
import { Consumer } from  '../context'
import { ButtonIcons } from '../Constants'


class TransactionLineEditor extends React.Component {

    constructor(props) {
      super(props)
      this.state = {
        lineIndex: this.props.lineIndex,
        subCategoryId: this.props.subCategoryId,
        budgetId: this.props.budgetId,
        amount: this.props.amount,
      }
      this.handleControlChange = this.handleControlChange.bind(this);
    }

    handleControlChange(fieldName, value) {
      this.setState({[fieldName]: value})
      this.props.onChange(this.state.lineIndex, fieldName, value)
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.budgetId !== this.state.budgetId) {
        this.setState({ budgetId: nextProps.budgetId})
      }
      if(nextProps.subCategoryId !== this.state.subCategoryId)
      {
        this.setState({ subCategoryId: nextProps.subCategoryId})
      }
    }

    render() {

      const currencyBoxStyle = {
        display: "flex",
        alignItems: "center"
      }

        return(
            <React.Fragment>
                <Row>
                  <Col md="6" xs="12">
                    <CategoryEditor
                      value={this.state.subCategoryId}
                      onChange = {(value) => this.handleControlChange("subCategoryId", value)} 
                      isViewMode ={this.props.isViewMode}
                    />
                  </Col>
                  <Col md="3" xs="12">
                    <BudgetEditor
                      value={this.state.budgetId}
                      onChange={(value) => this.handleControlChange("budgetId", value)}
                      isViewMode={this.props.isViewMode}
                      />

                  </Col>    
                  <Col md="3" xs="6">
                    <div style={currencyBoxStyle}>
                      <div>
                    <CurrencyInput 
                      value={this.state.amount} 
                      label="Transaction Value" 
                      help="Amount of the Transaction" 
                      onChange = {(value) => this.handleControlChange("amount", value)}
                      isViewMode={this.props.isViewMode}
                    />
                    </div>
                  
                  {this.props.allowDelete && !this.props.isViewMode &&
                    <div>
                      <Button 
                        onClick={() => this.props.onDelete()}
                        variant="outline-danger"
                        icon={ButtonIcons.DELETE}

                      />
                      </div>
                  }
                  </div>
                  </Col>
                </Row>
            </React.Fragment>
        )
    }
}

TransactionLineEditor.contextType = Consumer
export default TransactionLineEditor