import React from 'react'
import { isNumberic, formatMoney } from '../../Helpers/Formatter'
import ControlLabel from './controlLabel';
import ControlText from './controlText'

class CurrencyInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value && Number.parseFloat(this.props.value).toFixed(2)
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        
        let value = e.target.value
        if(!value)
        {
            this.setState({
                value: null
            })
            this.props.onChange(null);
            return
        }

        if(isNumberic(value, true)) {
            this.setState({ value })
            this.props.onChange(Number(value));
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.state) {

           this.setState({
               value: nextProps.value,
           })
        }
   }

    render() {

        const inputStyle = {
            textAlign : "right"
        }

        return (
            <React.Fragment>
                <div>
                    <ControlLabel text={this.props.label} />
                </div>
                {!this.props.isViewMode &&
                    <React.Fragment>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <input
                                value={this.state.value} 
                                type="number" 
                                className="form-control" 
                                onChange={this.onChange} 
                                style={inputStyle}
                            />
                        </div>
                        <div className="hintStyle">
                            {this.props.help}
                        </div>
                    </React.Fragment>
                }
                {this.props.isViewMode && 
                    <ControlText text={formatMoney(this.state.value)} />
                }
            </React.Fragment>
        )
    }
}

export default CurrencyInput