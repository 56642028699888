import React from 'react';
import { withToastManager, } from 'react-toast-notifications';
import ReactGA from 'react-ga';
import TextInput from './controls/textInput'
import Button from './controls/button'
import { Form, Row, Col } from 'react-bootstrap'
import TimezoneEditor from '../components/subcomponents/timezoneEditor'
import { Consumer } from '../context';

class Register extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            firstname: null,
            surname: null,
            email: null,
            password: null,
            confirmpassword: null,
            timezoneId: null,
            isBusy: false
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    handleRegister() {
      this.setState({
        isBusy: true
      })

      this.context.registerUser(this.state)
        .then((result) => {
          const { toastManager } = this.props;
          if(result.ok) {
            this.props.history.push("/");
                toastManager.add("Registration Success", {
                    appearance: 'success',
                    autoDismiss: true,
                    pauseOnHover: false,
                  });
                return; 
          } else {
              this.setState({
                isBusy: false
              })
                toastManager.add(`Registration Failed - ${result.message}`, {
                    appearance: 'error',
                    autoDismiss: true,
                    pauseOnHover: false,
                  });
                return; 
          }
        })
    }

    handleChange(fieldName, value) {
      this.setState({
        [fieldName]: value
      })
    }

    render() {

        
        return (
          <React.Fragment >

          <Row>
                        <Col lg="6" >
                          <h1>AccuFinance</h1>
                          <h3>Simple home finance management</h3>
                          <hr className="intro-divider" />
                          <h5>info@accufinance.com</h5>
                        </Col>
                        <Col className="intro-message" lg="5">
                          {/* <div>
                            <h3>Welcome to AccuFinance</h3>
                          </div> */}
                          <div> 
               <Form className="text-left">
                    <h3>Registration</h3>
                    <Form.Group>
                    <Row>
                        <Col md="6">
                          <TextInput 
                            label="Firstname"
                            id="Firstname" 
                            placeholder="Your Firstname" 
                            value={this.state.firstname} 
                            onChange={(e) => this.handleChange('firstname', e)} 
                          />
                        </Col>
                        <Col>
                          <TextInput 
                          label="Surname"
                            id="Surname" 
                            placeholder="Your Surname" 
                            value={this.state.surname} 
                            onChange={(e) => this.handleChange('surname', e)} 
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextInput 
                          label="Email"
                            id="Email" 
                            placeholder="Your Email" 
                            type="email" 
                            value={this.state.email} 
                            onChange={(e) => this.handleChange('email', e)} 
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <TextInput 
                          label="Password"
                            id="Password" 
                            placeholder="Your Password" 
                            type="password" 
                            value={this.state.password} 
                            onChange={(e) => this.handleChange('password', e)} 
                          />
                        </Col>
                        <Col>
                          <TextInput 
                          label="Retype Password"
                            id="Password" 
                            placeholder="Retype Password" 
                            type="password" 
                            value={this.state.retypepassword} 
                            onChange={(e) => this.handleChange('retypepassword', e)} 
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="intro-control">
                          <TimezoneEditor
                            value={this.state.timezoneId}
                            onChange={(value) => this.handleChange('timezoneId', value)} />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center">
                          <Button 
                            variant="primary" 
                            onClick={() => this.handleRegister()} 
                            text='Register'
                            isBusy={this.state.isBusy}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form> 
                  </div>
                  </Col>
                  </Row>
                  </React.Fragment>
        )
    }
}

Register.contextType = Consumer
export default withToastManager(Register);