import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Consumer } from '../../context';
import { withToastManager, } from 'react-toast-notifications';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Auth from '../../Authentication'
import { ButtonIcons } from '../../Constants';


class NavBar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        expanded: false
      }

      this.handleLogout = this.handleLogout.bind(this);

    }

    handleLogout() {
      const { toastManager } = this.props;
      const { pathname } = this.props.location;
      this.context.changeAuthenticated(false);
      this.context.logout();
      
      if(pathname !== "/") {
        this.props.history.push("/");
      }
      toastManager.add('You\'re now logged out', {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }

    setExpanded(value) {
      this.setState({
        expanded: value
      })
    }

    render() {


      const isAuthenticated = Auth.isAuthenticated();

      const iconStyle = {
        paddingRight: "6px"
      }

      return (
              <React.Fragment>

                <Navbar expanded={this.state.expanded} expand="lg" fixed="top" className="navbarGradientBackground shadowBottomStyle">
                    <Navbar.Brand as={Link} to='/'>AccuFinance</Navbar.Brand>
                      <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => this.setExpanded(this.state.expanded ? false : "expanded")} />
                        <Navbar.Collapse id="responsive-navbar-nav">
                          <Nav className="ml-auto">
                      
                          <Nav.Link onClick={() => this.setExpanded(false)} as={Link} to='/' exact>{ButtonIcons.HOME} Home</Nav.Link>
                          { isAuthenticated && 
                            <React.Fragment>
                              <Nav.Link onClick={() => this.setExpanded(false)} as={Link} to='/transaction/'>{ButtonIcons.TRANSACTION} New Transaction</Nav.Link>
                              
                              <Nav.Link onClick={() => this.setExpanded(false)} as={Link} to='/transactionsearch/'>{ButtonIcons.SEARCH} Search</Nav.Link>
                              <Nav.Link onClick={() => this.setExpanded(false)} as={Link} to='/accounts' exact>{ButtonIcons.ACCOUNTS} My Accounts</Nav.Link>
                              <Nav.Link onClick={() => this.setExpanded(false)} as={Link} to='/thirdparties' exact>{ButtonIcons.THIRDPARTIES} 3rd Parties</Nav.Link>
                              <Nav.Link onClick={() => this.setExpanded(false)} as={Link} to='/schedules' exact>{ButtonIcons.SCHEDULES} Schedules</Nav.Link>
                              <Nav.Link onClick={() => this.setExpanded(false)} as={Link} to='/tags' exact>{ButtonIcons.SCHEDULES} Tags</Nav.Link>
                              <Nav.Link onClick={() => this.setExpanded(false)} as={Link} to='/investments' exact>{ButtonIcons.INVESTMENTS} Investments</Nav.Link>
                              <Nav.Link onClick={() => this.setExpanded(false)} as={Link} to='/calendar' exact>{ButtonIcons.CALENDAR} Payment Calendar</Nav.Link>
                              <Nav.Link onClick={() => this.setExpanded(false)} as={Link} to='/budgets' exact>{ButtonIcons.BUDGETS} Budgets</Nav.Link>
                              <NavDropdown  alignRight title={ButtonIcons.REPORTING} id="collasible-nav-dropdown">
                                <NavDropdown.Item onClick={() => this.setExpanded(false)} as={Link} to="/balancetrend">Balance Trends</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => this.setExpanded(false)} as={Link} to="/budgettrend">Budget Trends</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => this.setExpanded(false)} as={Link} to="/categoryamountreport">Category Amounts</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => this.setExpanded(false)} as={Link} to="/categoriesovertime">Category Over Time</NavDropdown.Item>     
                              </NavDropdown>

                              <NavDropdown alignRight title={<span>{ButtonIcons.SETTINGS}</span>} id="collasible-nav-dropdown">
                                <NavDropdown.Item onClick={() => this.setExpanded(false)} as={Link} to="/categories">Categories and Subcategories</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => this.setExpanded(false)} as={Link} to="/user">User</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => this.setExpanded(false)} as={Link} to="/security">Security</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => this.setExpanded(false)} as={Link} to="/import">Transaction Import</NavDropdown.Item>

                                <NavDropdown.Item onClick={() => this.setExpanded(false)} as={Link} to="/settings/payments">Payments</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => this.setExpanded(false)} as={Link} to="/debug">Debug Page</NavDropdown.Item>
                              </NavDropdown>
                            </React.Fragment>
                          }
                          { !isAuthenticated && 
                            <Nav>
                              <Nav.Link  onClick={() => this.setExpanded(false)} as={Link} to='/register' exact><i style={iconStyle} className="fas fa-user "></i> Register</Nav.Link>
                              <Nav.Link  onClick={() => this.setExpanded(false)} as={Link} to='/recover' exact><i style={iconStyle} className="fas fa-unlock "></i> Recover Password</Nav.Link>
                            </Nav>
                          }
                          { isAuthenticated &&
                            <Nav.Link  
                             // onClick={() => { this.setExpanded(false); this.handleLogout }} 
                              as={Link} 
                              to= ' ' 
                              exact 
                              onClick={() => this.handleLogout()}>{ButtonIcons.LOGOUT} </Nav.Link>
                            
                          }
                        </Nav>
                      </Navbar.Collapse>
                </Navbar>
              </React.Fragment>
            )
          }
        }
  NavBar.contextType = Consumer;
  export default withRouter(withToastManager(NavBar));