import React from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import ControlLabel from './controlLabel';
import ControlText from './controlText';

class DateInput extends React.Component {


    handleDateChange(newDate) {
        if(newDate !== null) {
            const date = new Date(newDate)
            const userTimezoneOffset = date.getTimezoneOffset() * 60000;
            const reply = new Date(date.getTime() - userTimezoneOffset);
            this.props.onChange(reply)
        }
    }

    // Utility function to validate date strings
    isValidDate(dateString) {
        const date = new Date(dateString);
        return !isNaN(date.getTime());
    }


    render() {

        var displayDate = "" // Show blank if we have a null input.
        const validDate = this.isValidDate(this.props.value );

        if(validDate) {
            const d = new Date(this.props.value)
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
            const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d)
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    
            displayDate = this.props.value ? `${da} ${mo} ${ye}` : "" // Show blank if we have a null input.
        } 

        return (
            <React.Fragment>
                <div>
                    <ControlLabel text={this.props.label} />
                </div>
                <div>
                    {!this.props.monthOnly &&
                         <React.Fragment>
                            {!this.props.isViewMode && 
                                <DatePicker className="form-control"
                                    dateFormat="dd MMM yyyy"
                                    selected={Date.parse(this.props.value)}
                                    onChange = {(e) => this.handleDateChange(e)}
                                    readOnly = {this.props.readOnly}
                                />
                            }
                            {this.props.isViewMode &&
                                <ControlText text={displayDate} />
                            }
                        </React.Fragment>
                    }
                    {this.props.monthOnly &&
                        <DatePicker className="form-control"
                            dateFormat="MMMM yyyy"
                            selected={Date.parse(this.props.value)}
                            onChange = {(e) => this.handleDateChange(e)}
                            readOnly = {this.props.readOnly}
                            showMonthYearPicker
                        />
                    }
                </div>
                {!this.props.isViewMode && 
                    <div className="hintStyle">
                        {this.props.help}
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default DateInput