import React from 'react'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import { Col, Row } from 'react-bootstrap'
import SelectInput from './controls/selectInput'
import DateInput from './controls/dateInput'
import TextInput from './controls/textInput'
import Button from './controls/button'
import CheckboxInput from './controls/checkboxInput'
import CurrencyInput from './controls/currencyInput'
import CategoryEditor from './subcomponents/categoryEditor'
import BudgetEditor from './subcomponents/budgetEditor'
import { Consumer } from '../context'
import { withToastManager, } from 'react-toast-notifications';
import LoaderDisplay from './controls/loaderDisplay';
import { AccountTypes, ButtonIcons } from '../Constants'
import ReactGA from 'react-ga';


class AccountEditor extends React.Component {

    constructor(props) {
        super(props)

        let account = {
            id: null,
            name: null,
            accountTypeId: null,
            date: Date.now(),
            openingBalance: null,
            defaultSubCategoryId: null,
            defaultBudgetId: null,
            isActive: true,
            isFavourite: false,
            canQuickTransaction: false,
            isLoading: false
        }

        this.state = {
            account,
            isLoading: false,
            selectedId: null
        }
        this.saveAccount = this.saveAccount.bind(this);
        
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
        const selectedId = this.props.match.params.id
        if(selectedId) {
            this.setState({
                isLoading: true
            })

            this.context.getAccount(selectedId)
                .then((result) => {
                    const account = {
                        name: result.name,
                        accountTypeId: result.accountType,
                        date: result.date,
                        openingDate: result.openingDate,
                        openingBalance: result.openingBalance,
                        defaultSubCategoryId: result.defaultSubcategoryId,
                        defaultBudgetId: result.defaultBudgetId,
                        isActive: result.isActive,
                        id: result.id,
                        isFavourite: result.isFavourite,
                        canQuickTransaction: result.canQuickTransaction
                    }
                    this.setState(
                        {   
                            account, 
                            isLoading: false
                        })
                })
        }
    }

    onChange(controlName, value) {
        var account = this.state.account
        account[controlName] = value

        this.setState({
            account
        })
    }

    saveAccount() {
        const { toastManager } = this.props;
        this.context.saveAccount(this.state.account)
            .then((result) => {
                this.props.history.goBack();
                toastManager.add(`Account, ${this.state.account.name}, Saved Successfully.`, {
                    appearance: 'success',
                    autoDismiss: true,
                    pauseOnHover: false,
                    });
            })
    }

    handleCancel() {
        const { toastManager } = this.props;
        this.props.history.goBack();
            toastManager.add(`No changes were made.`, {
                appearance: 'success',
                autoDismiss: true,
                pauseOnHover: false,
            });
    }


    render() {

        const options = [
            {id: 1, value: 'Bank Account'},
            {id: 2, value: 'Third Party'},
        ]

        const buttonSpacingStyle = {
            padding: "4px",
            float: "left"
          }
      
          const buttonBarStyle = {
            display: "flex",
            justifyContent: "flex-end"
          }

        if(this.state.isLoading) {
            return (
                <LoaderDisplay />
            )
        }

        return (
            <React.Fragment>
                <AuthorisedArea showError>
                    <div className="borderBoxStyle">
                        <Row>
                            <Col md="6" >
                                <TextInput 
                                    label="Name" 
                                    placeholder="The name of the account" 
                                    help="Enter the name of the account" 
                                    value={this.state.account.name}
                                    onChange={(value) => this.onChange('name', value)}
                                />   
                            </Col>
                            <Col md="6" >
                                <SelectInput 
                                    name="accountTypeId"
                                    label="Type" 
                                    placeholder="Select an Account Type" 
                                    help="The type of account this is" 
                                    options={options} 
                                    onChange={(value) => this.onChange('accountTypeId', value)}
                                    value={this.state.account.accountTypeId}
                                />
                            </Col>
                        </Row>
                        
                        <Row>
                            {this.state.account.accountTypeId === AccountTypes.BANKACCOUNT &&
                                <React.Fragment>
                                    <Col md="3" >
                                        <DateInput 
                                            label="Date Opened" 
                                            placeholder="Date on which this account was opened" 
                                            help="Date account was opened" 
                                            onChange={(value) => this.onChange('openingDate', value)}
                                            value={this.state.account.openingDate}
                                        />
                                    </Col>
                                    <Col md="3" >
                                        <CurrencyInput 
                                            label="Opening Balance" 
                                            placeholder="Balance when the account is opened" 
                                            help="Opening balance" 
                                            onChange={(value) => this.onChange('openingBalance', value)}
                                            value={this.state.account.openingBalance}
                                        />
                                    </Col>
                                    </React.Fragment>
                            }
                            { this.state.account.accountTypeId  &&
                            <Col md="2">
                                <CheckboxInput
                                    value={this.state.account.isActive}
                                    label="Active"
                                    onChange={(value) => this.onChange('isActive', value)}
                                    help="Account is active"
                                    />
                            </Col>
                            }
                            {this.state.account.accountTypeId === AccountTypes.BANKACCOUNT &&
                            <Col md="2">
                                <CheckboxInput
                                    value={this.state.account.isFavourite}
                                    label="Favourite"
                                    onChange={(value) => this.onChange('isFavourite', value)}
                                    help="Account is favourite"
                                    />
                            </Col>
                            }
                            {this.state.account.accountTypeId === AccountTypes.THIRDPARTY &&
                            <Col md="2">
                                <CheckboxInput
                                    value={this.state.account.canQuickTransaction}
                                    label="Quick Transaction"
                                    onChange={(value) => this.onChange('canQuickTransaction', value)}
                                    help="Enable Quick Transactions"
                                    />
                            </Col>
                            }
                        </Row>
                        {this.state.account.accountTypeId === AccountTypes.THIRDPARTY &&
                        <Row>
                            <Col md="8">
                                <CategoryEditor 
                                    prefix="Account's Default"
                                    onChange={(value) => this.onChange('defaultSubCategoryId', value)}
                                    value={this.state.account.defaultSubCategoryId}
                                />
                            </Col>
                            <Col md="4">
                                <BudgetEditor 
                                    prefix="Account's Default"
                                    onChange={(value) => this.onChange('defaultBudgetId', value)}
                                    value={this.state.account.defaultBudgetId}
                                />
                            </Col>
                        </Row>
                        }
                        
                        <Row>
                            <Col>
                            <div style={buttonBarStyle}>
                                <Button 
                                    isBusy={this.state.isLoading}
                                    onClick={() => this.saveAccount()}
                                    text="Save"
                                    icon={ButtonIcons.SAVE}
                                    variant="primary"
                                    style={buttonSpacingStyle}
                                    />
                                <Button 
                                    onClick={() => this.handleCancel()}
                                    text="Cancel"
                                    variant='outline-primary'
                                    icon={ButtonIcons.CANCEL}
                                    style={buttonSpacingStyle}
                                />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </AuthorisedArea>
            </React.Fragment>
        )
    }
}

AccountEditor.contextType = Consumer
export default withToastManager(AccountEditor)