import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Register from '../components/register';
import Login from '../components/login'
import Recover from '../components/recover'
import { Row, Col, Container, Image } from 'react-bootstrap'
import cover1 from '../images/cover-1.jpg'
import cover2 from '../images/cover-2.jpg'

class Home extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      displayMessage: false
    }
  }

  componentDidMount() {
    // Handle email vcalidation
    const id = this.getId(window.location.search)
    if(id) {
      this.context.validateEmail(id)
        .then((result) => {
          this.setState({
            displayMessage: true,
            displayText: result.message
          })
        })
    }

  }

  // Get's the emailvalidationid from the URL if it exists.
  getId(location) {
    const searchParams = new URLSearchParams(location);
    for (let p of searchParams) {
      if(p.length===2) {
        if(p[0] === 'emailvalidationid')
          return p[1];
      }
    }
  }

  render() {

    const LoginRegister =                   
      <Switch>
        <Route exact path="/register" component={Register} />
        <Route path = "/recover" component={Recover} />
        <Route path = "" component={Login} />
      </Switch>

    const DisplayMessage = <div>
      {this.state.displayText}
    </div>


    return (

      <React.Fragment >
        <div className="intro-header">
          <Container>
              {DisplayMessage}
              {LoginRegister}

          </Container>
        </div>
        <div className="content-section-a">
          <Container>
            <Row>
              <Col lg="5" sm="6">
                <h2 className="section-heading">
                  What is AccuFinance?
                </h2>
                <p className="lead">
                  AccuFinance is a solution for simplifying your home financing management.
                </p>
                <p>
                  Developed by people who truly understand what home users want when it comes to home finance management, AccuFinance addresses the needs of people to achieve an understanding of their financial position.
                </p>
              </Col>
              <Col lg={{ span: 5, offset: 2 }} sm="6">
                <Image src={cover2} fluid />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="content-section-b">
          <Container>
            <Row>
            <Col lg={{ span: 5, offset: 1, pull: 6 }} sm="6">
                <Image src={cover1} fluid />
              </Col>
              <Col lg="5" sm="6">
                <h2 className="section-heading">
                  What does AccuFinance do?
                </h2>
                <p className="lead">
                  It gives you instant insight into your home finance position.
                </p>
                <p>
                  By providing you with a graphical overview of your finances, both past, <strong>and</strong> future forecast, you're
                  able to make decisions based on facts. AccuFinance won't advise you. It's empowers you to make your own decisions, based
                  on your past, and scheduled expenditure.
                </p>
              </Col>

            </Row>
          </Container>
        </div>
        <div className="banner">
          <Container>
            <Row>
              <Col lg="6">
                <h2>Contact AccuFinance:</h2>
              </Col>
              <Col lg="6">
                <ul className="list-inline banner-social-buttons">
                  <li>
                    <a href="mailto:info@accufinance.com" className="btn btn-lg btn-light">
                      <i className="fa fa-envelope fa-fw"></i>
                      <span className="network-name"> Email</span>
                    </a>
                  </li>
                  <li>
                    <a href="http://www.linkedin.com" className="btn btn-lg btn-light">
                    <i className="fab fa-linkedin"></i>
                      <span className="network-name"> LinkedIn</span>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

//Home.contextType = Consumer
export default Home;