import React from 'react'
import { Row, Col } from 'react-bootstrap'
import AuthorisedArea from '../Helpers/AuthorisedArea'

class SheduledItemEdit extends React.Component {

    render() {
        return(
            <React.Fragment >
                <AuthorisedArea showError>
                <Row>
                    <Col className="borderBoxStyle">
                        <Row>
                            <Col>
                                <h5>Scheduled Event Editor</h5>
                                <hr></hr>
                            </Col>
                        </Row>
                        <Row>
                            
                        </Row>
                    </Col>
                </Row>
              </AuthorisedArea>
            </React.Fragment>
        )
    }
}

export default SheduledItemEdit
