import React from 'react'
import { Button, Row, Col, Modal, Form } from 'react-bootstrap'
import DateInput from './controls/dateInput'
import TextInput from './controls/textInput'
import { Consumer } from '../context'
import Moment from 'react-moment'

class ScheduleDateEditor extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            scheduleId: null,
            paymentNumber: null,
            isVisible: false,
            currentDate: null,
            newDate: null,
            maximumAllowedDate: null,
            minimumAllowedDate: null,
            applyAll: false,
            description: null
        }
    }


    UNSAFE_componentWillReceiveProps(newProps) {
        if(newProps.event && (newProps.show !== this.state.isVisible || newProps.event.date !== this.state.currentDate)) {
            this.setState({
                isVisible: newProps.show,
                currentDate: newProps.event.date,
                scheduleId: newProps.event.scheduleId,
                paymentNumber: newProps.event.paymentNumber,
                maximumAllowedMoveDate: newProps.event.maximumAllowedMoveDate,
                minimumAllowedMoveDate: newProps.event.minimumAllowedMoveDate
            })
        }
    }

    handleChange(fieldName, value) {
        this.setState({
        [fieldName]: value
        })
    }

    handleClose() {
        this.setState({
            isVisible: false
        })
    }

    handleSave() {
        const data = {
            scheduleId: this.state.scheduleId,
            paymentNumber: this.state.paymentNumber,
            newDate: this.state.currentDate,
            description: this.state.description,
            continueChange: this.state.applyAll
        }

        console.log("Data", data)
        this.context.scheduleVariation(data) 
            .then((result) => {
                this.props.callbackMethod(true);
                this.setState({
                    isVisible: false
                })
            })
   
    }


    render() {

    

    

    const event = this.props.event

    return (
        <Modal 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.isVisible} 
        onHide={() => this.handleClose()} 
        animation={true}
      >
        <Modal.Header closeButton>
            <Modal.Title>Scheduled Date Change</Modal.Title>
        </Modal.Header>
        {event &&
        <Modal.Body>
          <Row>
              <Col md="12">
                  
                    <p>The date cannot overlap the next or previous scheduled payment. 
                      Therefore, the available range in which you can move this transaction 
                      is between <strong><Moment format="Do of MMMM">{this.state.minimumAllowedMoveDate}</Moment></strong>, and the <strong><Moment format="Do of MMMM">{this.state.maximumAllowedMoveDate}</Moment></strong>,
                      unless moving all future payments.
                    </p>
              </Col>
            </Row>
          <Row>
              <Col md="6">
                  <DateInput 
                      value={this.state.currentDate}
                      label="New Date"
                      onChange={(value) => this.handleChange("currentDate", value)}
                      help="The date on which this payment is scheduled"
                      startDate={this.state.minimumAllowedMoveDate}
                      endDate={this.state.maximumAllowedMoveDate}
                  />
              </Col>
            </Row>
            <Row>
                <Col md="7" xs="12">
                    <TextInput 
                        label="Description"
                        placeholder="Optional Description"
                        value={this.state.description}
                        onChange={(value) => this.handleChange("description", value)}

                    />
                </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Check 
                      type="switch"
                      id={`custom-switch-drumm`}
                      label="Apply to all future payments?"
                      checked={this.state.applyAll}
                      onChange={(event) => this.handleChange("applyAll", event.target.checked)}
                  />
              </Col>
          </Row>
          </Modal.Body>
                  }

          <Modal.Footer>
              <Button variant="secondary" onClick={() => this.handleClose()}>
                  Cancel
              </Button>
              <Button variant="primary" onClick={() => this.handleSave()}>
                  Save Changes
              </Button>
          </Modal.Footer>
      </Modal>
    )
}


}

ScheduleDateEditor.contextType = Consumer
export default ScheduleDateEditor
