export const HandleResponse = (response) => {
    return new Promise((resolve) => {

        if(response.status.toString().charAt(0) === '5') {
            console.log("Unhandled exception from API!")
            resolve(
                { 
                    ok: false, 
                    message: "Unhandled exception has occured" 
                })
            return
        }


        // Handle bad request
        if(response.status.toString() === "400") {
            response.json()
            .then((data) => {
                let errorText = data.description ? data.description : "Ensure all fields are completed"
               
                resolve(
                    { 
                        ok: false, 
                        message: errorText
                    })
                
            })
            return
        }

        if(!response.data.hasOwnProperty('responseState')){
            console.log("Object is missing redponse state or responseState")
        }

        if(response.status.toString().charAt(0) !== '2')
        {
            console.log("HTTP code was not OK. Rejecting.", response.status)
            resolve(
                { 
                    ok: false, 
                    message: "Some other erroroccured. Can show status", 
                })
        }

                
        const reply = {
            ok: response.data.responseState.isSuccess, 
            message: response.data.responseState.description, 
            data: response.data.data
        }
        resolve (reply)
    });
}
 