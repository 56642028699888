import React from 'react';
import AuthorisedArea from '../Helpers/AuthorisedArea'
import {Row, Col} from 'react-bootstrap'
import Moment from 'react-moment'

class Attachments extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            attachments: this.props.attachments
        }
    }

    render() {
        return (
            <AuthorisedArea showError>
                        <h5>Attachments</h5>
                        <hr />
                        <Row className="tableViewHeaderStyle">
                            <Col md="3">Uploaded</Col>
                            <Col md="3">Description</Col>
                            <Col md="3">Filename</Col>
                            <Col  className="text-right" md="2">Size</Col>
                            <Col  className="text-right" md="1"></Col>
                        </Row>
                        {this.state.attachments.map((attachment, index) => 
                            <Row key={index} className="tableViewRowStyle">
                                <Col md="3"><Moment format="dddd, Do of MMMM">{attachment.uploadedDate}</Moment></Col>
                                <Col md="3"><strong>{attachment.description}</strong></Col>
                                <Col md="3">{attachment.fileName}</Col>
                                <Col  className="text-right" md="2">{(attachment.size / 1024 / 1024).toFixed(2)} Mb</Col>
                                <Col  className="text-right" md="1">
                                    <a href={attachment.uri} target="_blank" title="View" ><i className="far fa-eye"></i></a>&nbsp;&nbsp;&nbsp;
                                    <a href={attachment.uri} target="_blank" title="Delete"><i className="far fa-trash-alt"></i></a>
                                </Col>
                            </Row>
                        )}
            </AuthorisedArea>
        )
    }
}

export default Attachments