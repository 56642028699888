import React from 'react'

class ControlLabel extends React.Component {

    render() {

        const labelStyle = {
            fontWeight: '600',
            marginLeft: '0px',
            marginBottom: '2px',
            marginTop: '2px',
            color:  "black",
            fontSize: "12px",
            opacity: "60%",

        }

        return(
            <span style={labelStyle}>
                {this.props.text}
            </span>
        )
    }
}

export default ControlLabel