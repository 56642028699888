import React from 'react';
import { withToastManager, } from 'react-toast-notifications';
import TextInput from './controls/textInput'
import Button from './controls/button'
import { Form, Row, Col } from 'react-bootstrap'
import AuthenticationDataAccessor from '../api/AuthenticationDataAccessor'

class Recover extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            email: null,
            pin: null,
            password: null,
            confirmpassword: null,
            isBusy: false,
            awaitPin: false
        }
    }

    handleChange(fieldName, value) {
      this.setState({
        [fieldName]: value
      })
    }

    handleSend() {
      this.setState({ isBusy: true })
      AuthenticationDataAccessor.sendPasswordResetCode(this.state.email)
        .then((result) => {
          this.setState({ isBusy: false })
            this.setState({ awaitPin: true })
            const { toastManager } = this.props;
            toastManager.add("A validation PIN has been emailed to you. Please enter the pin once you get it", {
              appearance: 'success',
              autoDismiss: true,
              pauseOnHover: false,
          });
        })
    }

    handlePin() {
      this.setState({ awaitPin: false })
    }

    render() {
        
        return (
          <React.Fragment >

          <Row>
                        <Col lg="6" >
                          <h1>AccuFinance</h1>
                          <h3>Simple home finance management</h3>
                          <hr className="intro-divider" />
                          <h5>info@accufinance.com</h5>
                        </Col>
                        <Col className="intro-message" lg="5">
                          {/* <div>
                            <h3>Welcome to AccuFinance</h3>
                          </div> */}
                          <div> 
               <Form className="text-left">
                    <h3>Password Recovery</h3>
                    <Form.Group>
                    {!this.state.awaitPin &&
                    <React.Fragment>
                      <Row>
                          <Col md="6">
                            <TextInput 
                              label="Your Email Address"
                              placeholder="Your Email Address" 
                              value={this.state.email} 
                              onChange={(e) => this.handleChange('email', e)} 
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-center">
                            <Button 
                              variant="primary" 
                              onClick={() => this.handleSend()} 
                              text='Send Code'
                              isBusy={this.state.isBusy}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                      }
                      {this.state.awaitPin &&
                        <React.Fragment>
                          <Row>
                            <Col>
                              <p>An email has been sent to your email address, {this.state.email}. Once you get it, enter the PIN below.</p>
                            </Col>
                          </Row>
                          <Row>
                          <Col md="6">
                            <TextInput 
                              label="Validation Code"
                              placeholder="Code" 
                              value={this.state.pin} 
                              onChange={(e) => this.handleChange('pin', e)} 
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-center">
                            <Button 
                              variant="primary" 
                              onClick={() => this.handlePin()} 
                              text='Enter your Validation Code'
                              isBusy={this.state.isBusy}
                            />
                          </Col>
                        </Row>

                        </React.Fragment>
                      }
                    </Form.Group>
                  </Form> 
                  </div>
                  </Col>
                  </Row>
                  </React.Fragment>
        )
    }
}

export default withToastManager(Recover);