import React from 'react'
import { withRouter } from 'react-router-dom';
import { withToastManager, } from 'react-toast-notifications';
import { Col, Row } from 'react-bootstrap'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import TextInput from './controls/textInput'
import { Consumer } from '../context'
import LoaderDisplay from './controls/loaderDisplay'
import ReactGA from 'react-ga';
import Button from '../components/controls/button'

class Security extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            currentPassword: null,
            newPassword: null,
            retypePassword: null
        }

        this.onChange = this.onChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }


    onChange(fieldName, value) {
        this.setState( {
            [fieldName] : value
        })
    }


    onSave() {

        const { toastManager } = this.props;
        if(!this.state.newPassword || this.state.newPassword !== this.state.retypePassword) {
            toastManager.add("New password validation failed", {
                appearance: 'error',
                autoDismiss: true,
                pauseOnHover: false,
            });
            return;

        }


        const request = {
            currentPassword: this.state.currentPassword,
            newPassword: this.state.newPassword,
        }

        this.context.changePassword(request)
            .then((result) => {
                if(result.ok) {
                    toastManager.add(result.message, {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
                    this.props.history.goBack()
                    return
                    
                }

                toastManager.add(result.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
        })
    }


    render() {
        if(this.state.isLoading)
            return <LoaderDisplay />

        const cancelClick = () => {
            this.props.history.goBack();
            }

            const buttonSpacingStyle = {
                padding: "4px",
                float: "left"
              }
          
              const buttonBarStyle = {
                display: "flex",
                justifyContent: "flex-end"
              }
        // Can we enable the save button?
        const isValid = this.state.newPassword && 
                        (this.state.newPassword === this.state.retypePassword) &&
                        this.state.currentPassword

        return (
            <React.Fragment>
                <AuthorisedArea showError>
                    <div className="borderBoxStyle">
                <Row >
                    <Col md="12">
                        <h5>User Security</h5>
                        <hr />
                    </Col>
                    </Row>
                <Row>
                    <Col md="4" >
                        <TextInput 
                            label="Current Password"
                            value={this.state.currentPassword}
                            help="Your current password"
                            placeholder="Current Password"
                            type="password"
                            onChange={(value) => this.onChange('currentPassword', value)}
                        />
                    </Col>
                    </Row>
                    <Row>
                    <Col md="4" >
                        <TextInput 
                            label="New Password"
                            value={this.state.newPassword}
                            help="New password"
                            type="password"
                            placeholder="New Password"
                            onChange={(value) => this.onChange('newPassword', value)}
                        />
                    </Col>
                    <Col md="4" >
                        <TextInput 
                            label="Retype New Password"
                            value={this.state.retypePassword}
                            help="Retype your new password"
                            type="password"
                            placeholder="Retype Password"
                            onChange={(value) => this.onChange('retypePassword', value)}
                        />
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                    <div style={buttonBarStyle}>
                        <Button 
                            onClick={() => this.onSave()}
                            text="Save"
                            disabled={!isValid}
                            variant="primary"
                            style={buttonSpacingStyle}
                        />

                        <Button 
                            onClick={() => cancelClick()}
                            text="Cancel"
                            variant="outline-primary"
                            style={buttonSpacingStyle}
                        />
                            
                    </div>
                    </Col>
                </Row>
                </div>
                </AuthorisedArea>

            </React.Fragment>
        )
    }
}

Security.contextType = Consumer
export default withRouter(withToastManager(Security))