import fetchData from '../Helpers/FetchData'
import Server from '../Constants'
import { HandleResponse } from './ResponseHandler' 

const accountUrl = `${Server.ApiURL}/api/accounts`;

class AccountDataAccessor {

   
    static saveAccount(account) {
        return new Promise((resolve, reject) => {
            const request = {
              URL: `${Server.ApiURL}/api/account`,
              method: 'POST',
              data : {
                  id: account.id,
                  name: account.name,
                  accountTypeId: account.accountTypeId,
                  openingDate: account.openingDate,
                  openingBalance: account.openingBalance,
                  isFavourite: account.isFavourite,
                  defaultSubCategoryId: account.defaultSubCategoryId,
                  defaultBudgetId: account.defaultBudgetId,
                  isActive: account.isActive,
                  canQuickTransaction: account.canQuickTransaction
              }
          }

          fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        })
    }


    static getAllAccounts() {
        return new Promise((resolve, reject) => {
            const request = {
                URL: accountUrl,
                method: 'POST',
                data : {
                    IncluedDeactivated: true,
                    includeDeleted: false,
                    includeBalances: true,
                    FilterAccountType: null
                }
            }
    
            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result.data)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        })
    }

    static getAccount(id) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/api/account/${id}`,
                method: 'GET'
            }
                
            fetchData(request)            
            .then((response) => {  
                HandleResponse(response)
                    .then((result) => {
                        resolve(result)
                })
            })
            .catch((err) => {
                console.log('Error caught!', err);
                reject(err);
            });
        })
    }

    static deleteAccount(id) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/api/account/${id}`,
                method: 'DELETE'
            }
                
            fetchData(request)            
            .then((response) => {  
                HandleResponse(response)
                    .then((result) => {
                        resolve(result)
                })
            })
            .catch((err) => {
                console.log('Error caught!', err);
                reject(err);
            });
        })
    }

}

export default AccountDataAccessor;