import React from 'react'
import { Consumer } from '../context'
import { Button } from 'react-bootstrap'

class Debug extends React.Component {
    constructor(props) {
        super(props);
        this.refreshAccounts = this.refreshAccounts.bind(this);
    }

    refreshAccounts() {

        this.context.getBudgets(true);
        this.context.getAllAccounts(true);
        this.context.getRecentTransactions(true, 10);
        this.context.getCategories(true);
        this.context.getDashboardForcastBalanceReport()
        this.context.getDashboardHistoricBalanceReport(14, true)

    }


    render() {

        return (
            <React.Fragment>
                <Consumer.Consumer>
                    {value => {
                        return(
                        <div>
                            <h1>Debug Screen</h1>
                            <Button className="info" onClick={() => this.refreshAccounts()}>Update Context Data</Button>
                        </div>
                        )
                    }
                }
            </Consumer.Consumer>
            </React.Fragment>
        )
    }
}

Debug.contextType = Consumer
export default Debug