import React from 'react'
import { ProgressBar, Row, Col } from 'react-bootstrap'
import { formatMoney } from '../Helpers/Formatter'
import { Link } from 'react-router-dom';
import { Consumer } from '../context'

class BudgetBox extends React.Component {

    componentDidMount() {
        this.context.getBudgets(false)
    }
    render() {
        const boxStyle={
            marginBottom: "8px"
        }

        const params = {
            budgetId: this.props.budgetId
        }

        const budgetItemStyle = {
            paddingBottom: "14px"
        }

        return (
            <div style={boxStyle}>
                <Consumer.Consumer>
                    {value => { 
                        return(
                            <React.Fragment>
                                {value.cacheStore.budgets
                                    .sort(function(a, b) {
                                        const textA = a.name.toUpperCase();
                                        const textB = b.name.toUpperCase();
                                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                    })
                                    .map((item, index) => {   
                                        const used = (`$ ${formatMoney(item.used)} of $ ${formatMoney(item.budgetAmount)} used.`);
                                        const percentage = item.used / item.budgetAmount * 100;
                                        const varient = percentage <= 50 ? 'success' : percentage <= 75 ? 'warning' : 'danger'
                                        return (
                                            <React.Fragment key={index}>
                                                <div style={budgetItemStyle}>
                                                <Row>
                                                    <Col>
                                                        <div><Link to={{pathname: `/budget/${item.id}`, state: params}}>{item.name}</Link></div>
                                                        <div className="labelStyle">{used}</div>
                                                    </Col>
                                                </Row>
                                                <ProgressBar variant={`${varient}`} now={percentage} label={`${Math.round(percentage)} %`} />
                                                </div>
                                        </React.Fragment>
                                    )
                                })}
                                
                            </React.Fragment>
                        )}
                        }
                        
                    </Consumer.Consumer>
                
            </div>
        )
    }
}

BudgetBox.contextType = Consumer
export default BudgetBox
