/* global fetch:false */
import Auth from '../Authentication';
import axios from 'axios'

async function fetchData ({ method = 'GET', URL, data = {}, contentType = 'application/json'}) {

  const config = {
    method: method,
    headers: { Authorization: `Bearer ${Auth.token()}`, 'content-type': contentType },
    url: URL,
    data: data
  };
  const res = await axios(config)
  return res

};

export default fetchData;
