import React from 'react';
import NavBar from './navbar';
import InfoBar from './infobar';
import { Consumer } from '../../context';

class Header extends React.Component {
    
    render() {
        return(
            <Consumer.Consumer>
                {value => {
                    return (
                        <div >
                            <NavBar />
                            <InfoBar />
                        </div>
                    )
                }}
            </Consumer.Consumer>
        )
    }
}

export default Header;
