import React from 'react'
import { Row, Col } from 'react-bootstrap'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import LoaderDisplay from '../components/controls/loaderDisplay'
import { Consumer } from '../context'
import TextInput from '../components/controls/textInput'
import { ButtonIcons } from '../Constants'
import Button from './controls/button'
import DateInput from './controls/dateInput'

import { withRouter } from 'react-router-dom';
import SchedulesItem from './schedulesItem'

import ReactGA from 'react-ga';

class Tags extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fromDate: null,
            toDate: null,
            isLoading: true,
            tags: []
        }

        this.SelectTag = this.SelectTag.bind(this)
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
       
        this.context.getDetailedTags(this.state.fromDate, this.state.toDate)
            .then((result) => {
                this.setState({
                    tags: result.sort(function(a, b) {
                        const textA = a.name.toUpperCase();
                        const textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                      }),
                    isLoading: false
                })
            })

    }

    getData() {
        this.setState({
            tags: [],
            isLoading: true
        })

        this.context.getDetailedTags(this.state.fromDate, this.state.toDate)
            .then((result) => {
                this.setState({
                    tags: result.sort(function(a, b) {
                        const textA = a.name.toUpperCase();
                        const textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                      }),
                    isLoading: false
                })
            })
    }

    SelectTag(tagId) {
        console.log("Will load", tagId)
        this.props.history.push(`/tag/${tagId}`);
    }

    Create() {
        this.props.history.push(`/tag/`);
    }

    handleChange(fieldName, value) {
        this.setState({ [fieldName]: value })
        
    }

    handleSearch() {
        
        console.log("Will search")
        this.getData();
    }
     
    render() {

        return (

            <React.Fragment>
            <AuthorisedArea showError>
                <div className="borderBoxStyle">
                    <Row>
                        <Col md="3">
                            <DateInput label="From" value={this.state.fromDate}
                                        onChange={(value) => this.handleChange('fromDate', value)}/>
                        </Col>
                        <Col md="3">
                            <DateInput label="To" value={this.state.toDate}
                                        onChange={(value) => this.handleChange('toDate', value)}/>
                        </Col>
                        <Col md="3" className="d-flex flex-column justify-content-end">
                            <Button 
                                icon={ButtonIcons.SEARCH} 
                                onClick={() => this.handleSearch()}
                                text="Search"
                                style={{ marginBottom: '6px' }}
                            />  
                        </Col>
                        <Col md="3" className="d-flex flex-column justify-content-end text-right">
                            <Button 
                                icon={ButtonIcons.CREATE} 
                                onClick={() => this.Create()}
                                text="Create New Tag"
                                style={{ marginBottom: '6px' }}
                            />
                        </Col> 
                    </Row>
                </div>
                {this.state.isLoading && 
                    <LoaderDisplay />
                } 
                {!this.state.isLoading && 
                <div className=" top-buffer borderBoxStyle">
                    <Row>
                        <Col md="12" >
                            <Row className="tableViewHeaderStyle">  
                                <Col md="3" sm="8">Tag Name</Col>
                                <Col md="3" className="text-right d-none d-md-block">Transactions</Col>
                                <Col md="2" className="text-right d-none d-sm-block">Schedules</Col>
                                <Col md="2" className="d-none d-sm-block">Active</Col>
                                <Col md="2" sm="4" className="text-right">Amount</Col>
                                
                            </Row>
                            {this.state.tags === 0 &&
                                <div>No tags available</div>
                            }
                            {this.state.tags
                                .map((item, index) => 
                                
                                    <Row className="tableViewRowStyle pointable" key={index} onClick={() => this.SelectTag(item.id)}>
                                        <Col md="3"><strong>{item.name}</strong></Col>
                                        <Col md="3" className="text-right d-none d-md-block">{item.assignedTransactions}</Col>
                                        <Col md="2" className="text-right d-none d-sm-block">{item.assignedSchedules}</Col>
                                        <Col md="2" className="d-none d-sm-block">{item.deactivated ? "No" : "Yes"}</Col>
                                        <Col md="2" className="text-right" style={{ color: item.transactionSum < 0 ? 'red' : 'green' }}>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Math.abs(item.transactionSum))}</Col>
                                        
                                    </Row>
                            )}
                        </Col>
                    </Row>
                    </div>
                }
                
                </AuthorisedArea>
            </React.Fragment>
        )
    }


}

Tags.contextType = Consumer
export default withRouter(Tags)