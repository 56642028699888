import fetchData from '../Helpers/FetchData'
import Server from '../Constants'
import { HandleResponse } from './ResponseHandler' 

const accountUrl = `${Server.ApiURL}/api/schedule`;

class ScheduleDataAccessor {

    static saveSchedule(data) {
        console.log("Sav", data)
        return new Promise((resolve, reject) => {
    
          const request = {
            URL: accountUrl,
            method: 'POST',
            data: data
          };

          fetchData(request)            
                .then((response) => {
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        });
    }

    static deleteSchedule(id) {
        return new Promise((resolve, reject) => {
    
          const request = {
            URL: `${Server.ApiURL}/api/schedule/${id}`,
            method: 'DELETE',
          };
          fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        });
    }

    static getSchedule(id) {
        return new Promise((resolve, reject) => {
            const request = {
                URL:  `${Server.ApiURL}/api/schedule/${id}`,
                method: 'GET'
            };
            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        })
    }


    static getUpcoming(filter) {
        return new Promise((resolve, reject) => {
    
          const request = {
            URL: `${Server.ApiURL}/api/schedule/upcoming`,
            method: 'POST',
            data: filter
          };
          fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        });
    }

    static pay(scheduleId, paymentNumber) {
        return new Promise((resolve, reject) => {
          const request = {
            URL: `${Server.ApiURL}/api/schedule/${scheduleId}/pay/${paymentNumber}`,
            method: 'POST',
          };
          
          fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    });
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        });
    }

    static variation(data) {
        return new Promise((resolve, reject) => {
            const request = {
              URL: `${Server.ApiURL}/api/schedule/variation`,
              method: 'POST',
              data: data
            };
            
            fetchData(request)            
            .then((response) => {  
                HandleResponse(response)
                    .then((result) => {
                        resolve(result)
                      });
                  })
                  .catch((err) => {
                      console.log('Error caught!', err);
                      reject(err);
                  });
          });
    }

    static deleteVariation(variationId) {
        return new Promise((resolve, reject) => {
            const request = {
              URL: `${Server.ApiURL}/api/schedule/variation/${variationId}`,
              method: 'DELETE',
            };
            
            fetchData(request)            
            .then((response) => {  
                HandleResponse(response)
                    .then((result) => {
                        resolve(result)
                      });
                  })
                  .catch((err) => {
                      console.log('Error caught!', err);
                      reject(err);
                  });
          });
    }

    static calendar(year, month) {
        return new Promise((resolve, reject) => {
          const request = {
            URL: `${Server.ApiURL}/api/schedule/calendar/${year}/${month}`,
            method: 'GET',
          };
          
          fetchData(request)            
          .then((response) => {  
              HandleResponse(response)
                  .then((result) => {
                      resolve(result)
              })
          })
          .catch((err) => {
              console.log('Error caught!', err);
              reject(err);
          });
        });
    }
}

export default ScheduleDataAccessor;