import React from 'react'
import Select from 'react-select'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Button } from 'react-bootstrap'
import ControlLabel from './controlLabel'
import ControlText from './controlText';


class MultiSelectInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            values: this.props.values ?? [],
            options: this.props.options,
            componentName: this.props.name,
            isViewMode: this.props.isViewMode,
        }
        this.onChange = this.onChange.bind(this);

    }

    onChange(values) {
        this.setState(
            { values }, 
            () => { 
                
                this.props.onChange(values)
            });
    }

    /*
        returns the text/label for the selected item.
    */
    textBasedOnValue() {
      return this.state.values?.map(item => item.label).join(", ");
    }

    handleCreate(input) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Add new Third Party</h1>
                    <p>This will create a new Third Party, "{input}". Continue?</p>
                    <Button onClick={onClose}>No</Button>&nbsp;
                    <Button
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Yes, Create it!
                    </Button>
                  </div>
                );
              }
          });
    }

    // This method handles updates to props that get triggered from the parent.
    componentWillReceiveProps(nextProps) {
         if(nextProps !== this.state) {

            this.setState({
                values: nextProps.values,
                options: nextProps.options
            })
         }
    }

    render() {

        const text = this.textBasedOnValue();

        return (
            <React.Fragment>
                <div>
                    <ControlLabel text={this.props.label} />
                </div>
                {!this.props.isViewMode &&
                    <div>
                    
                        <Select
                            isLoading={false} 
                            placeholder={this.props.placeholder} 
                            defaultValue={this.state.values}
                            onChange={this.onChange}  
                            options = {this.state.options}
                            isDisabled={this.props.readOnly}
                            isMulti={true}
                        />
                    
                    </div>
                    }
                     {this.props.isViewMode &&
                        <ControlText text={text} />
                    }
                    {!this.props.isViewMode && 
                        <div className="hintStyle">
                            {this.props.help}
                        </div>
                    }
            </React.Fragment>
        )
    }
}

export default MultiSelectInput