import React from 'react'
import AuthorisedArea from '../../Helpers/AuthorisedArea'
import { Row, Col } from 'react-bootstrap'
import DoughnutChart from '../charts/doughnutChart'
import { Consumer } from '../../context'
import LoaderDisplay from '../controls/loaderDisplay'
import DateInput from '../controls/dateInput'
import SelectInput from '../controls/selectInput'
import CheckboxInput from '../controls/checkboxInput'

class CategoryAmountReport extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            advancedSelection: false,
            selectedCategoryId: null,
            reportRange: 1,
            fromDate: null,
            toDate: null,
            chartData: [],
            isLoading: true
          };
    }

    
    componentDidMount() {

        this.getReport()
        
    }

    getReport() {

        this.setState({
            isLoading: true
        })
        
        // A set of colours to use. Note, if there are more than 10 budgets, this will break! Need a method to loop.
        const backgroundColours = [
            '0, 135, 108', 
            '89, 157, 113', 
            '141, 179, 124', 
            '189, 200, 142', 
            '235, 221, 168', 
            '230, 187, 127', 
            '227, 150, 97', 
            '222, 109, 82', 
            '212, 61, 81',
            '0, 135, 108',
            '93, 158, 138',
            '149, 180, 169',
            '201, 201, 201',
            '213, 159, 159',
            '216, 115, 119',
            '212, 61, 81',
        ]

        let history = 730

        switch(this.state.reportRange) {
            case 1: history = 30; break;
            case 2: history = 60; break;
            case 3: history = 90; break;
            case 4: history = 180; break;
            case 5: history = 365; break;
            default: history = 730;
        }

        this.context.getCategoryAmountReport(
                this.state.selectedCategoryId, 
                this.state.advancedSelection ? this.state.fromDate : null, 
                this.state.advancedSelection ? this.state.toDate: null, 
                !this.state.advancedSelection ? history : null)
            .then((data) => {
                const chartData = {
                    // labels: data
                    //     .sort(function(a, b) {
                    //         return (a.amount < b.amount) ? 1 : (a.amount > b.amount) ? -1 : 0;
                    //     })
                    //     .slice(0, 15)
                    //     .map((item, index) => { return `${item.category} - ${item.subcategory}`}),
                    datasets: [
                        {
                            
                            backgroundColor: backgroundColours.map((color) => `rgba(${color}, 0.3)`),
                            borderColor: backgroundColours.map((color) => `rgba(${color}, 0.7)`),
                            borderWidth: 1,
                            hoverBackgroundColor: backgroundColours.map((color) => `rgba(${color}, 1)`),
                            hoverBorderColor: backgroundColours.map((color) => `rgba(${color}, 0.7)`),
                            data: data
                                .sort(function(a, b) {
                                    return (a.amount < b.amount) ? 1 : (a.amount > b.amount) ? -1 : 0;
                                })
                                .slice(0, 15)
                                .map((item) => { return { amount: item.amount, label:  `${item.category} - ${item.subcategory}` }})
                        }]
                };

                this.setState({
                    chartData,
                    isLoading: false
                })
            })
    }

    handleChange(fieldName, value) {
        this.setState({ [fieldName]: value }, () => this.getReport())
    }

    render() {

        const chartHolder = {
            height: "50vh",
            width: "100%"
          }

          const periods = [
            {id: 1, value: "Past month"},
            {id: 2, value: "Past 2 months"},
            {id: 3, value: "Past 3 Months"},
            {id: 4, value: "Past 6 Months"},
            {id: 5, value: "Past 1 Year"}
        ]


        return(
            <AuthorisedArea showError>
                <div className="borderBoxStyle">
                    <Row>
                        <Col>
                            <h4>Category Amount Report</h4>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="2">
                            <CheckboxInput label="Advanced Selection" value={this.state.advancedSelection} onChange={(value) => this.handleChange('advancedSelection', value)} />
                        </Col>
                        {this.state.advancedSelection === false &&
                            <Col md="3">
                                <SelectInput label="Report Period" value={this.state.reportRange} onChange={(value) => this.handleChange('reportRange', value)} options={periods}  />
                            </Col>
                        }
                        {this.state.advancedSelection &&
                            <React.Fragment>
                            <Col md="3">
                                <DateInput label="From" value={this.state.fromDate} onChange={(value) => this.handleChange('fromDate', value)} />
                            </Col>
                            <Col md="3">
                                <DateInput label="To" value={this.state.toDate} onChange={(value) => this.handleChange('toDate', value)} />
                            </Col>
                            </React.Fragment>
                        }
                    </Row>
                </div>
                <div className="borderBoxStyle top-buffer">
                    <div style={chartHolder}>
                        {this.state.isLoading && 
                            <LoaderDisplay />
                        }
                        {!this.state.isLoading && 
                            <DoughnutChart data={this.state.chartData} />
                        }
                    </div>
                </div>

            </AuthorisedArea>
        )
    }
}

CategoryAmountReport.contextType = Consumer
export default CategoryAmountReport