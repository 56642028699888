import React from 'react'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import { Col, Row } from 'react-bootstrap'
import TextInput from './controls/textInput'
import SelectInput from './controls/selectInput'
import { Consumer } from '../context'
import { withToastManager, } from 'react-toast-notifications';
import LoaderDisplay from './controls/loaderDisplay';
import ReactGA from 'react-ga';
import Button from './controls/button'
import { ButtonIcons } from '../Constants'


class InvestmentEditor extends React.Component {

    constructor(props) {
        super(props)

        let investment = {
            id: null,
            name: null,
            code: null,
            currentValue: null,
            volume: null,
            change24Hr: null,
            investmentTypeId: 1
        }

        let investmentTypes = [
            {id: 1, value: 'Cryptocurrency'},
            {id: 2, value: 'Commodity'},
        ]

        this.state = {
            investment,
            investmentTypes,
            isLoading: false,
            historicPrices: [],
            isBusy: false
            
        }   

        this.saveInvestment = this.saveInvestment.bind(this);
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
        const selectedId = this.props.match.params.id
        if(selectedId) {
            this.setState({
                isLoading: true
            })

            this.context.getInvestment(selectedId)
                .then((result) => {
                    const investment = {
                        id: result.id,
                        name: result.investmentType.name,
                        code: result.investmentType.code,
                        change24Hr: result.change24Hr,
                        currentValue: result.currentValue,
                        volumeOwned: result.volumeOwned,
                        investmentTypeId: result.investmentType.investmentTypeId

                    }
                    this.setState(
                        {
                            investment, 
                            isLoading: false
                         })
                })
        }
    }

    onChange(controlName, value) {
        const investment = this.state.investment
        investment[controlName] = value
        this.setState({
            investment
        })
    }

    saveInvestment() {
        this.setState({
            isBusy: true
        })

        const { toastManager } = this.props;
        this.context.saveInvestment(this.state.investment)
            .then((result) => {    
                if(result.ok) {
                this.props.history.goBack();
                toastManager.add(`${this.state.investment.name}, Saved Successfully.`, {
                    appearance: 'success',
                    autoDismiss: true,
                    pauseOnHover: false,
                    });
                }
                else {
                    toastManager.add(result.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: false,
                      });
                      return        
                }
            })
    }

    handleCancel() {
        const { toastManager } = this.props;
        this.props.history.goBack();
            toastManager.add(`No changes were made.`, {
                appearance: 'success',
                autoDismiss: true,
                pauseOnHover: false,
            });
    }

    handleDelete() {
        this.setState({
            isBusy: true
        })
        
        this.context.deleteInvestment(this.state.investment.id)
            .then((result) => {
                const { toastManager } = this.props;
                if(result.ok) {
                    this.props.history.goBack();
                    toastManager.add(result.message, {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
                }
                else {
                    toastManager.add(result.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
                }
            })
    }


    render() {

        if(this.state.isLoading) {
            return (
                <LoaderDisplay />
            )
        }

        const buttonSpacingStyle = {
            padding: "4px",
            float: "left"
          }
      
          const buttonBarStyle = {
            display: "flex",
            justifyContent: "flex-end"
          }

        return (
            <React.Fragment>
                <AuthorisedArea showError>
                    <div className="borderBoxStyle">
                        <Row>
                            <Col md="3" >
                                <TextInput 
                                    label="Name" 
                                    placeholder="Name" 
                                    help="Enter the name of the investment" 
                                    value={this.state.investment.name}
                                    onChange={(value) => this.onChange('name', value)}
                                />   
                            </Col>
                            <Col md="3" >
                                <TextInput 
                                    label="Code" 
                                    placeholder="Code" 
                                    help="Enter the code of the investment" 
                                    value={this.state.investment.code}
                                    onChange={(value) => this.onChange('code', value)}
                                />   
                            </Col>
                            <Col md="3" >
                                <SelectInput 
                                    name="investmentTypeId"
                                    label="Type" 
                                    placeholder="Select an Investment Type" 
                                    help="The type of investment this is" 
                                    options={this.state.investmentTypes} 
                                    onChange={(value) => this.onChange('investmentTypeId', value)}
                                    value={this.state.investment.investmentTypeId}
                                /> 
                            </Col>
                            
                        <Col md="3">
                            <TextInput 
                                label="Volume" 
                                placeholder="Volume" 
                                help="The volume you own" 
                                value={this.state.investment.volumeOwned}
                                onChange={(value) => this.onChange('volumeOwned', value)}
                            />   
                            </Col>
                            </Row>
                        <Row>
                            <Col md="3">
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                            <div style={buttonBarStyle}>
                                <Button 
                                    onClick={() => this.saveInvestment()}
                                    text="Save"
                                    variant='primary'
                                    icon={ButtonIcons.SAVE}
                                    style={buttonSpacingStyle}
                                    isBusy={this.state.isBusy}
                                />

                                <Button 
                                    onClick={() => this.handleCancel()}
                                    text="Cancel"
                                    variant='secondary'
                                    icon={ButtonIcons.CANCEL}
                                    style={buttonSpacingStyle}
                                    isBusy={this.state.isBusy}
                                />
                                <Button 
                                        onClick={() => this.handleDelete()}
                                        text="Delete"
                                        variant='danger'
                                        icon={ButtonIcons.DELETE}
                                        style={buttonSpacingStyle}
                                        isBusy={this.state.isBusy}
                                    />
                                
                            </div>

                            </Col>
                        </Row>
                    </div>
                   
                </AuthorisedArea>
            </React.Fragment>
        )
    }
}

InvestmentEditor.contextType = Consumer
export default withToastManager(InvestmentEditor)