import React from 'react'
import { Row, Col, Button  } from 'react-bootstrap'
import { Consumer } from  '../context'
import Moment from 'react-moment'
import { withToastManager, } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import ContextMenu from '../components/controls/contextMenu'

class ScheduledFutureList extends React.Component {
    constructor(props) {
        super(props) 

        this.state = {
            scheduleId: this.props.match.params.id,
            scheduledDisplayCount: 6,
            schedule: this.props.schedule,
            totalScheduled: this.props.schedule.length
        }
    }

    moreClick() {
        this.setState({
          scheduledDisplayCount: this.state.scheduledDisplayCount + 5
        })
      }

    payItemClick(scheduleNumber) {
        this.props.history.push(`/transaction/schedule/${this.state.scheduleId}/${scheduleNumber}`);
    }

    render() {

        return(
            <Row>
            <Col >
            <Row>
                <Col>
                    <strong>Upcoming Scheduled Transactions</strong>
                    <hr></hr>
                </Col>
            </Row>
            <Row>
                <Col>
                <Row className="tableViewHeaderStyle">
                    <Col  md={6} >Due Date</Col>
                    <Col  md={6} className="text-right">Amount</Col>
                </Row>
                {this.state.schedule
                    .slice(0, this.state.scheduledDisplayCount)
                    .map((item, index) => {
                        const formattedDate = <Moment format="Do MMM 'YY">{item.date}</Moment>

                        const options = [
                            { displayText: "Change Date",       callback: () => this.props.showDateEditorClick(item.paymentNumber) },
                            { displayText: "Change Amount",     callback: () => this.props.showAmountEditorClick(item.paymentNumber) },
                            { displayText: "Pay",               callback: () => this.payItemClick(item.paymentNumber) },
                            { displayText: "Skip Payment",      callback: () => this.props.skipPayment(item.paymentNumber) }
                        ]

                        if(item.variations && item.variations.length > 0) {
                            options.push({  })
                            item.variations.forEach(i => {

                                let description = ''

                                if(i.newDate) {
                                    description = 'date change to ' + i.newDate
                                } else {
                                    description = 'amount change to ' + i.newAmount
                                }

                                options.push(
                                    { displayText: `Remove ${description}`,   callback: () => this.props.removeVariation(i.id) }
                                )
                            })

                           
                        }

                        return (
                            <Row className={"tableViewRowStyle"} key={index}>                                    
                                <Col md={6}>
                                    <div>
                                        <ContextMenu items={options}/>
                                        <div>
                                            {formattedDate}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className='text-right'><strong>{Number.parseFloat(item.amount).toFixed(2)}</strong></Col>
                            </Row>
                        )
                    }
                )}
                </Col>
                </Row>
                <Row>
                    <Col md="12" className="text-right">
                        <small>(Showing {this.state.scheduledDisplayCount} of {this.props.totalScheduled} events)</small> <Button variant="link" onClick={() => this.moreClick()}>Show More..</Button> 
                    </Col>
                </Row>
            </Col>
            </Row>
        )
    }
}
ScheduledFutureList.contextType = Consumer;
export default withRouter(withToastManager(ScheduledFutureList))



