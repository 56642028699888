import React from 'react'
import { Row, Col, Button  } from 'react-bootstrap'
import { Consumer } from  '../context'
import Moment from 'react-moment'
import { withToastManager, } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ContextMenu from '../components/controls/contextMenu'

class SchedulePastList extends React.Component {
    constructor(props) {
        super(props) 

        this.state = {
            scheduleId: this.props.match.params.id,
            scheduledDisplayCount: 6,
            schedule: this.props.schedule,
            totalScheduled: this.props.schedule.length
        }
    }

    moreClick() {
        this.setState({
          scheduledDisplayCount: this.state.scheduledDisplayCount + 5
        })
      }

    render() {

        return(
            <Row>
            <Col >
            <Row>
                <Col>
                    <strong>Completed and Past Transactions</strong>
                    <hr/>
                </Col>
            </Row>
            <Row>
                <Col>
                <Row className="tableViewHeaderStyle">
                    <Col md={5} sm={5}>Scheduled</Col>
                    <Col md={3} sm={4}>State</Col>
                    <Col md={4} sm={3} className="text-right">Amount Paid</Col>
                </Row>
                {this.state.schedule
                    .slice(0, this.state.scheduledDisplayCount)
                    .map((item, index) => {
                        let formattedPaidDate = ''
                        if(item.relatedTransaction) {
                            formattedPaidDate = <Moment format="Do MMM 'YY">{item.relatedTransaction.transactionDate}</Moment>
                        } else {
                            formattedPaidDate = <Moment format="Do MMM 'YY">{item.date}</Moment>
                        }
                        
                        const options = [
                            { displayText: "Un-Skip",   callback: () => this.props.unSkipPayment(item.paymentNumber) },
                        ]

                        let statusLink = <div>Skipped</div>
                        if(item.relatedTransactionId) {
                            statusLink = <Link to={{pathname: `/transaction/${item.relatedTransactionId}`, state: {transactionId: item.relatedTransactionId}}}>Paid</Link>
                        }
                        return (
                            <Row className={"tableViewRowStyle"} key={index}>                                    
                                <Col md={5} sm={5}>
                                        {!item.relatedTransactionId &&
                                            <ContextMenu items={options}/>
                                        }
                                        <div>
                                            {formattedPaidDate}
                                        </div>
                                </Col>
                                <Col md={3} sm={4}>{statusLink}</Col>
                                <Col md={4} sm={3} className="d-md-block text-right">{item.relatedTransactionId && Number.parseFloat(item.relatedTransaction.amount).toFixed(2) || Number.parseFloat(item.amount).toFixed(2)}</Col>
                            </Row>
                        )
                    }
                )}
                </Col>
                </Row>
                <Row>
                    <Col md="12" className="text-right">
                        <small>(Showing {this.state.scheduledDisplayCount} of {this.props.totalScheduled} events)</small> <Button variant="link" onClick={() => this.moreClick()}>Show More..</Button> 
                    </Col>
                </Row>
            </Col>
            </Row>
        )
    }
}
SchedulePastList.contextType = Consumer;
export default withRouter(withToastManager(SchedulePastList))



