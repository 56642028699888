import React from 'react'
import Loader from 'react-loader-spinner'

class LoaderDisplay extends React.Component {

    render() {

        const divStyle = {
            textAlign: "center"
        }
        
        return (
            <div style={divStyle}>
                <Loader 
                    type="ThreeDots" 
                    color="#0078c8" 
                    height="75" 
                    width="75" />
            </div>
        )
    }
}

export default LoaderDisplay