import React from 'react'
import { Col, Row, Badge } from 'react-bootstrap'
import ScheduleCalendarCellEvent from './scheduleCalendarCellEvent'

class SchedueCalendarCell extends React.Component {

    render() {

        const eventStyle = {
            paddingTop: "10px",
        }
        return(

            <React.Fragment>
                <div>
                    <Row>
                        <Col md="12" className="text-right">
                        <h6><Badge pill variant={this.props.isToday ? 'danger' : 'primary'}>{this.props.dayNumber}</Badge></h6>
                        </Col>
                    </Row>
                    
                    {this.props.events && this.props.events.map((event,index) => {
                        if(this.props.showSkipped===false && event.isSkipped===true )
                            return null;
                        
                        if(event.paidDate)
                            return null;
                        return(
                        <Row key={index}>
                            <Col md="12" style={eventStyle}>
                                <ScheduleCalendarCellEvent event={event} />
                            </Col>
                        </Row>
                        )
                    })}
                    </div>
            </React.Fragment>
        )
    }
}

export default SchedueCalendarCell
