import React from 'react'
import AuthorisedArea from '../../Helpers/AuthorisedArea'
import { Row, Col } from 'react-bootstrap'
import SelectInput from '../controls/selectInput'
import BarChart from '../charts/barChart'
import { Consumer } from '../../context'
import LoaderDisplay from '../controls/loaderDisplay'
import moment from 'moment';

class BudgetTrend extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedBudgetTypeId: 1,
            selectedBudgetId: null,
            budgets: [],
            chartData: [],
            isLoading: true
                
          };
    }

    
    componentDidMount() {

        this.context.getBudgets()
            .then((data) => {
                const budgetList = data.sort(function(a, b) {
                    const textA = a.name.toUpperCase();
                    const textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
                .map(item => ({ id: item.id, value: item.name }))
                this.setState({
                    budgets: budgetList
                })
                
            })

            this.getBudgetReport()
        
    }

    getBudgetReport() {

        this.setState({
            isLoading: true
        })
        
        // A set of colours to use. Note, if there are more than 10 budgets, this will break! Need a method to loop.
        const backgroundColours = ['0, 135, 108', '89, 157, 113', '141, 179, 124', '189, 200, 142', '235, 221, 168', '230, 187, 127', '227, 150, 97', '222, 109, 82', '212, 61, 81']
  
        this.context.getBudgetReport(this.state.selectedBudgetTypeId, this.state.selectedBudgetId)
            .then((data) => {
                const chartData = {
                    labels:  data[0].instances.map((item) => { return `${moment(new Date(item.startDate)).format("Do MMM")} to ${moment(new Date(item.endDate)).format("Do MMM")}` }), // ['11.Jul', '12.Jun', '13.Jun', '14.Jun', '15.Jun', '16.Jun', '17.Jun', '18.Jun', '19.Jun', '20.Jun', '21.Jun','22.Jun', '23.Jun', '24.Jun', '25.Jun'],
                    datasets: data.map((budget, index) => { 
                        return {
                            label: budget.name,
                            fill: true,
                            stack: index,
                            lineTension: 0.2,
                            backgroundColor: `rgba(${backgroundColours[index]}, 0.3)`,
                            borderColor: `rgba(${backgroundColours[index]}, 0.7)`,
                            borderWidth: 1,
                            hoverBackgroundColor: `rgba(${backgroundColours[index]}, 1)`,
                            hoverBorderColor: `rgba(${backgroundColours[index]}, 0.7)`,
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            data: budget.instances
                            .map((item) => { return { amount: item.percentageUsed, label: `${moment(new Date(item.startDate)).format("Do MMM")} to ${moment(new Date(item.endDate)).format("Do MMM")}` }})
                        }
                    })
                };

                this.setState({
                    chartData,
                    isLoading: false
                })
            })
    }

    handleTypeChange(value) {
        this.setState({ selectedBudgetTypeId: value }, () => this.getBudgetReport())
    }

    handleBudgetChange(value) {
        this.setState({ selectedBudgetId: value }, () => this.getBudgetReport())
    }

    render() {

        const BudgetTypes = [
            {id: 1, value: 'Weekly'},
            {id: 2, value: 'Monthly'}
        ]

        const chartHolder = {
            height: "50vh",
            width: "100%"
          }


        return(
            <AuthorisedArea showError>
                <div className="borderBoxStyle">
                    <Row>
                        <Col>
                            <h4>Budget Trend Report</h4>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <SelectInput label="Budget Type" value={this.state.selectedBudgetTypeId} options={BudgetTypes} onChange={(value) => this.handleTypeChange(value)} />
                        </Col>
                        <Col md="4">
                            <SelectInput label="Budget" value={this.state.selectedBudgetId} options={this.state.budgets} onChange={(value) => this.handleBudgetChange(value)} />
                        </Col>
                    </Row>
                </div>
                <div className="borderBoxStyle top-buffer">
                    <div style={chartHolder}>
                        {this.state.isLoading && 
                            <LoaderDisplay />
                        }
                        {!this.state.isLoading && 
                            <BarChart data={this.state.chartData} />
                        }
                    </div>
                </div>

            </AuthorisedArea>
        )
    }
}

BudgetTrend.contextType = Consumer
export default BudgetTrend