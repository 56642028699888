import fetchData from '../Helpers/FetchData';
import Server from '../Constants'
import { HandleResponse } from './ResponseHandler' 

//const isReponseCodeOK = num => num.toString()[0] === '2';
const loginURL = `${Server.ApiURL}/token`;

class AuthenticationDataAccessor {
  
    static authenticateCredentials(userName, password) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: loginURL,
                method: 'POST',
                data: { username: userName, password },
            };

            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                        })
                })
            .catch((err) => {
                console.log('Error caught!', err);
                reject(err);
            });
        });
    }

    static sendPasswordResetCode(email) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/recover`,
                method: 'POST',
                data: { email: email }
            };

            fetchData(request)
            .then((response) => {
                HandleResponse(response)
                    .then((result) => {
                        resolve(result);
                    })
            })
            .catch((err) => {
                console.log('Error caught!', err);
                reject(err);
            });
        })
    }

    static validateEmailAddress(validationId) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/validateEmail/${validationId}`,
                method: 'GET'
            };
            
            fetchData(request)            
            .then((response) => {  
                HandleResponse(response)
                    .then((result) => {
                        resolve(result)
                    })
            })
        .catch((err) => {
            console.log('Error caught!', err);
            reject(err);
        });

        })
    }
}

export default AuthenticationDataAccessor;
