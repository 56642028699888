import React from "react";
import { Consumer } from '../../context'

class UserInfo extends React.Component {
    render() {

        return(
            <React.Fragment>
                <Consumer.Consumer>
                    {value => { 
                        const isAuthenticated = value.user.authStatus
                        if(isAuthenticated) return <div className="infoText">Hello {value.user.displayName}</div>
                    }}
                </Consumer.Consumer>
            </React.Fragment>
        )
    }
}
UserInfo.contextType = Consumer
export default UserInfo;