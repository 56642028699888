import React from 'react'
import AuthorisedArea from '../../Helpers/AuthorisedArea'
import { Row, Col } from 'react-bootstrap'
import BarChart from '../charts/barChart'
import { Consumer } from '../../context'
import LoaderDisplay from '../controls/loaderDisplay'
import SelectInput from '../controls/selectInput'
import CategoryEditor from '../subcomponents/categoryEditor'


class CategoriesOverTime extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            reportRange: 1,
            fromDate: null,
            toDate: null,
            selectedCategoryId: null,
            granularity: 1,
            includeCurrent: 1,
            chartData: [],
            isLoading: true
          };
    }

    getReport() {

        this.setState({
            isLoading: true
        })
        
        let history = 730

        switch(this.state.reportRange) {
            case 1: history = 30; break;
            case 2: history = 60; break;
            case 3: history = 90; break;
            case 4: history = 180; break;
            case 5: history = 365; break;
            case 6: history = 730; break;
            case 7: history = 1095; break;
            default: history = 730;
        }

        let dateRange = this.getHistoricDateRange(history)
        
        this.context.getCategoriesOverTimeReport(
                this.state.selectedCategoryId, 
                dateRange.startDate, 
                dateRange.endDate,
                this.state.granularity,
                this.state.includeCurrent
            )
            .then((data) => {
                const chartData = {
                    datasets: [
                        {
                            data: data
                                .map((item) => { return { amount: item.amount, label: item.displayLabel }})
                        }]
                };

                this.setState({
                    chartData,
                    isLoading: false
                })
            })
    }

    // Accepts a number of days, and returns a date range from now, to the number of days ago.
     getHistoricDateRange(numberOfDays) {
        // Get today's date
        const today = new Date();
    
        // Calculate the start date by subtracting the number of days from today
        let startDate = new Date();
        startDate.setDate(today.getDate() - numberOfDays);
    
        if(this.granular === 1) // Weekly
        {
            startDate = this.getMonday(startDate);
        }

        if(this.granular === 2) {
            startDate = this.getFirstDayOfMonth(startDate)
        }

        // Format the dates as strings (optional)
        const formattedToday = today.toISOString().split('T')[0];
        const formattedStartDate = startDate.toISOString().split('T')[0];
    
        // Return the date range
        return {
            startDate: formattedStartDate,
            endDate: formattedToday
        };
    }

    // For weekly dates, we want to start from the beginning of the week. So we find the Monday.
    getMonday(date) {
        const day = date.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, etc.)
        const diff = (day === 0 ? -6 : 1) - day; // Calculate the difference to Monday
        const monday = new Date(date); // Create a new date object
        monday.setDate(date.getDate() + diff); // Set the date to the calculated Monday
        return monday;
    }

    getFirstDayOfMonth(date) {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return firstDay;
    }
    

    handleChange(fieldName, value) {
        this.setState({ [fieldName]: value }, () => 
            {
                if (this.state.selectedCategoryId)
                    this.getReport()
            })
    }

    render() {

        const chartHolder = {
            height: "50vh",
            width: "100%"
          }

          const periods = [
            {id: 1, value: "Past month"},
            {id: 2, value: "Past 2 months"},
            {id: 3, value: "Past 3 Months"},
            {id: 4, value: "Past 6 Months"},
            {id: 5, value: "Past 1 Year"},
            {id: 6, value: "Past 2 Year"},
            {id: 7, value: "Past 3 Year"}
        ]

        const granular = [
            {id: 1, value: "Weekly"},
            {id: 2, value: "Monthly"}
        ]

        const yesno = [
            {id: 1, value: "Yes"},
            {id: 0, value: "No"}
        ]


        return(
            <AuthorisedArea showError>
                <div className="borderBoxStyle">
                    <Row>
                        <Col>
                            <h4>Categories Over Time</h4>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <React.Fragment>
                        <Col md="2">
                                <SelectInput label="Report Period" value={this.state.reportRange} onChange={(value) => this.handleChange('reportRange', value)} options={periods}  />
                            </Col>
                            <Col md="2">
                                <SelectInput label="Granularity" value={this.state.granularity} onChange={(value) => this.handleChange('granularity', value)} options={granular}  />
                            </Col>
                            <Col md="2">
                                <SelectInput label="Include Current" value={this.state.includeCurrent} onChange={(value) => this.handleChange('includeCurrent', value)} options={yesno}  />
                            </Col>
                            <Col md="6">
                                <CategoryEditor
                                prefix="Report"
                                onChange={(value) => this.handleChange('selectedCategoryId', value)}
                                value={this.state.selectedCategoryId} />
                            </Col>
                        </React.Fragment>
                    </Row>
                </div>
                <div className="borderBoxStyle top-buffer">
                    <div style={chartHolder}>
                        {this.state.isLoading && 
                            <LoaderDisplay />
                        }
                        {!this.state.isLoading && 
                            <BarChart data={this.state.chartData} />
                        }
                    </div>
                </div>

            </AuthorisedArea>
        )
    }
}

CategoriesOverTime.contextType = Consumer
export default CategoriesOverTime