import React from 'react'

class BooleanDisplay extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            value: this.props.value
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if(newProps.value !== this.state.value) {
            this.setState({ value: newProps.value})
        }
    }

    render() {

        const trueDisplay = <i className="fas fa-check incomeColor"></i>
        const falseDisplay = <i className="fas fa-times expenseColor"></i>

        return(
            <React.Fragment>
                {this.state.value === true && trueDisplay}
                {!this.state.value && falseDisplay} 
            </React.Fragment>
            
        )
    }

}

export default BooleanDisplay