import React from 'react';
import AccountBox from './accountBox';
import BudgetBox from './budgetBox';
import { Consumer } from '../context';
import { AccountTypes, ButtonIcons } from '../Constants';
import QuickTransaction from '../components/quicktransaction';
import { Accordion, Button } from 'react-bootstrap';

class LeftInfoBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedKeys: {
        '1': true, // Set the "Accounts" section to be expanded by default
        '3': true, // Set the "Budgets" section to be expanded by default
      }
    };
  }

  componentDidMount() {
    this.context.getAllAccounts(false);
    this.context.getInvestments(true);
  }

  handleToggle = (key) => {
    this.setState((prevState) => ({
      expandedKeys: {
        ...prevState.expandedKeys,
        [key]: !prevState.expandedKeys[key]
      }
    }));
  }

  render() {
    const { expandedKeys } = this.state;

    return (
      <React.Fragment>
        <Consumer.Consumer>
          {value => { 
            var bankAccounts = value.cacheStore.allAccountsList.filter((x) => x.isFavourite && x.accountType === AccountTypes.BANKACCOUNT)
              .sort((a, b) => {
                const textA = a.name.toUpperCase();
                const textB = b.name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              });

            var accountPayload = bankAccounts.map((item) => {
              return {
                name: item.name,
                balance: item.balance,
                id: item.id,
                type: 'acc'
              }
            })

            if(value.cacheStore.investmentBalance > 0) {
              accountPayload.push({
                name: 'Investments',
                balance: value.cacheStore.investmentBalance,
                type: 'inv'
              })  
            }

            return (
              <React.Fragment>
                <div className="borderBoxStyle">
                  <Accordion defaultActiveKey="1">
                    <div className="headerBoxStyle">
                      <Accordion.Toggle 
                        as={Button} 
                        variant="link" 
                        eventKey="1"
                        onClick={() => this.handleToggle('1')}
                      >
                        {expandedKeys['1'] ? ButtonIcons.COLLAPS : ButtonIcons.EXPAND}
                      </Accordion.Toggle> 
                      Accounts 
                    </div>
                    <Accordion.Collapse eventKey="1" in={expandedKeys['1']}>
                      <React.Fragment>
                        {accountPayload && accountPayload.map((account, i) => {
                          return (
                            <AccountBox key={i} name={account.name} balance={account.balance} accountId={account.id} type={account.type} />
                          );
                        })}
                        {(!bankAccounts || bankAccounts.length === 0) && (
                          <React.Fragment>
                            No account available
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    </Accordion.Collapse>
                  </Accordion>
                </div>
                
                <div className="top-buffer">
                  <div className="borderBoxStyle">
                    <Accordion>
                      <div className="headerBoxStyle">
                        <Accordion.Toggle 
                          as={Button} 
                          variant="link" 
                          eventKey="2"
                          onClick={() => this.handleToggle('2')}
                        >
                          {expandedKeys['2'] ? ButtonIcons.COLLAPS : ButtonIcons.EXPAND}
                        </Accordion.Toggle> 
                        Quick Entry 
                      </div>
                      <Accordion.Collapse eventKey="2" in={expandedKeys['2']}>
                        <QuickTransaction />
                      </Accordion.Collapse>
                    </Accordion>
                  </div>
                </div>

                <div className="top-buffer">
                  <div className="borderBoxStyle">
                    <Accordion defaultActiveKey="3">
                      <div className="headerBoxStyle">
                        <Accordion.Toggle 
                          as={Button} 
                          variant="link" 
                          eventKey="3"
                          onClick={() => this.handleToggle('3')}
                        >
                          {expandedKeys['3'] ? ButtonIcons.COLLAPS : ButtonIcons.EXPAND}
                        </Accordion.Toggle> 
                        Budgets 
                      </div>
                      <Accordion.Collapse eventKey="3" in={expandedKeys['3']}>
                        <BudgetBox />
                      </Accordion.Collapse>
                    </Accordion>
                  </div>
                </div>
              </React.Fragment>
            );
          }}
        </Consumer.Consumer>
      </React.Fragment>
    );
  }
}

LeftInfoBar.contextType = Consumer;
export default LeftInfoBar;
