import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import { Consumer } from '../context'
import { AccountTypes } from '../Constants'
import TransactionsRow from './transactionsRow'
import SelectInput from '../components/controls/selectInput'
import TextInput from '../components/controls/textInput'
import LoaderDisplay from './controls/loaderDisplay'
import ReactGA from 'react-ga';
import ScrollableAnchor from 'react-scrollable-anchor';
import { configureAnchors, goToAnchor } from 'react-scrollable-anchor'


class Account extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            searchText: null,
            transactions: [],
            accounts: [],
            selectedAccountId: this.props.match.params.id,
            selectedDuration: 30
        }

        this.onChange = this.onChange.bind(this)
        this.search = this.search.bind(this)
    }

    componentDidUpdate(prevProps) {

        if (this.props.match.params.id !== prevProps.match.params.id) {
          this.setState({ selectedAccountId: this.props.match.params.id }, () => this.getTransactions())
        }
      }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
        configureAnchors({offset: -60, scrollDuration: 50})
        goToAnchor('mainSection', false)
        this.getTransactions()

        this.context.getAllAccounts(false)
        .then((result) => {
            const filteredAccounts = result
            .filter(item => item.accountType === AccountTypes.BANKACCOUNT)
            .sort(function(a, b) {
                const textA = a.name.toUpperCase();
                const textB = b.name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 
                : 0;
            })
            .map(item => ({ id: item.id, value: item.name }))

            this.setState({
                accounts: filteredAccounts,
                isLoading: false
            })
        })

    }

    getTransactions() {

        this.setState( {
            isLoading: true
        })

        const request = {
            accountId:  this.state.selectedAccountId,
            historicDaysFromNow: this.state.selectedDuration
        }

        this.context.getTransactions(request)
        .then((result) => {
            const t = result.map((item) => ({
                ...item
            }))
            this.setState({
                transactions: t,
                isLoading: false
            })
        })
    }

    search(text) {
        this.setState({ searchText: text })
    }

    onDurationChange(value) {
        this.setState( {
            selectedDuration: value
        }, () => this.getTransactions())
    }

    onChange(value) {
        this.props.history.push(`/transactions/${value}`);
        this.setState({
            selectedAccountId: value,
            isLoading: true,
        }, () => this.getTransactions())

        
    }
    
    onSelect(transactionId) {
        this.props.history.push(`/transaction/${transactionId}`);
    }

    
    calculatePayee(thisAccountId, transaction) {
        return thisAccountId === transaction.debitAccountId ? transaction.creditAccountName : transaction.debitAccountName
     }

    render() {
        const durations = [
            {id: 30, value: "Past 30 days"},
            {id: 60, value: "Past 60 days"},
            {id: 90, value: "Past 90 days"},
            {id: 180, value: "Last 180 days"},
        ]

     
        let lastDate = "";

        const itemStyle = {
            cursor: "pointer"
        }


        return (
            <React.Fragment>
                <AuthorisedArea showError>
                <ScrollableAnchor id={'mainSection'}>
                    <div className="borderBoxStyle">
                        <Row>
                            <Col md="12">
                                <Row >
                                    <Col md="5" >
                                        <SelectInput 
                                            label="Bank Account" 
                                            placeholder="Select an Account" 
                                            help="Select an account to view" 
                                            value={this.state.selectedAccountId}
                                            options={this.state.accounts} 
                                            onChange={(value) => this.onChange(value)}
                                        />   
                                    </Col>
                                    <Col md="3" >
                                        <SelectInput 
                                            label="History" 
                                            help="Select a Duration" 
                                            value={this.state.selectedDuration}
                                            options={durations} 
                                            onChange={(value) => this.onDurationChange(value)}
                                        />   
                                    </Col>
                                    <Col md="4">
                                        <TextInput 
                                            label="Search"
                                            placeholder="Enter a search"
                                            help="Enter teach to search for"
                                            value={this.state.searchText}
                                            onChange={(value) => this.search(value)}    
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    </ScrollableAnchor>
                    <div className="borderBoxStyle top-buffer ">
                <Row >

                    <Col md="12">
                        <Row className="tableViewHeaderStyle">
                            <Col md="4">Transaction</Col>
                            <Col md="4">Description</Col>
                            <Col  className="text-right" md="2">Amount</Col>
                            <Col  className="text-right" md="2">Balance</Col>
                        </Row>

                        {this.state.isLoading && <LoaderDisplay />}

                        {this.state.isLoading === false && 
                            this.state.transactions
                            .filter((filtered) => 
                                (!this.state.searchText || 
                                (filtered.amount.toString().includes(this.state.searchText) || 
                                this.calculatePayee(this.state.selectedAccountId, filtered).includes(this.state.searchText))))
                            .map((item, index) => {
                                const formattedDate = <Moment format="dddd, Do of MMMM">{item.transactionDate}</Moment>;
                                const showHeader = (lastDate !== item.transactionDate);
                                lastDate = item.transactionDate

                                return (
                                    <React.Fragment key={index}>
                                    {showHeader && 
                                        <Row className="tableViewGroupBarStyle">
                                            <Col md="12">
                                                {formattedDate}
                                            </Col>
                                        </Row>
                                    }

                                <Row key={index} onClick={() => this.onSelect(item.id)} className="tableViewRowStyle" style={itemStyle}>
                                    <TransactionsRow thisAccountId={this.state.selectedAccountId} transaction={item}/>
                                </Row>
                                </React.Fragment>
                                )
                            }
                        )}
                    </Col>
                </Row>
                    </div>
                </AuthorisedArea>
            </React.Fragment>

        )
    }

}

Account.contextType = Consumer
export default withRouter(Account)