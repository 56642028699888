import React from 'react';
import UserInfo from './userInfo';
import { Container } from 'react-bootstrap'

class InfoBar extends React.Component {
    render() {
        return (        
            <React.Fragment>
                <Container> 
                    <div className="infobar">
                        <UserInfo />
                    </div>
                </Container>
            </React.Fragment>
        )
    }
}

export default InfoBar;