import React from 'react'
import { Form } from 'react-bootstrap'
import ControlLabel from './controlLabel'

class RadioInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedValue: this.props.value
        }
    }

    handleChange(value) {
        this.setState({
            selectedValue: value
        }, () => this.props.onChange(value))
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.state) {
           this.setState({
            selectedValue: nextProps.value,
           })
        }
   }


    render() {



        return(
            <React.Fragment>
                 <div>
                    <ControlLabel text={this.props.label} />
                </div>
                {this.props.options.map((option, index) => {
                    return <React.Fragment>
                        <Form.Check 
                            custom
                            type='radio'
                            id={`custom-radio-${this.props.label}-${index}`}
                            label={option.label}
                            value={option.value}
                            checked={this.state.selectedValue === option.value}
                            onClick={() => { this.handleChange(option.value) }}
                        
                        />  
                    </React.Fragment>  
                }
                )
            }
                
            </React.Fragment>
        )
    }
}

export default RadioInput