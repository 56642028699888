import React from 'react'
import {Row, Col} from 'react-bootstrap'
import Button from '../components/controls/button'
import { ButtonIcons }  from '../Constants'
import { withRouter } from 'react-router-dom';
import { withToastManager, } from 'react-toast-notifications';
import { Consumer } from  '../context'
import Moment from 'react-moment'




class Import extends React.Component {

    static contextType = Consumer;

    constructor(props) {
        super(props)

        this.state = {
            isBusy: false,
            selectedFile: null,
            data: []
        }
    }

    onChangeFileHandler(event) {
        event.preventDefault();
         this.setState({
           selectedFile: event.target.files[0]
         })
    }

    match() {
      
    }

    handleUpload() {

        const formData = new FormData() 
        formData.append('formFile', this.state.selectedFile)
        formData.append('accountId', '6598d057-e617-437a-af81-aafacdff43cc')
    
        this.context.matchTransactions('6598d057-e617-437a-af81-aafacdff43cc', formData)
          .then((result) => {
            const { toastManager } = this.props;
    
            if(result) {
              toastManager.add(result.message, {
                appearance: 'success',
                autoDismiss: true,
                pauseOnHover: false,
              });
            }  
            this.setState({
              uploadVisible: false,
              data: result.data
            })
          })
      }

    render() {

        const nowrap = {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }

        const buttonSpacingStyle = {
            padding: "4px",
            float: "left"
          }
      
        return (
            <React.Fragment>
                <h2>Import</h2>

                <Row>
                    <Col md="12">
                        <input type="file" name="file" onChange={(e) => this.onChangeFileHandler(e)}/>
                    </Col>
                </Row>
                <Row>

          <Button  
            onClick={() => this.handleUpload()} 
            text="Upload"
            variant="outline-primary" 
            isBusy={this.state.isBusy}
            style={buttonSpacingStyle}
            icon={ButtonIcons.OK}
          /> 
          </Row>


          <hr />
          <Row className="tableViewHeaderStyle">
              <Col md="2">Date</Col>
              <Col md="1">Type</Col>
              <Col md="2">Third Party</Col>
              <Col className="text-right" md="1">Amount</Col>
              <Col md="4" style={nowrap}>Raw Data</Col>
              <Col md="2"></Col>
          </Row>

          {this.state.data.map((data) => {
            
            const imported = {
              transactionDate : null,
              transactionType: null,
              thirdParty: null,
              amount: null,
            }

            const matched = {
              transactionDate : null,
              transactionType: null,
              thirdParty: null,
              amount: null,
              description: 'No match'
            }

            if(data.importedTransaction) {
              imported.transactionDate = <Moment format="Do MMM, 'YY">{data.importedTransaction.transactionDate}</Moment> 
              imported.transactionType = data.importedTransaction.transactionType
              imported.thirdParty = data.importedTransaction.thirdParty
              imported.amount = data.importedTransaction.amount.toFixed(2)
            }

            if(data.matchedTransaction) {
              matched.transactionDate = <Moment format="Do MMM, 'YY">{data.matchedTransaction.transactionDate}</Moment>
              matched.transactionType = data.matchedTransaction.transactionType
              matched.thirdParty = data.matchedTransaction.creditAccountName
              matched.amount = data.matchedTransaction.amount.toFixed(2)
              matched.description = data.matchedTransaction.description
            }


            return (
              <React.Fragment>
                <Row  className="tableViewRowStyle">
                  <Col md="2">{imported.transactionDate}</Col>
                  <Col md="1">{imported.transactionType}</Col>
                  <Col md="2" style={nowrap}>{imported.thirdParty}</Col>
                  <Col md="1" className="text-right">{imported.amount}</Col>
                  <Col md="3" style={nowrap}>{data.rawData}</Col>
                  <Col md="3"></Col>

                  <Col md="2">{matched.transactionDate}</Col>
                  <Col md="1">{matched.transactionType}</Col>
                  <Col md="2"style={nowrap}>{matched.thirdParty}</Col>
                  <Col md="1" className="text-right">{matched.amount}</Col>
                  <Col md="3" style={nowrap}>{matched.description}</Col>
                  <Col md="3">
                    <Button
                       onClick={() => this.Match()}
                       text="Confirm Match"
                       variant="primary"
                       icon={ButtonIcons.LINK}
                     />
                  </Col>
                </Row>
              </React.Fragment> 
            )
              }
            )
          }
         </React.Fragment> 
        )
    }
}

export default withRouter(withToastManager(Import))