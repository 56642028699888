import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/header/header';
import { ToastProvider } from 'react-toast-notifications';
import { Provider } from './context';
import { Consumer } from './context';
import MainLayout from './components/mainLayout'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-156514544-1');

class App extends React.Component {

  componentDidMount() {
      ReactGA.pageview(window.location.pathname);
  }

  render() {

    return (
      <div className="App">
        <ToastProvider autoDismissTimeout={8000} placement="bottom-right"> 
            <Provider>
              <BrowserRouter>
                <Header />
                <Consumer.Consumer>
                  {value => { 
                    return (
                      <MainLayout />
                      )
                    }}
                  </Consumer.Consumer>
                </BrowserRouter>
            </Provider>
          </ToastProvider>
      </div>
    );
  }
}

App.contextType = Consumer;
export default App;
