import React from 'react'
import { Row, Col } from 'react-bootstrap'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import LoaderDisplay from '../components/controls/loaderDisplay'
import { Consumer } from '../context'
import TextInput from '../components/controls/textInput'
import { ButtonIcons } from '../Constants'
import Button from './controls/button'

import { withRouter } from 'react-router-dom';
import SchedulesItem from './schedulesItem'

import ReactGA from 'react-ga';

class Schedules extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            scheduleList: [],
            filteredList: [],
            searchText: null,
        }

        this.View = this.View.bind(this)
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
        const filter = {
            LimitResultCount: 1000
        }

        this.context.getUpcoming(filter)
            .then((result) => {
                this.setState({
                    scheduleList: result,
                    filteredList: result,
                    isLoading: false
                })
            })

    }

    View(scheduleId) {
        this.props.history.push(`/schedule/${scheduleId}`);
    }

    Create() {
        this.props.history.push(`/schedule/`);
    }

    handleChange(fieldName, value) {
        const filtered = this.state.scheduleList
            .filter(item => 
                    !value ||
                    (item.debitAccount.toLowerCase().includes(value.toLowerCase()) || item.creditAccount.toLowerCase().includes(value.toLowerCase()))
                )
        this.setState({ [fieldName]: value, filteredList: filtered  })
        
    }
     
    render() {

        let prevDate = null
        return (

            <React.Fragment>
            <AuthorisedArea showError>
                <div className="borderBoxStyle">
                    <Row>
                        <Col md="6"><TextInput
                            value={this.state.searchText}
                            onChange={(value) => this.handleChange('searchText', value)}
                            label="Search"
                            help="Search for a Schedule"
                            placeholder="Payee"

                        />
                        </Col>
                        <Col md="6" className="text-right">
                            <Button 
                                icon={ButtonIcons.CREATE} 
                                onClick={() => this.Create()}
                                text="Create New Schedule"
                            />
                        </Col> 
                    </Row>
                </div>
                {this.state.isLoading && 
                    <LoaderDisplay />
                } 
                {!this.state.isLoading && 
                <div className=" top-buffer borderBoxStyle">
                    <Row>
                        <Col md="12" >
                            <Row className="tableViewHeaderStyle">  
                                <Col md="4">Transaction</Col>
                                <Col md="4">Description</Col>
                                <Col md="4" className="text-right">Amount</Col>
                            </Row>
                            {this.state.filteredList.length === 0 &&
                                <div>No schedules available</div>
                            }
                            {this.state.filteredList
                                .sort(function(a, b) {
                                    return (a.dueDays < b.dueDays) ? -1 : (a.dueDays > b.dueDays) ? 1 : 0;
                                })
                                .map((item, index) => 
                                {
                                    const showDateHeader = prevDate !== item.date
                                    prevDate = item.date
                                    return <SchedulesItem 
                                        showDate={showDateHeader} 
                                        key={index} 
                                        data={item} 
                                        onClick={this.View} 
                                    />
                                }
                            )}
                        </Col>
                    </Row>
                    </div>
                }
                
                </AuthorisedArea>
            </React.Fragment>
        )
    }


}

Schedules.contextType = Consumer
export default withRouter(Schedules)