import React from 'react'
import RouteManager from './routeManager'
import LeftInfoBar from './leftInfoBar';
import { Container, Row, Col } from 'react-bootstrap'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import Auth from '../Authentication'
import Home from './home'
import { Consumer } from '../context'

class MainLayout extends React.Component {

  constructor(props) {
    super(props)
    this.checkChanges = this.checkChanges.bind(this);
  }

  componentDidMount() {
    //this.timer = setInterval(()=> this.checkChanges(), 10000);

  }

  checkChanges() {
    this.context.pollForChanges()
  }


  render() {

    let isAuthenticated = Auth.isAuthenticated() 

    return (
      <React.Fragment>
          {isAuthenticated === false && 
            <Home />
          }
        <Container>
          {isAuthenticated && 
            <AuthorisedArea>
              <Row >
                <Col md="2">
                  <LeftInfoBar />
                </Col>
                <Col md="10">
                    <RouteManager />
                </Col>
              </Row>
            </AuthorisedArea>
          }
        </Container>
        </React.Fragment>
    )
  }
}
MainLayout.contextType = Consumer
export default MainLayout