import moment from 'moment';

const minDateValue = -8640000000000000;
const minDate = new Date(minDateValue);

const sortByDate = (itemDateAParam, itemDateBParam) => {
  const itemDateA = itemDateAParam || minDate;
  const itemDateB = itemDateBParam || minDate;
  const dateA = moment.isMoment(itemDateA) ? itemDateA : moment(new Date(itemDateA));
  const dateB = moment.isMoment(itemDateB) ? itemDateB : moment(new Date(itemDateB));
  return dateA.diff(dateB);
};

export const sortMethod = (itemA, itemB) => {
 
  var nameA = itemA.toUpperCase(); // ignore upper and lowercase
  var nameB = itemB.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
}

export const sortByDateDesc = (itemDateA, itemDateB) => sortByDate(itemDateB, itemDateA);
export const sortByDateAsc = (itemDateA, itemDateB) => sortByDate(itemDateA, itemDateB);

export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
      const negativeSign = amount < 0 ? "-" : "";
  
      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;
  
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  };


export function getDayNameFromDate(date) {
  var d = new Date(date);
  var weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";
  return weekday[d.getDay()];
}

export function getMonthNameFromDate(date) {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  return monthNames[date.getMonth()];
}

export function getDayNumberFromDate(date) {
  const days = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th",
                "16th", "17th", "18th", "19th", "20th", "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th", "29th", "30th", "31st"]
  return days[date.getDate() - 1];

}

export function isNumberic(value, allowDecimal) {
  const numeric = Number(value)
  if(isNaN(numeric) === false) {
    if(allowDecimal===false) {
      if(value % 1 !== 0) {
        return false
      }
      return true
    }
    return true
  }
  return false
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
    return v.toString(16);
  });
}

// Rounds a number to the nearest 0, 00, 000 etc. Eg, 16213 will be 17000. -23, would be -100.
export function roundUpToNearestPowerOfTen(value) {
  const factor = 100;
  if (value >= 0) {
    return Math.ceil(value / factor) * factor;
  } else {
    return Math.floor(value / factor) * factor;
  }
}

