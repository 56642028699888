import React from 'react';
import { withRouter } from 'react-router-dom';
import { withToastManager, } from 'react-toast-notifications';
import { Form, Row, Col } from 'react-bootstrap'
import { Consumer } from '../context';
import TextInput from './controls/textInput'
import Button from './controls/button'
import AuthenticationDataAccessor from '../api/AuthenticationDataAccessor'
import Auth from '../Authentication';
import { ButtonIcons } from '../Constants'



class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        email: null,
        password: null,
        isBusy: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleChange(fieldName, newValue) {
    let user = this.state;
    user[fieldName] = newValue;
    this.setState({user});
  }

  handleLogin() {
    this.setState({ isBusy: true })
    const { toastManager } = this.props;

    AuthenticationDataAccessor.authenticateCredentials(this.state.email, this.state.password)
      .then((result) => {
        this.setState({ isBusy: false })
        if (result.ok) {
          Auth.login(this.state.email, result.data.token);
            this.context.changeAuthenticated(true);
            this.props.history.push("/");
            toastManager.add(result.message, {
                appearance: 'success',
                autoDismiss: true,
                pauseOnHover: false,
            });
            return;
            
        }
        toastManager.add(result.message, {
          appearance: 'error',
          autoDismiss: true,
          pauseOnHover: false,
        });
    })
  }

  render() {

    // const registerStyle = {
    //   display: "none",
    // }

    return (
      <React.Fragment >

<Row>
              <Col className="intro-message" lg="8" id="about-box">
                <h1>AccuFinance</h1>
                <h3>Simple home finance management</h3>
                <hr className="intro-divider" />
                <h5>info@accufinance.com</h5>
              </Col>
              <Col className="intro-message" lg="3">
                {/* <div>
                  <h3>Welcome to AccuFinance</h3>
                </div> */}
                <div> 
            
                  <Form>
                    <h2>Login</h2>
                    <Form.Group controlId="formLogin" >
                      <Row>
                        <Col>
                          <TextInput 
                            id="Email" 
                            placeholder="Your Email" 
                            type="email" 
                            value={this.state.email} 
                            onChange={(e) => this.handleChange('email', e)} 
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextInput 
                            id="Password" 
                            placeholder="Your Password" 
                            type="password" 
                            value={this.state.password} 
                            onChange={(e) => this.handleChange('password', e)} 
                            enterPressed={() => { if(this.state.password !== '') {this.handleLogin()}}} 
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center">
                          <Button 
                            variant="primary" 
                            onClick={() => this.handleLogin()} 
                            text='Login'
                            isBusy={this.state.isBusy}
                            icon={ButtonIcons.LOGIN}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form> 
                  </div>
                  </Col>
                  </Row>
                
      </React.Fragment>
    )
  }
}

Login.contextType = Consumer;
export default withRouter(withToastManager(Login));