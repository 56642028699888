import React from 'react'
import { Row, Col  } from 'react-bootstrap'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import TextInput from './controls/textInput'
import SelectInput from './controls/selectInput'
import CurrencyInput from './controls/currencyInput'
import DateInput from './controls/dateInput'
import Button from './controls/button'
import LoaderDisplay from './controls/loaderDisplay'
import { Consumer } from '../context'
import { AccountTypes, ButtonIcons } from '../Constants'
import { withToastManager, } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import moment from 'moment';
import { formatMoney, sortByDateDesc } from '../Helpers/Formatter'
import { confirmAlert } from 'react-confirm-alert'; 
import cloneDeep from 'lodash/cloneDeep';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


class Budget extends React.Component {
   
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isViewMode: true,
            budget :{
                id: this.props.match.params.id,
                name: null,
                allowanceAmount: null,
                startDate: null,
                endDate: null,
                budgetTypeId: null,
                primaryAccountId: null,
            },
            accountList: [],
            periods: [],
            transactions: [],
            transactionsLoading: true
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            const budget = this.state.budget
            budget.id = this.props.match.params.id 
            this.setState({ 
                budget  
            }, () => this.getBudget())
        }
      }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
        this.context.getAllAccounts(false)
            .then((data) => {
                const Accounts = data.filter((item) => item.accountType === AccountTypes.BANKACCOUNT)
                .sort(function(a, b) {
                    const textA = a.name.toUpperCase();
                    const textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
                .map(item => ({ id: item.id, value: item.name }))

                this.setState({
                    accountList: Accounts
                })
            })

        if(this.state.budget.id) {
            this.getBudget()
        } else {
            this.setState({
                isLoading: false
            })
        }

    }

    getBudget() {
        this.setState({ isLoading: true })
        
        this.context.getBudget(this.state.budget.id)
            .then((result) => {
                if(result.ok === false) {
                    const { toastManager } = this.props;
                    this.props.history.goBack();
                        toastManager.add(result.message, {
                            appearance: 'error',
                            autoDismiss: true,
                            pauseOnHover: false,
                        });
                        return; 
                }

                const payload = result.data

                const budget = {
                    name: payload.name,
                    allowanceAmount: payload.allowanceAmount,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    budgetTypeId: payload.budgetTypeId,
                    primaryAccountId: payload.primaryAccountId,
                    id: payload.id
                }

                this.setState({
                    isLoading: false,
                    budget,
                    originalBudget: cloneDeep(budget)
                })

                const schedules = payload.schedule.map((schedule) => {
                    return {
                        id: schedule.budgetNumber,
                        value: `${moment(new Date(schedule.fromDate)).format("Do MMMM YYYY")} to ${moment(new Date(schedule.toDate)).format("Do MMMM YYYY")}`
                    }
                })

                this.setState({
                    periods: schedules,
                    selectedPeriod: payload.currentBudgetNumber
                }, () => this.getTransactionsForBudget())


            })
        }

    getTransactionsForBudget() {
        this.context.getBudgetTransactions(this.state.budget.id, this.state.selectedPeriod)
            .then((result) => {
                if(result.ok) {
                    this.setState({
                        transactions: result.data,
                        transactionsLoading: false
                    })
                }
                else
                {
                    const { toastManager } = this.props;
                        toastManager.add(result.message, {
                            appearance: 'error',
                            autoDismiss: true,
                            pauseOnHover: false,
                        });
                }

                
            })
    }

    handleChange(property, value) {
        const budget = this.state.budget
        budget[property] = value

        this.setState({budget})
    }

    handlePeriodChange(budgetNumber) {
        this.setState({
            transactionsLoading: true,
            selectedPeriod: budgetNumber
        }, () => this.getTransactionsForBudget())
    }

    cancelClick() {
        this.setState({
            budget: cloneDeep(this.state.originalBudget),
            isViewMode: true
        })
    }

    onTransactionSelect(id) {
        this.props.history.push(`/transaction/${id}`);
    }

    deleteClick() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                      <h1>Delete Budget</h1>
                      <p>Confirm you want to delete this budget</p>
                      <Button onClick={onClose} text="Cancel" variant="info" />&nbsp;
                      <Button onClick={() =>{ this.delete(); onClose()} } text="Delete" varient="success" />
                  </div>
                );
            }
        });
    }


    delete() {
        const { toastManager } = this.props;
        this.context.deleteBudget(this.state.budget.id)
            .then((result) => {
                if(result.ok) {
                    this.props.history.goBack();
                        toastManager.add(result.message, {
                        appearance: 'success',
                        autoDismiss: true,
                    pauseOnHover: false,
                    });
                }
            else 
            {
                toastManager.add(result.message, {
                    appearance: 'error',
                    autoDismiss: true,
                    pauseOnHover: false,
                });
            }
            })
    }

    saveClick() {
        const { toastManager } = this.props;

        this.setState({ isBusy: true })

        const payload = {
            id: this.state.budget.id,
            name: this.state.budget.name,
            allowanceAmount: this.state.budget.allowanceAmount,
            startDate: this.state.budget.startDate,
            endDate: this.state.budget.endDate,
            budgetTypeId: this.state.budget.budgetTypeId,
            primaryAccountId: this.state.budget.primaryAccountId,
            startDayNumber: 1
        }

        this.context.saveBudget(payload)
            .then((result) => {
                if(result.ok) {
                    this.setState({
                        isViewMode: true
                    })
                    toastManager.add(result.message, {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
                } else {
                    this.setState({
                        isBusy: false
                    })

                    toastManager.add(result.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
                }
            })
    }


    render() {

        const buttonSpacingStyle = {
            padding: "4px",
            float: "left"
          }
      
          const buttonBarStyle = {
            display: "flex",
            justifyContent: "flex-end"
          }

        const BudgetTypes = [
            {id: 1, value: 'Weekly'},
            {id: 2, value: 'Monthly'}
        ]

        const itemStyle = {
            cursor: "pointer"
        }
        if(this.state.isLoading) {
            return <LoaderDisplay />
        }

        const transactionTotal = () => {
            let value = 0;
            this.state.transactions.forEach(item => {
              value = value + item.amount
            })
            return value
        }

        const totalStyle = {
            borderTop: "1px solid",
            borderBottom: "double",
            fontSize: "24px",
            fontWeight: "600"
        }

        return (
            <React.Fragment>
                <AuthorisedArea showError>
                    <div className="borderBoxStyle">
                    <Row>
                        <Col>
                            <h5>Budget</h5>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <TextInput
                                label="Budget Description"
                                value={this.state.budget.name}
                                onChange={(value) => this.handleChange('name', value)}
                                help="Description of this Budget"
                                isViewMode={this.state.isViewMode}
                            />
                        </Col>
                        <Col md="4">
                            <SelectInput
                                label="Budget Period"
                                value={this.state.budget.budgetTypeId}
                                onChange={(value) => this.handleChange('budgetTypeId', value)}
                                help="Length a budget lasts"
                                options={BudgetTypes}
                                isViewMode={this.state.isViewMode}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <DateInput
                                label="Start Date"
                                value={this.state.budget.startDate}
                                onChange={(value) => this.handleChange('startDate', value)}
                                help="Start date of this budget"
                                isViewMode={this.state.isViewMode}
                            />
                            </Col>
                        <Col md="4">
                            <DateInput
                                label="End Date"
                                value={this.state.budget.endDate}
                                onChange={(value) => this.handleChange('endDate', value)}
                                help="End date of this budget"
                                isViewMode={this.state.isViewMode}
                            />
                            </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <SelectInput
                                options={this.state.accountList}
                                label="Primary account"
                                help="The account the budget will count against"
                                value={this.state.budget.primaryAccountId}
                                onChange={(value) => this.handleChange('primaryAccountId', value)}
                                isViewMode={this.state.isViewMode}
                            />
                        </Col>
                        <Col md="3">
                            <CurrencyInput
                                label="Budget Allowance"
                                help="The amount allowed for this budget"
                                value={this.state.budget.allowanceAmount}
                                onChange={(value) => this.handleChange('allowanceAmount', value)}
                                isViewMode={this.state.isViewMode}
                            />
                        </Col>
                    </Row>
                    <Row right>
                <Col >


                <div style={buttonBarStyle}>
                    {this.state.isViewMode && 
                          <Button  
                            onClick={() => this.props.history.goBack()} 
                            text="Back"
                            variant="outline-primary"
                            style={buttonSpacingStyle}
                            icon={ButtonIcons.BACK}
                            isBusy={this.state.isBusy}
                          />
                      }


                      {!this.state.isViewMode &&
                          <Button  
                            onClick={() => this.cancelClick()} 
                            text="Cancel"
                            variant="outline-primary" 
                            isBusy={this.state.isBusy}
                            style={buttonSpacingStyle}
                            icon={ButtonIcons.CANCEL}
                          />
                        }


                        {this.state.isViewMode && 
                          <Button  
                            onClick={() => this.deleteClick()} 
                            text="Delete"
                            variant="outline-danger" 
                            isBusy={this.state.isBusy}
                            style={buttonSpacingStyle}
                            icon={ButtonIcons.DELETE}
                          /> 
                      }


                      {!this.state.isViewMode && 
                          <Button  
                            isBusy={this.state.isBusy}
                            onClick={() => this.saveClick()} 
                            text="Save"
                            variant="primary"
                            style={buttonSpacingStyle}
                            icon={ButtonIcons.SAVE}
                          />
                      }                        
                      
                      {this.state.isViewMode && 
                          <Button  
                            onClick={() => { this.setState({ isViewMode: false}); }} 
                            text="Edit"
                            variant="primary"
                            style={buttonSpacingStyle}
                            icon={ButtonIcons.EDIT}
                          />
                     }       
                </div>

                </Col>
              </Row>
                    </div>
                    {this.state.budget.id &&
                    <div className="borderBoxStyle top-buffer ">
                    <Row>
                        <Col>
                            <h5>Associated Transactions</h5>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <SelectInput
                                    label="Budget Period"
                                    value={this.state.selectedPeriod}
                                    onChange={(value) => this.handlePeriodChange(value)}
                                    help="Period for Transactions"
                                    options={this.state.periods}
                                />
                        </Col>
                        </Row>
                    <Row>
                        <Col md="12">
                        <Row className="tableViewHeaderStyle">
                            <Col md="2">Date</Col>
                            <Col md="3">To</Col>
                            <Col md="3">Category</Col>
                            <Col className="text-right" md="4">Amount</Col>
                        </Row>
                        {this.state.transactionsLoading && <LoaderDisplay />}
                            {!this.state.transactionsLoading && 
                            
                                this.state.transactions
                                    .sort((a,b) => sortByDateDesc(a.transactionDate,b.transactionDate))
                                    .map((item, index) => {
                                        return (
                                            <Row key={index} onClick={() => this.onTransactionSelect(item.id)} className="tableViewRowStyle" style={itemStyle} >
                                                <Col md="2">{moment(new Date(item.transactionDate)).format("Do MMMM")}</Col>
                                                <Col md="3"><strong>{item.creditAccountName}</strong></Col>
                                                <Col md="3">{item.subCategoryName}</Col>
                                                <Col md="4" className="text-right">{formatMoney(item.amount)}</Col>
                                            </Row>
                                        )
                                    })
                                    
                                    
                            }
                        </Col>
                    </Row>
                    <Row className="tableViewRowStyle" style={itemStyle}>
                                        <Col md={{ span: 2, offset: 10 }} className="text-right">
                                            <div style={totalStyle}>{formatMoney(transactionTotal())}</div>
                                        </Col>
                                        </Row>
                   </div>
    }
                </AuthorisedArea>
            </React.Fragment>
        )
    }
}

Budget.contextType = Consumer
export default withToastManager(withRouter(Budget))