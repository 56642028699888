import fetchData from '../Helpers/FetchData'
import Server from '../Constants'
import { HandleResponse } from './ResponseHandler' 

const balanceHistoryUrl = `${Server.ApiURL}/api/report/balanceHistory`;

class BudgetDataAcessor {

    static getBalanceHistory(accountId, historicDays) {
        return new Promise((resolve, reject) => {
    
          const request = {
            URL: balanceHistoryUrl,
            method: 'POST',
            data: {
                accountId: accountId,
                historicDays: historicDays
            }
          };
    
          fetchData(request)            
          .then((response) => {  
              HandleResponse(response)
                  .then((result) => {
                      resolve(result)
              })
          })
          .catch((err) => {
              console.log('Error caught!', err);
              reject(err);
          });
        })      
    }


        static getBalanceForecast(accountId, historicDays) {
            return new Promise((resolve, reject) => {
              const request = {
                URL:  `${Server.ApiURL}/api/report/balanceforecast`,
                method: 'POST',
                data: {
                    accountId: accountId,
                    historicDaysFromNow: historicDays
                }
              };
        
              fetchData(request)            
              .then((response) => {  
                  HandleResponse(response)
                      .then((result) => {
                          resolve(result)
                  })
              })
              .catch((err) => {
                  console.log('Error caught!', err);
                  reject(err);
              });
            })
        }

        static getBudgetReport(budgetTypeId, budgetId) {
            return new Promise((resolve, reject) => {
              const request = {
                URL:  `${Server.ApiURL}/api/report/budget`,
                method: 'POST',
                data: { budgetTypeId, budgetId }
              };
        
              fetchData(request)            
              .then((response) => {  
                  HandleResponse(response)
                      .then((result) => {
                          resolve(result)
                  })
              })
              .catch((err) => {
                  console.log('Error caught!', err);
                  reject(err);
              });
            })
        }

        static getCategoriesOverTime(subCategoryId, fromDate, toDate, granularity, includeCurrent) {
            return new Promise((resolve, reject) => {
                const request = {
                    URL:  `${Server.ApiURL}/api/report/categoriesovertime`,
                    method: 'POST',
                    data: { subCategoryId, fromDate, toDate, granularity, includeCurrent }
                };
                fetchData(request)            
                .then((response) => {  
                  HandleResponse(response)
                      .then((result) => {
                          resolve(result)
                  })
              })
              .catch((err) => {
                  console.log('Error caught!', err);
                  reject(err);
              });
            })
        }

        static getCategoryAmount(categoryId, fromDate, toDate, historicDays) {
            const data = { 
                categoryId: categoryId, 
                fromDate: fromDate, 
                toDate: toDate, 
                historicDays: historicDays 
            }

            return new Promise((resolve, reject) => {
              const request = {
                URL:  `${Server.ApiURL}/api/report/categoryamount`,
                method: 'POST',
                data: data
              };

              fetchData(request)            
              .then((response) => {  
                  HandleResponse(response)
                      .then((result) => {
                          resolve(result)
                  })
              })
              .catch((err) => {
                  console.log('Error caught!', err);
                  reject(err);
              });
            })
        }


}

export default BudgetDataAcessor;