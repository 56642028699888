import React from 'react'
import { Consumer } from  '../../context'
import { Row, Col } from 'react-bootstrap'
import SelectInput from '../controls/selectInput'

class TimezoneEditor extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            rawData: [],
            countries: [],
            timezones: []
        }

    }

    componentDidMount() {
        this.context.getTimezones()
            .then((result) => {
                var countries = result.map(item => (
                    { id: item.countryId, value: item.name }
                ))
                this.setState({
                    rawData: result,
                    countries,
                    selectedTimezoneId: this.props.value
                }, () => {
                   this.setSelectedItems()
                })
            })
    }

    setSelectedItems() {
        if(this.state.selectedTimezoneId) {
            // We have a timezone... need to find the country that this timezone belongs to...
            this.state.rawData.forEach((rawDataItem) => {
                const tz = rawDataItem.timezones.find((item) => item.id === this.state.selectedTimezoneId)
                if(tz) {
                   this.setState({
                        selectedCountryId: rawDataItem.countryId,
                   }, () => this.populateTimezones(rawDataItem.countryId))
               }
            })
        }
    }

    handleCountryChange(countryId) {
        this.setState({
            selectedCountryId: countryId
        })
        this.populateTimezones(countryId)
    }


    populateTimezones(countryId) {
        var selectedCountry = this.state.rawData
            .find((item) => item.countryId === countryId)

        var timezones = selectedCountry.timezones
            .map(item => (
                { id: item.id, value: item.name }
            ))

        this.setState({
            timezones
        })
    }


    handleTimezoneChange(timezoneId) {
        this.setState({
            selectedTimezoneId: timezoneId
        }, () => this.props.onChange(timezoneId))
    }

    render() {

        return (
            <React.Fragment>
            <Row>
                <Col md="6">
                    <SelectInput
                        label="Country"
                        help="Your Country"
                        options={this.state.countries}
                        value={this.state.selectedCountryId}
                        onChange={(id) => this.handleCountryChange(id) }
                    />
                </Col>
                <Col md="6">
                    <SelectInput
                        label="Timezone"
                        help="Your Timezone"
                        options={this.state.timezones}
                        value={this.state.selectedTimezoneId}
                        onChange={(id) => this.handleTimezoneChange(id) }
                    />
                </Col>
            </Row>
                
            </React.Fragment>
        )
    }

}

TimezoneEditor.contextType = Consumer
export default TimezoneEditor