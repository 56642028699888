import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Moment from 'react-moment'
import { TransactionTypes } from '../Constants'
import ContextMenu from '../components/controls/contextMenu'
import { withRouter } from 'react-router-dom';


class SchedulesItem extends React.Component {


    Pay(id, paymentNumber) {
        this.props.history.push(`/transaction/schedule/${id}/${paymentNumber}`);
    }

    render() {

        const displayAccount = this.props.data.transactionTypeId === TransactionTypes.DEPOSIT 
            ? this.props.data.debitAccount 
            : this.props.data.creditAccount

        const join = this.props.data.transactionTypeId === TransactionTypes.DEPOSIT 
        ? 'from'
        : 'to'
        
        let transactionIcon = ''

        switch(this.props.data.transactionTypeId) {
            case TransactionTypes.PAYMENT: 
            {
                transactionIcon = <i className="far fa-arrow-alt-circle-down expenseColor"></i>
                break
            }
            case TransactionTypes.DEPOSIT: 
            {
                transactionIcon = <i className="far fa-arrow-alt-circle-up incomeColor"></i>
                break
            } 
            default:
            {
                transactionIcon =<i className="fas fa-random incomeColor"></i>;
            }
        }

        const displayAmount = <span className={`${this.props.data.transactionTypeId === TransactionTypes.PAYMENT ? 'expenseColor' : this.props.data.transactionTypeId=== TransactionTypes.DEPOSIT ? 'incomeColor' : ''}`}>
                <strong>{Number.parseFloat(this.props.data.amount).toFixed(2)}</strong>
            </span>

        const display = 
            <React.Fragment>
                {transactionIcon} <small>{this.props.data.transactionType} {join} <strong>{displayAccount}</strong></small>
            </React.Fragment>

        const dueDisplay = <React.Fragment>
            ({this.props.data.dueDays === 0 ? 'Due today' : this.props.data.dueDays < 0 ? 'Overdue' : this.props.data.dueDays === 1 ? 'Due tomorrow' : `In ${this.props.data.dueDays} days`})
        
        </React.Fragment>
        
            const options=[
                { displayText: "Pay", callback: () => this.Pay(this.props.data.scheduleId, this.props.data.paymentNumber)}
            ]

            return(

                <React.Fragment>
                    {this.props.showDate === true && 
                        <Row key={this.props.data.index} className="tableViewGroupBarStyle">
                            <Col md="12"><strong><Moment format="Do of MMMM">{this.props.data.date}</Moment></strong> <small>{dueDisplay}</small></Col>
                        </Row>
                    }

                    <Row key={this.props.data.index} >
                        <Col md="4">
                            <ContextMenu items={options} />
                       
                        <div onClick={() => this.props.onClick(this.props.data.scheduleId)} className="tableViewRowStyle pointable">
                            {display}
                            </div>
                        </Col>
                        <Col md="4">
                            <small>
                                <strong>{this.props.data.schedule}</strong> - {this.props.data.subCategory}
                            </small>
                        </Col>
                        <Col md="4" className="text-right">
                            {displayAmount}
                        </Col>
                    
                    </Row>   
                </React.Fragment> 
            )
    }
}

export default withRouter(SchedulesItem)
