import React from 'react'
import { Row, Col  } from 'react-bootstrap'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import LoaderDisplay from '../components/controls/loaderDisplay'
import { Consumer } from '../context'
import ReactGA from 'react-ga';
import Button from '../components/controls/button'
import { ButtonIcons } from '../Constants'


class Budgets extends React.Component {


    constructor(props) {
        super(props)

        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
        this.context.getBudgets()
            .then(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    View(budgetId) {
        this.props.history.push(`/budget/${budgetId}`);
    }

    Create() {
        this.props.history.push(`/budget`);
    }

    render() {

        
    const buttonBarStyle = {
        display: "flex",
        justifyContent: "flex-end"
      }
        return(
            <React.Fragment>
            <AuthorisedArea showError>
                <div className="borderBoxStyle">
                    <Row>
                        <Col md="6">Filter Box</Col>
                        <Col md="6">
                            <div style={buttonBarStyle}>
                            <Button 
                                onClick={() => this.Create()}
                                text="Create"
                                variant="primary"
                                icon={ButtonIcons.CREATE}
                            />
                            </div>
                        </Col>
                    </Row>
                </div>
                {this.state.isLoading && 
                    <LoaderDisplay />
                } 
                
                {!this.state.isLoading && 
                <div className="borderBoxStyle  top-buffer">
                   
                        <Row>
                            <Col md="12" >
                                <Row className="tableViewHeaderStyle">  
                                    <Col md="2">Budget</Col>
                                    <Col md="3">Account</Col>
                                    <Col md="2">Budget Type</Col>
                                    <Col md="2" className="text-right">Budget Value</Col>
                                    <Col md="3" className="text-right">Used this period</Col>
                                </Row>


                                <Consumer.Consumer>
                                    {value => { 
                                
                                    if(value.cacheStore.budgets === 0)
                                        return(<div>No schedules available</div>)
                        
                                
                                    return(
                            
                                        value.cacheStore.budgets
                                            .map((item, index) => 
                                                <Row 
                                                    key={index} 
                                                    onClick={() => this.View(item.id)}
                                                    className="tableViewRowStyle pointable" 
                                                >
                                                    <Col md="2"><strong>{item.name}</strong></Col>
                                                    <Col md="3"><small>{item.primaryAccountName}</small></Col>
                                                    <Col md="2"><small>{item.budgetType}</small></Col>
                                                    <Col md="2" className="text-right">{Number.parseFloat(item.budgetAmount).toFixed(2)}</Col>
                                                    <Col md="3" className="text-right">{Number.parseFloat(item.used).toFixed(2)}</Col>
                                                </Row>   
                                            ) 
                                    )
                                }}
                                </Consumer.Consumer>
                            </Col>
                        </Row>
                  
                    </div>
                }
               
                </AuthorisedArea>
                </React.Fragment>
        )
    }
}

Budgets.contextType = Consumer
export default Budgets