import React from 'react'

class EmailValidation extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id
        }

    }


    render() {

        return(
            <div>
                Email Validation. {this.state.id}
            </div>
        )
    }
}

export default EmailValidation;