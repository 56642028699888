/* global localStorage:false, window:false */
// import AuthenticationDataAccessor from './api/AuthenticationDataAccessor';

const NotFound = -1;
const DateMultiplier = 1000;

const Auth = (function Auth() {
  // Parse the JWT token so that we can get the data from it.
  function parseJwt(token) {
    // Is there a token?
    if (!token) { return ''; }
    // Do we have the separator?
    if (token.indexOf('.') === NotFound) { return ''; }
    // Token is made of two parts. Header info, and payload. Let's split it and take the payload.
    const tokenPayloadSection = token.split('.')[1];
    // Clean up the base64 text.
    const base64 = tokenPayloadSection.replace('-', '+').replace('_', '/');
    // Return the payload, decoded.
    return JSON.parse(window.atob(base64));
  }

  // Get the Expiry date from the JWT Token
  function expiryEpoch() {
    if (Auth.token()) {
      // Decode the token.
      const decoded = parseJwt(Auth.token());
      if (decoded) {
        const exp = decoded.exp;
        if(!exp) {
          console.log("No exp in token. It seems bad. Returning -1 from expiryEpoch... Will cause logout.")
          return -1;
        }
        return decoded.exp;
      }
    }
    return -1;
  }

  // Validate that the token is valid and current.
  function isValidToken() {
    // First... do we have a token?
    if (!Auth.token()) {
      return false;
    }

    // We have a token, let;'s now check it.
    const nowEpoch = Math.floor(new Date().getTime() / DateMultiplier);
    const expiry = expiryEpoch();
    // Current date is greater than the expiry?
    const hasExpired = (expiry < 0) || nowEpoch > expiry;

    if (hasExpired) {
      console.log('Token has expired. Will execute Logout to make sure that everythis is in the correct state.');
      // Token seems invalid... Let's clear the login details.
      Auth.logout();
      // and fail.
      return false;
    }
    return !hasExpired;
  }

  // Properties of the Authentication object, easily accessable.
  Auth.token = function token() {
    return localStorage.getItem(Auth.localStorageToken);
  };
  Auth.email = function email() {
    return Auth.getFieldFromToken('email');
  };
  Auth.firstname = function firstname() {
    return Auth.getFieldFromToken('given_name');
  };
  Auth.surname = function surname() {
    return Auth.getFieldFromToken('family_name');
  };
  Auth.isAuthenticated = function isAuthenticated() {
    return isValidToken(); // Logged in is true if we have a valid token.
  };
  Auth.defaultAccount = function defaultAccount() {
    return Auth.getFieldFromToken('defaultAccountId');
  }

  Auth.isAdmin = function isAdmin() {
    // Decode the JWT
    const decoded = parseJwt(Auth.token());
    let result = false;
    // Get each item from the JWT into an array.
    const splitToken = Object.keys(decoded);
    // Iterate through each item..
    splitToken.forEach((splitItem) => {
      // and see if it has the word 'roles'.
      if (splitItem.match(/.*role$/)) {
        // Sprit the item into an array...
        const roles = Object.values(decoded[splitItem]);
        // Iterate though each roles...
        roles.forEach((role) => {
          // And see if it's set to Admin.
          if (role === 'Admin') {
            // If so, return true.
            result = true;
          }
        });
      }
    });
    // Not matching admin tag found in the token, so... not an admin.
    return result;
  };


  Auth.getFieldFromToken = function(fieldName) {
    const decoded = parseJwt(Auth.token());
    const splitToken = Object.keys(decoded);
    let reply = "";

    splitToken.forEach((splitItem) => {
      if(splitItem === fieldName) {
        const value = Object.values(decoded[splitItem]);
        value.forEach((char) => {
          reply = reply + char;
        })
      }
    });
    return reply;

  };


  Auth.logout = function logout() {
    localStorage.removeItem(Auth.localStorageToken);
  };


  Auth.login = function login(Username, token) {

    localStorage.setItem(Auth.localStorageToken, token);
    const sub = Auth.getFieldFromToken("sub");
    if(Username !== sub) {
      console.log(`Token sub ${sub} doesn't match login name ${Username}. Logging out`);
      Auth.logout()
    }
  };

  return Auth;
}());

Auth.localStorageToken = 'AccuFinance_JWTToken';
// Auth.localStorageEmail = 'AccuFinance_Email';
// Auth.localStorageFirstname = 'AccuFinance_Firstname';
// Auth.localStorageSurname = 'AccuFinance_Surname';

export default Auth;
