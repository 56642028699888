import React from 'react'

class StaticLabel extends React.Component {

    render() {

        const labelStyle = {
            color: "#a1a1a1"
        }

        const fontSize = this.props.size || "14px"

        const textStyle = {
            fontSize: fontSize,
            marginTop: "4px",
            marginBottom: "12px",
            paddingLeft: "5px",
            color: "#FFFFFF"
        }

        return (
            <React.Fragment>
                <div style={labelStyle}>{this.props.label}</div>
                <div style={textStyle}>{this.props.value}</div>
            </React.Fragment>
        )
    }
}

export default StaticLabel