import React from 'react'

class ControlText extends React.Component {

    render() {

        const controlStyle = {
            fontWeight: "600",
            fontSize: "14px",
            paddingTop: "2px",
            paddingBottom: "10px",
          //  paddingLeft: "8px",
            minHeight: "40px"
        }

        return(
            <div style={controlStyle}>
                {this.props.text}
            </div>
        )
    }
}

export default ControlText